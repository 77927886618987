export default {
    markAsRead: "Отбележи като прочетено",
    joinRequestApproved: 'Вашата молба за пътуване беше одобрена от шофьора. Приятно пътуване.',
    joinRequestCancelled: 'Пътникът оттегли своята молба за пътуване с вас.',
    joinRequestCreated: 'Имате нова молба за присъединяване към вашето пътуване.',
    joinRequestRejected: 'Вашата молба за пътуване беше отказана от шофьора.',
    rateTheDriver: 'Моля оценете шофьора. Това ще бъде от полза на останалите пътници.',
    rateThePassenger: 'Моля оценете пътника. Това ще бъде от полза на останалите шофьори.',
    complaintLevelReached: 'Получихме няколко оплаквания за вас и ще бъде преценено дали да ви бъде наложена забрана за определено време да създавате нови пътувания.',
    rateUser: "Оцени потребител",
    rateLabel: "Оцени",
    joinRequestLabel: "Прегледай молбата",
}