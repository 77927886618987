import React from "react";

const PageNotFound = () => {
    return (
        <div>
            <h3 className="h-100 d-flex align-items-center justify-content-center">
                Resource not found
            </h3>
        </div>
    );
}

export default PageNotFound;