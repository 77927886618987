import { useForm } from "react-hook-form";
import { changeUsername } from "../../util/http";
import { useAuth } from "../../providers/AuthProvider";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import ModalMessage from "../ModalMessage";
import '../../css/ProfileSettings.css';
import { useNavigate } from "react-router-dom";

const ChangeUsername = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { token } = useAuth();
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('success');
    const [modalMessage, setModalMessage] = useState('');

    const {
        register: register,
        handleSubmit: handle,
        formState: { errors: errors },
    } = useForm();

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            await changeUsername(data, token);
            handleModal('success');
            setModalMessage('You have successfully changed your username');
        } catch (err) {
            handleModal('failure', err);
        } finally {
            setLoading(false);
        }
    };

    function handleModal(type, error = null) {
        if (type === 'success') {
            setModalType('success');
            setShowModal(true);
        } else if (type === 'failure') {
            setModalType('failure');
            if (error.response?.status && [400, 401, 422, 409].includes(error.response.status)) {
                setModalMessage(error.response.data.error);
            } else {
                setModalMessage(t('common:generalFormSubmitServerError'));
            }
            setShowModal(true);
        }
    }

    function handleCloseModal() {
        setShowModal(false);
        if (modalType === 'success') {
            window.location.reload();
        }
    }

    return (
        <>
            <ModalMessage
                show={showModal}
                onClose={handleCloseModal}
                message={modalMessage}
                messageType={modalType}
            />

            <form onSubmit={handle(onSubmit)}>
                <div className="form-group mb-3">
                    <p style={{ color: '#02335B', fontSize: '18px', fontWeight: 'bold', marginBottom: '20px' }}>Change username</p>
                    <p className="custom-input-label">Write new username</p>
                    <input id="username" className="form-control custom-profile-settings-input" type="text"
                        {...register("username", {
                            required: t('common:requiredField'),
                            minLength: {
                                value: 8,
                                message: t('tripcreate:fieldMinSymbols8'),
                            },
                            maxLength: {
                                value: 20,
                                message: t('tripcreate:fieldMaxSymbols20'),
                            }
                        })}
                        aria-invalid={errors.username ? "true" : "false"}
                    />
                    {errors.username && <p className="text-danger">{errors.username?.message}</p>}
                </div>
                <div className="form-group mb-3">
                    <p className="custom-input-label">Write your password</p>
                    <input id="password" className="form-control custom-profile-settings-input" type="password"
                        {...register("password", {
                            required: t('common:requiredField'),
                            minLength: {
                                value: 8,
                                message: t('tripcreate:fieldMinSymbols8'),
                            },
                            maxLength: {
                                value: 20,
                                message: t('tripcreate:fieldMaxSymbols20'),
                            }
                        })}
                        aria-invalid={errors.password ? "true" : "false"}
                    />
                    {errors.password && <p className="text-danger">{errors.password?.message}</p>}
                </div>
                <div className="text-center mt-3">
                    <button type="submit" className="custom-profile-settings-submit-button" disabled={loading}>
                        {loading ? <div className="loading-spinner" /> : t('common:changeLabel')}
                    </button>
                </div>
            </form >
        </>
    );
}

export default ChangeUsername;