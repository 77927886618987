export default {
    changePasswordLabel: "Change password",
    changeUsernameLabel: "Change username",
    deleteAccountLabel: "Delete account",
    driverRating: "Driver rating",
    passengerRating: "Passenger rating",
    username: "Username",
    totalMoneyEarned: "Total money earned",
    userCreateFailureModalMessage: "There was a problem creating the profile. Please try again later or contact our support team.",
    invalidFileFormatMessage: "Invalid file format. Allowed formats: jpg, jpeg and png",
    sizeExceedsLimitMessage: "File size exceeds the limit of 1 MB.",
    information: "Information",
    comment: 'Comment',
    rateSuccessful: 'Thank you for your feedback.',
    driver: 'Driver',
    passenger: 'Passenger',
    comments: 'Comments',
    successfulUserRegistration: "Successful registration",
    successfulTripConfigurationDeletion: "Configuration has been deleted",
    reportUserText: "Please describe the problem",
    sendComplaintButtonText: "Send complaint",
    complaintPostedSuccessfullyMessage: "Complaint successfully sent",
    daysUsingTheApp: "Days using the platform",
}