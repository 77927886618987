import React, { useState, useEffect } from 'react';
import { useAuth } from '../providers/AuthProvider';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import ForgottenPasswordModal from '../components/ForgottenPasswordModal';
import { getGoogleSocialAuthRedirectUrl } from '../util/http';
import ModalMessage from '../components/ModalMessage';
import '../css/Login.css';

const Login = () => {
  const { t } = useTranslation();
  const { onLogin } = useAuth();
  const [loading, setLoading] = useState(false);
  const { register, formState: { errors }, handleSubmit, watch } = useForm();
  const [showForgottenPasswordModal, setShowForgottenPasswordModal] = useState(false);
  const [googleSocialAuthUrl, setGoogleSocialAuthUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('success');
  const [modalMessage, setModalMessage] = useState('');

  const getGoogleAuthUrl = async () => {
    try {
      const response = await getGoogleSocialAuthRedirectUrl();
      setGoogleSocialAuthUrl(response.data.url);
    } catch (error) {
      console.log('cannot fetch google auth url');
    }
  }

  useEffect(() => {
    getGoogleAuthUrl();
  }, []);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const formData = watch();
      await onLogin(formData.email, formData.password);
    } catch (err) {
      handleModal('failure', err);
    } finally {
      setLoading(false)
    }
  };

  function handleModal(type, error = null) {
    if (type === 'success') {
      setModalType('success');
      setShowModal(true);
    } else if (type === 'failure') {
      setModalType('failure');
      if (error.response?.status && [401, 422, 409].includes(error.response.status)) {
        setModalMessage(error.response.data.error);
      } else {
        setModalMessage(t('common:generalFormSubmitServerError'));
      }
      setShowModal(true);
    }
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  return (
    <div className='container-fluid'>
      {showForgottenPasswordModal && <ForgottenPasswordModal
        show={!!showForgottenPasswordModal}
        onClose={() => setShowForgottenPasswordModal(false)}
      />}

      <ModalMessage
        show={showModal}
        onClose={handleCloseModal}
        message={modalMessage}
        messageType={modalType}
      />


      <div className='row no-gutters'>
        <div className='col-md-8 image-column'>
          <img src='img/login_background.png' />
        </div>
        <div className='col-md-4 form-container'>
          <div className="custom-form">
            <form onSubmit={handleSubmit(onSubmit)}>

              <p style={{ fontWeight: 'bolder', letterSpacing: "1px", fontSize: '20px', color: "#02335B" }}>Welcome Back! Your Next Adventure Awaits.</p>

              <div className="form-outline mb-4">
                <label className="input-label" htmlFor="email">Email address</label>
                <input type="email" id="loginEmail" className="form-control custom-input"
                  {...register("email", {
                    required: t('common:requiredField'),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t('common:invalidEmailMessage')
                    }
                  })}
                  aria-invalid={errors.email ? "true" : "false"}
                  placeholder="Enter email"
                />
                <div>
                  {errors.email && <span className="text-danger">{errors.email?.message}</span>}
                </div>
              </div>

              <div className="form-outline mb-4">
                <label className="input-label" htmlFor="password">Password</label>
                <input type="password" id="loginPassword" className="form-control custom-input"
                  {...register("password", { required: t('common:requiredField') })}
                  aria-invalid={errors.password ? "true" : "false"}
                  placeholder="Enter password"
                />
                <div>
                  {errors.password && <span className="text-danger">{errors.password?.message}</span>}
                </div>
              </div>

              <p className='forgotten-password' onClick={() => setShowForgottenPasswordModal(true)}>{t('common:forgottenPassword')}?</p>

              <div className="pt-1 mb-4">
                <button className="sign-button" type="submit" disabled={loading}>
                  {loading ? <div className="loading-spinner" /> : t('common:loginLabel')}
                </button>
              </div>
            </form>

            <hr />

            <div className='col-md-12'>
              <div className="pt-1 mb-4">
                <a className='google-sign-button' href={googleSocialAuthUrl}>
                  <img
                    alt=""
                    src="img/google_icon.png"
                    className='social-icon'
                  />
                  <p style={{ margin: 'auto' }}>{t('common:continueWithLabel')} Google</p>
                </a>
              </div>
              <div className="pt-1 mb-4">
                <a className='apple-sign-button' href={googleSocialAuthUrl}>
                  <img
                    alt=""
                    src="img/apple_icon.png"
                    className='social-icon'
                  />
                  <p style={{ margin: 'auto' }}>{t('common:continueWithLabel')} Apple</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
