import axios from 'axios';
import config from './config';

// Auth
export async function verifyEmail(data) {
  const response = await axios.post(config.BACKEND_API_URL + '/verify-email', data);
  return response;
}

// Contact mail
export async function sendContactEmail(data) {
  const response = await axios.post(config.BACKEND_API_URL + '/contact-mail', data);
  return response;
}

// Trips
export async function storeTrip(token, tripData) {
  const response = await axios.post(config.BACKEND_API_URL + '/trips', tripData, getHeadersConfig(token));
  return response;
}

export async function showTrip(token, tripId) {
  const response = await axios.get(config.BACKEND_API_URL + '/trips/' + tripId, getHeadersConfig(token));
  const trip = response.data.data;

  return trip;
}

export async function fetchTrips(filters = {}, currentPage) {
  const queryParams = getQueryParams(currentPage, filters);
  const configReq = {...queryParams};
  const response = await axios.get(config.BACKEND_API_URL + '/trips', configReq);
  const trips = response.data;

  return trips;
}

export function cancelTrip(tripId, payload, token) {
  return axios.post(config.BACKEND_API_URL + `/trips/${tripId}/cancel`, payload, getHeadersConfig(token));
}

export function getGoogleSocialAuthRedirectUrl() {
  return axios.get(config.BACKEND_API_URL + `/social-auth/redirect-provider/google`);
}

export function socialAuthCallback(location) {
  return axios.get(config.BACKEND_API_URL + `/social-auth/callback${location.search}`);
}
// PROFILE ENDPOINTS

export async function getUserProfile(token) {
  const response = await axios.get(config.BACKEND_API_URL + '/users/profile', getHeadersConfig(token));
  const user = response.data.data;

  return user;
}

export async function changePassword(data, token) {
  const response = await axios.post(config.BACKEND_API_URL + '/users/profile/password', data, getHeadersConfig(token));
  return response;
}

export async function changeUsername(data, token) {
  const response = await axios.post(config.BACKEND_API_URL + '/users/profile/username', data, getHeadersConfig(token));
  return response;
}

export async function getSentJoinRequests(token, currentPage) {
  const headers = getHeadersConfig(token);
  const queryParams = getQueryParams(currentPage);
  const configReq = {...headers, ...queryParams};
  const response = await axios.get(config.BACKEND_API_URL + '/users/profile/tripjoinrequests/sent', configReq);
  return response.data;
}

export async function getReceivedJoinRequests(token, currentPage) {
  const headers = getHeadersConfig(token);
  const queryParams = getQueryParams(currentPage);
  const configReq = {...headers, ...queryParams};
  const response = await axios.get(config.BACKEND_API_URL + '/users/profile/tripjoinrequests/received', configReq);
  return response.data;
}

export async function getUserTripsAsDriver(token, currentPage) {
  const headers = getHeadersConfig(token);
  const queryParams = getQueryParams(currentPage);
  const configReq = {...headers, ...queryParams};
  const response = await axios.get(config.BACKEND_API_URL + '/users/profile/trips/driver', configReq);
  return response.data;
}

export async function getFinishedUserTripsAsDriver(token, currentPage) {
  const headers = getHeadersConfig(token);
  const queryParams = getQueryParams(currentPage);
  const configReq = {...headers, ...queryParams};
  const response = await axios.get(config.BACKEND_API_URL + '/users/profile/trips/driver/finished', configReq);
  return response.data;
}

export async function getUserTripsAsPassenger(token, currentPage) {
  const headers = getHeadersConfig(token);
  const queryParams = getQueryParams(currentPage);
  const configReq = {...headers, ...queryParams};
  const response = await axios.get(config.BACKEND_API_URL + '/users/profile/trips/passenger', configReq);
  return response.data;
}

export async function getFinishedUserTripsAsPassenger(token, currentPage) {
  const headers = getHeadersConfig(token);
  const queryParams = getQueryParams(currentPage);
  const configReq = {...headers, ...queryParams};
  const response = await axios.get(config.BACKEND_API_URL + '/users/profile/trips/passenger/finished', configReq);
  return response.data;
}

export async function getUnreadNotifications(token, currentPage) {
  const headers = getHeadersConfig(token);
  const queryParams = getQueryParams(currentPage);
  const configReq = {...headers, ...queryParams};
  const response = await axios.get(config.BACKEND_API_URL + `/users/profile/notifications`, configReq);
  return response.data;
}

export async function markNotificationsAsRead(ids, token) {
  const response = await axios.post(config.BACKEND_API_URL + '/users/profile/notifications/read', ids, getHeadersConfig(token));
  return response;
}

export async function updateAvatar(data, token) {
  const response = await axios.post(config.BACKEND_API_URL + '/users/profile/avatar', data, getMultipartHeaders(token));
  return response;
}

export async function deleteAvatar(token) {
  const response = await axios.delete(config.BACKEND_API_URL + '/users/profile/avatar', getHeadersConfig(token));
  return response;
}

export async function getUserProfileId(token) {
  const response = await axios.get(config.BACKEND_API_URL + '/users/profile/id', getHeadersConfig(token));
  const userId = response.data;

  return userId;
}

export async function getUserDetails(userId, token) {
  const response = await axios.get(config.BACKEND_API_URL + '/users/details/' + userId, getHeadersConfig(token));
  return response.data;
}

export async function forgottenPassword(payload) {
  const url = config.BACKEND_API_URL + '/forgot-password';
  const response = await axios.post(url, payload);

  return response;
}

export async function resetPassword(data) {
  const response = await axios.post(config.BACKEND_API_URL + '/reset-password', data);
  return response;
}

export async function deleteAccount(token) {
  const response = await axios.delete(config.BACKEND_API_URL + '/users/profile', getHeadersConfig(token));
  return response;
}

// CAR ENDPOINTS
export async function createCar(data, token) {
  const response = await axios.post(config.BACKEND_API_URL + '/users/cars', data,getHeadersConfig(token));
  return response;
}

export async function deleteCar(carId, token) {
  const response = await axios.delete(config.BACKEND_API_URL + '/users/cars/' + carId, getHeadersConfig(token));
  return response;
}

// TRIPS ENDPOINTS

export async function createJoinRequest(tripId, data, token) {
  const response = await axios.post(config.BACKEND_API_URL + '/tripjoinrequests/trips/' + tripId, data, getHeadersConfig(token));
  return response;
}

export async function cancelJoinRequest(joinReqId, token) {
  const response = await axios.post(config.BACKEND_API_URL + '/tripjoinrequests/' + joinReqId + '/cancellations', [], getHeadersConfig(token));
  return response;
}

export async function approveJoinRequest(joinReqId, token) {
  const response = await axios.post(config.BACKEND_API_URL + '/tripjoinrequests/' + joinReqId + '/approvals', [], getHeadersConfig(token));
  return response;
}

export async function rejectJoinRequest(joinReqId, token) {
  const response = await axios.post(config.BACKEND_API_URL + '/tripjoinrequests/' + joinReqId + '/rejections', [], getHeadersConfig(token));
  return response;
}

export async function getJoinRequest(joinReqId, token) {
  const response = await axios.get(config.BACKEND_API_URL + '/tripjoinrequests/' + joinReqId, getHeadersConfig(token));
  return response.data;
}

// COMPLAINT ENDPOINTS
export async function reportUser(userId, complaint, token) {
  const response = await axios.post(config.BACKEND_API_URL + '/complaints/' + userId, complaint, getHeadersConfig(token));
  return response;
}

// RATE ENDPOINTS
export async function rateDriver(userId, rateData, token) {
  const response = await axios.post(config.BACKEND_API_URL + '/ratings/drivers/' + userId, rateData, getHeadersConfig(token));
  return response;
}

export async function ratePassenger(userId, rateData, token) {
  const response = await axios.post(config.BACKEND_API_URL + '/ratings/passengers/' + userId, rateData, getHeadersConfig(token));
  return response;
}

// CHAT ENDPOINTS

export async function getChatRooms(token) {
  const response = await axios.get(config.BACKEND_API_URL + '/chats/rooms', getHeadersConfig(token));
  return response.data;
}

export async function getRoomMessages(roomId, token) {
  const response = await axios.get(config.BACKEND_API_URL + '/chats/rooms/'+ roomId +'/messages', getHeadersConfig(token));
  return response.data;
}

export async function sendMessage(data, token) {
  const response = await axios.post(config.BACKEND_API_URL + '/chats/messages', data, getHeadersConfig(token));
  return response.data;
}

function getHeadersConfig(token) {
    return {
        headers: { Authorization: `Bearer ${token}` }
    }
}

function getMultipartHeaders(token) {
  return {
    headers: { 
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
}
}

function getQueryParams(currentPage, filters = {}) {
  Object.keys(filters).forEach((k) => filters[k] == '' && delete filters[k]);

  let pageNum = { page: currentPage };
  return {
    params: { ...pageNum, ...filters }
  }
}