import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import '../../css/ProfileSettings.css';
import ChangePassword from './ChangePassword';
import DeleteAccount from './DeleteAccount';
import ChangeUsername from './ChangeUsername';
import CarSettings from './CarSettings';


const ProfileSettings = ({ show, handleClose }) => {
    const [activeComponent, setActiveComponent] = useState(null);

    const renderComponent = () => {
        switch (activeComponent) {
            case 'CarSettings':
                return <CarSettings />;
            case 'ChangePassword':
                return <ChangePassword />;
            case 'ChangeUsername':
                return <ChangeUsername />;
            case 'DeleteAccount':
                return <DeleteAccount />;
            default:
                return null;
        }
    };

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-lg">
            <Modal.Header closeButton>
                <Modal.Title>Profile Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div id="menu-items" className='col-md-4 d-flex flex-column align-items-center'>
                        <button className={`menu-button ${activeComponent === 'CarSettings' ? 'active' : ''}`} onClick={() => setActiveComponent('CarSettings')}>Car</button>
                        <button className={`menu-button ${activeComponent === 'ChangePassword' ? 'active' : ''}`} onClick={() => setActiveComponent('ChangePassword')}>Change password</button>
                        <button className={`menu-button ${activeComponent === 'ChangeUsername' ? 'active' : ''}`} onClick={() => setActiveComponent('ChangeUsername')}>Change username</button>
                        <button className={`menu-delete-button ${activeComponent === 'DeleteAccount' ? 'active' : ''}`} onClick={() => setActiveComponent('DeleteAccount')}>Delete account</button>
                    </div>
                    <div className='col-md-8'>
                        {renderComponent()}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ProfileSettings;