import { Modal, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useAuth } from "../providers/AuthProvider";
import { getUserProfileId, cancelJoinRequest, getJoinRequest, rejectJoinRequest, approveJoinRequest, getUserDetails } from "../util/http";
import config from "../util/config";
import ModalMessage from "./ModalMessage";
import '../css/Notifications.css';


const JoinRequestModal = ({ show, onClose, joinId, joinType, joinNotificationUserId }) => {
    const { t } = useTranslation();
    const { token } = useAuth();
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('success');
    const [modalMessage, setModalMessage] = useState('');
    const [userId, setUserId] = useState('');
    const [joinRequestData, setJoinRequestData] = useState([]);
    const [requesterOrDriverData, setRequesterOrDriverData] = useState();

    useEffect(() => {
        getJoinRequestData();
        fetchUsersProfileId();
        getRequesterOrDriverData()
    }, []);

    async function getRequesterOrDriverData() {
        try {
            const response = await getUserDetails(joinNotificationUserId, token);
            setRequesterOrDriverData(response.data);
        } catch (error) {
            console.log('Could not fetch join request data !');
        }
    }

    async function getJoinRequestData() {
        setLoading(true);
        try {
            const response = await getJoinRequest(joinId, token);
            setJoinRequestData(response.data);
        } catch (error) {
            console.log('Could not fetch join request data !');
        } finally {
            setLoading(false);
        }
    }

    const fetchUsersProfileId = async () => {
        try {
            const response = await getUserProfileId(token);
            setUserId(response);
        } catch (error) {
            console.error('Error fetching user profile:', error);
        }
    }

    async function approve() {
        try {
            setLoading(true)
            await approveJoinRequest(joinId, token);
            onClose();
        } catch (error) {
            handleModal('failure', error);
        } finally {
            setLoading(false);
        }
    }

    async function cancelTrip() {
        try {
            setLoading(true)
            await cancelJoinRequest(joinId, token);
            onClose();
        } catch (error) {
            handleModal('failure', error);
        } finally {
            setLoading(false);
        }
    }

    async function reject() {
        try {
            setLoading(true)
            await rejectJoinRequest(joinId, token);
            onClose();
        } catch (error) {
            handleModal('failure', error);
        } finally {
            setLoading(false);
        }
    }

    /** show if auth user has posted the join request */
    function showCancelButton() {
        if ((joinRequestData.status === 'approved' || joinRequestData.status === 'requested') && joinRequestData.user_id === userId) {
            return true;
        }

        return false;
    }

    /** show if auth user has NOT posted the join request */
    function showApproveButton() {
        if (joinRequestData.status === 'requested' && joinRequestData.user_id !== userId) {
            return true;
        }

        return false;
    }

    /** show if auth user has NOT posted the join request */
    function showRejectButton() {
        if (joinRequestData.status === 'requested' && joinRequestData.user_id !== userId) {
            return true;
        }

        return false;
    }

    function handleModal(type, error = null) {
        if (type === 'success') {
            setModalType('success');
            setShowModal(true);
        } else if (type === 'failure') {
            setModalType('failure');
            if (error.response?.status && [400, 401, 422, 409].includes(error.response.status)) {
                setModalMessage(error.response.data.error);
            } else {
                setModalMessage(t('common:generalFormSubmitServerError'));
            }
            setShowModal(true);
        }
    }

    function handleCloseModal() {
        setShowModal(false);
        onClose();
    }

    return (
        <>
            <ModalMessage
                show={showModal}
                onClose={handleCloseModal}
                message={modalMessage}
                messageType={modalType}
            />

            <Modal size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered show={show} onHide={onClose} className="trip-details-modal-dialog">
                <Modal.Body>

                    <div className="row">
                        <div className="row mb-3">
                            <div className='col d-flex align-items-center mb-md-0'>
                                <p style={{ fontSize: "16px", fontWeight: "bold", marginBottom: '0px', color: '#02335B' }}>{joinRequestData.trip_data?.departure_city}</p>
                                <svg width="100" height="16" viewBox="0 0 100 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ margin: '0 20px' }}>
                                    <line x1="0" y1="12" x2="85" y2="12" stroke="#02335B" strokeWidth="2" />
                                    <polygon points="85,0 100,12 85,24" fill="#02335B" />
                                </svg>
                                <p style={{ fontSize: "16px", fontWeight: "bold", marginBottom: '0px', color: '#02335B' }}>{joinRequestData.trip_data?.destination_city}</p>
                            </div>
                            <div className="col align-items-center mb-3 mb-md-0">
                                <p style={{ margin: '0' }} className='text-end'><span style={{ fontWeight: "bold", fontSize: '16px', color: '#FD7E14' }}>{joinRequestData.status}</span></p>
                            </div>
                        </div>

                        <hr />

                        <div className="row">
                            <div className="col-md-6">
                                <div className="d-flex flex-column h-100">
                                    <p style={{ fontSize: '12px', color: "grey", marginBottom: '0' }}>Trip Start date</p>
                                    <p style={{ fontSize: '14px', fontWeight: "bold", color: "#02335B" }}>{joinRequestData.trip_data?.trip_start_time}</p>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="d-flex align-items-center">
                                    <div className="rounded-circle overflow-hidden"
                                        style={{ width: '40px', height: '40px', backgroundColor: 'lightgrey' }}>
                                        {requesterOrDriverData?.avatar ? (
                                            <img src={config.BACKEND_PIC_URL + '/' + requesterOrDriverData?.avatar} alt="Passenger Avatar" className="img-fluid" />
                                        ) : (
                                            <img src="img/noavatar.png"
                                                alt="NoAvatar" className="img-fluid" />
                                        )}
                                    </div>
                                    <div className='col-md-8' style={{ marginLeft: '10px' }}>
                                        <div className="row">
                                            <div className='align-items-center'>
                                                <p style={{ color: "#02335B", fontWeight: "bold", margin: '0', fontSize: '14px' }}>{requesterOrDriverData?.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col">
                            <div>
                                <p style={{ fontSize: '12px', color: "grey", marginBottom: '0' }}>{t('tripcard:passengerRequirementsLabel')}:</p>
                            </div>
                            <div className="row mt-2">
                                <div className='col-md-4 d-flex align-items-center'>
                                    {joinRequestData.requirements?.pet ? (
                                        <i className="bi bi-check-circle" style={{ color: 'green', fontSize: '20px', marginRight: '10px' }}></i>
                                    ) : (
                                        <i className="bi bi-x-circle" style={{ color: 'red', fontSize: '20px', marginRight: '10px' }}></i>
                                    )}
                                    <p style={{ fontSize: '12px', margin: '0' }}>Pet</p>
                                </div>
                                <div className='col-md-4 d-flex align-items-center'>
                                    {joinRequestData.requirements?.additional_passenger_category ? (
                                        <i className="bi bi-check-circle" style={{ color: 'green', fontSize: '20px', marginRight: '10px' }}></i>
                                    ) : (
                                        <i className="bi bi-x-circle" style={{ color: 'red', fontSize: '20px', marginRight: '10px' }}></i>
                                    )}
                                    <p style={{ fontSize: '12px', margin: '0' }}>Additional passenger</p>
                                </div>
                                <div className='col-md-4 d-flex align-items-center'>
                                    {joinRequestData.requirements?.suitcases ? (
                                        <i className="bi bi-suitcase-lg-fill" style={{ color: 'green', fontSize: '20px', marginRight: '10px' }}></i>
                                    ) : (
                                        <i className="bi bi-suitcase-lg-fill" style={{ color: 'red', fontSize: '20px', marginRight: '10px' }}></i>
                                    )}
                                    <p style={{ fontSize: '12px', margin: '0' }}>{joinRequestData.requirements?.suitcases}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {showApproveButton() && <button type="submit" onClick={approve} disabled={loading} className="approve-button">
                        {loading ? <div className="loading-spinner" /> : 'Approve'}
                    </button>}
                    {showRejectButton() && <button type="submit" onClick={reject} disabled={loading} className="reject-button">
                        {loading ? <div className="loading-spinner" /> : 'Reject'}
                    </button>}
                    {showCancelButton() && <button type="submit" onClick={cancelTrip} disabled={loading} className="reject-button">
                        {loading ? <div className="loading-spinner" /> : 'Cancel trip'}
                    </button>}
                </Modal.Footer>
            </Modal>
        </>

    );
}

export default JoinRequestModal;