export default {
    slogan: "Your story starts with us",
    subslogan: "Welcome to TravelWithMe, the premier carpooling platform connecting travelers between cities. We're here to revolutionize long-distance travel, making it affordable, convenient, and socially rewarding. Join TravelWithMe and embark on a seamless journey to your desired destination, while enjoying the company of fellow travelers.",
    socialConnections: "We bring everything you need for your carpooling journey into one platform. No need to navigate through multiple platforms — share stories, build new connections and create memorable experiences, all in one place.",
    costEfficiency: "Say goodbye to expensive bus or train tickets. TravelWithMe offers an affordable alternative for city-to-city travel. By sharing rides, you can split the costs of fuel, tolls, and parking fees, making your trips more budget-friendly without compromising comfort.",
    security: "Feel secure on your journey with TravelWithMe – your trusted carpooling platform. We prioritize your safety by implementing a robust rating and feedback system for both passengers and drivers. Our community-driven approach ensures that you can rely on the experiences and comments of fellow travelers, providing peace of mind as you share rides.",
    socialConnectionsLabel: "Social Connections",
    costEfficiencyLabel: "Cost Efficiency",
    securityLabel: "Security",
    tryOurApp: "Try Our App",
    tryOurAppText1: "Join TravelWithMe today and unlock a world of affordable, flexible, and socially rewarding travel. Say goodbye to the limitations of traditional transportation and embrace a new way of exploring cities, one ride at a time.",
    tryOurAppText2: "Start your city-to-city carpooling adventure with TravelWithMe now and make every journey an exciting chapter in your travel story!",
    footerTitle: "Find us",
    footerText: "We are here for you",
    testimonialsSection: "What our users say",
    weOfferLabel: 'What We Offer',
    emailConfirmedMessage: "Your email was successfully confirmed",
}