import { useEffect, useState, useRef } from "react";
import { useAuth } from "../providers/AuthProvider";
import { getSentJoinRequests, cancelJoinRequest } from "../util/http";
import SentJoinReqCard from "../components/SentJoinReqCard";
import { useTranslation } from 'react-i18next';
import { Modal, Button } from "react-bootstrap";
import LoadingOverlay from "../components/LoadingOverlay";
import { useNavigate } from "react-router-dom";

const SentJoinRequests = () => {
    const { t } = useTranslation();
    const { token } = useAuth();
    const [joinRequests, setJoinRequests] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState();
    const [loading, setLoading] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [selectedJoinReqId, setSelectedJoinReqId] = useState(null);
    const navigate = useNavigate();
    const [loadingMore, setLoadingMore] = useState(false);
    const loaderRef = useRef(null);

    const handleTripClick = (tripId) => {
        navigate(`/trips/${tripId}`);
    };

    const getNextPage = () => {
        if (currentPage < lastPage && !loadingMore) {
            getSentUserJoinRequests(currentPage + 1);
        }
    };

    useEffect(() => {
        getSentUserJoinRequests();
    }, []);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '20px',
            threshold: 0
        };

        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                getNextPage();
            }
        }, options);

        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [loadingMore]);

    async function getSentUserJoinRequests(page = 1) {
        setLoading(true);
        setLoadingMore(true);

        try {
            let pageToSearch = page !== 1 ? page : 1
            const response = await getSentJoinRequests(token, pageToSearch);
            setLastPage(response.last_page)
            setCurrentPage(response.current_page);
            setJoinRequests(prevJoinReq => page === 1 ? response.data : [...prevJoinReq, ...response.data]);
        } catch (error) {
            console.log('Could not fetch sent requests !');
        } finally {
            setLoading(false);
            setLoadingMore(false);
        }
    }

    function closeCancelModal() {
        setShowCancelModal(false);
    }

    async function handleCancelationRequest() {
        setShowCancelModal(false);
        try {
            setLoading(true)
            await cancelJoinRequest(selectedJoinReqId, token);
            setSelectedJoinReqId(null);
            getSentUserJoinRequests();
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    }

    function handleCancellationClick(joinReqId) {
        setSelectedJoinReqId(joinReqId);
        setShowCancelModal(true);
    }

    return (
        <LoadingOverlay loading={loading}>
            <div className='container'>
                <Modal
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    show={showCancelModal} onHide={() => { setShowCancelModal(false) }}>
                    <div className="text-center">
                        <p className="modal-p">{t('tripdetails:cancelJoinRequestConfirmation')}</p>
                    </div>
                    <Modal.Footer>
                        <Button className="btn custom-btn btn-md btn-block mx-auto" variant="secondary" onClick={() => handleCancelationRequest()}>
                            {t('tripdetails:yes')}
                        </Button>
                        <Button className="btn custom-btn btn-md btn-block mx-auto" variant="secondary" onClick={() => closeCancelModal()}>
                            {t('tripdetails:no')}
                        </Button>
                    </Modal.Footer>
                </Modal>

                {joinRequests.length > 0 ? (
                    <div>
                        {joinRequests.map((join, index) => (
                            <SentJoinReqCard loading={loading} showTrip={handleTripClick} cancelRequest={handleCancellationClick} join={join} key={index} />
                        ))}
                        {loading && <div className="spinner">Loading...</div>}
                        <div ref={loaderRef} style={{ height: '20px' }} />
                    </div>
                ) : (
                    <div className="d-flex justify-content-center pt-1 mb-4">
                        {!loading && <p>{t('common:noResultsFound')}</p>}
                    </div>
                )}
            </div>
        </LoadingOverlay>
    );
}

export default SentJoinRequests;