export default {
    sofia: 'София',
    varna: 'Варна',
    plovdiv: 'Пловдив',
    burgas: 'Бургас',
    ruse: 'Русе',
    staraZagora: 'Стара Загора',
    pleven: 'Плевен',
    sliven: 'Сливен',
    dobrich: 'Добрич',
    shumen: 'Шумен',
    haskovo: 'Хасково',
    pazardzhik: 'Пазарджик',
    yambol: 'Ямбол',
    pernik: 'Перник',
    blagoevgrad: 'Благоевград',
    velikoTarnovo: 'Велико Търново',
    vratsa: 'Враца',
    kardzhali: 'Кърджали',
    kyustendil: 'Кюстендил',
    gabrovo: 'Габрово',
    asenovgrad: 'Асеновград',
    vidin: 'Видин',
    kazanlak: 'Казанлък',
    montana: 'Монтана',
    targovishte: 'Търговище',
    dimitrovgrad: 'Димитровград',
    silistra: 'Силистра',
    lovech: 'Ловеч',
    razgrad: 'Разград',
    petrich: 'Петрич',
    dupnitsa: 'Дупница',
    gornaOryahovitsa: 'Горна Оряховица',
    smolyan: 'Смолян',
    sandanski: 'Сандански',
    samokov: 'Самоков',
    novaZagora: 'Нова Загора',
    karlovo: 'Карлово',
    velingrad: 'Велинград',
    sevlievo: 'Севлиево',
    lom: 'Лом',
    aytos: 'Айтос',
    svishtov: 'Свищов',
    harmanli: 'Харманли',
    troyan: 'Троян',
    botevgrad: 'Ботевград',
    gotseDelchev: 'Гоце Делчев',
    peshtera: 'Пещера',
    karnobat: 'Карнобат',
    svilengrad: 'Свиленград',
    momchilgrad: 'Момчилград',
    panagyurishte: 'Панагюрище',
    berkovitsa: 'Берковица',
    noviPazar: 'Нови Пазар',
    radomir: 'Радомир',
    ihtiman: 'Ихтиман',
    pomorie: 'Поморие',
    noviIskar: 'Нови Искър',
    nesebar: 'Несебър',
    radnevo: 'Раднево',
    balchik: 'Балчик',
    razlog: 'Разлог',
    byalaSlatina: 'Бяла Слатина',
    kozloduy: 'Козлодуй',
    kavarna: 'Каварна',
    stamboliyski: 'Стамболийски',
    pavlikeni: 'Павликени',
    isperih: 'Исперих',
    mezdra: 'Мездра',
    kostinbrod: 'Костинброд',
    etropole: 'Етрополе',
    bankya: 'Банкя',
    knezha: 'Кнежа',
    elhovo: 'Елхово',
    omurtag: 'Омуртаг',
    tutrakan: 'Тутракан',
    ardino: 'Ардино',
    lukovit: 'Луковит',
    teteven: 'Тетевен',
    kubrat: 'Кубрат',
    tryavna: 'Трявна',
    bansko: 'Банско',
    belene: 'Белене',
    svoge: 'Своге',
    dryanovo: 'Дряново',
    simitli: 'Симитли',
    tsarevo: 'Царево',
    dobrinishte: 'Добринище',
    noviHan: 'Нови Хан',
    apriltsi: 'Априлци',
    ahtopol: 'Ахтопол',
}