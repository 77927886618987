import React, { useState } from 'react';
import { useAuth } from '../providers/AuthProvider';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import ModalMessage from '../components/ModalMessage';
import '../css/Login.css';

const Register = () => {
    const { t } = useTranslation();

    const { onRegister } = useAuth();
    const [loading, setLoading] = useState(false);
    const { register, formState: { errors }, handleSubmit, watch } = useForm();
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('success');
    const [modalMessage, setModalMessage] = useState('');
    const navigate = useNavigate();

    const onSubmit = async () => {
        try {
            setLoading(true);
            const formData = watch();
            const response = await onRegister(formData.username, formData.email, formData.password);
            if (response.status === 201) {
                navigate("/confirm-registration");
            }
        } catch (err) {
            handleModal('failure', err);
        } finally {
            setLoading(false);
        }
    };

    function handleModal(type, error = null) {
        if (type === 'success') {
            setModalType('success');
            setShowModal(true);
        } else if (type === 'failure') {
            setModalType('failure');
            if (error.response?.status && [400, 401, 422, 409].includes(error.response.status)) {
                setModalMessage(error.response.data.error);
            } else {
                setModalMessage(t('common:generalFormSubmitServerError'));
            }
            setShowModal(true);
        }
    }

    function handleCloseModal() {
        setShowModal(false);
    }


    return (
        <div className='container-fluid'>
            <ModalMessage
                show={showModal}
                onClose={handleCloseModal}
                message={modalMessage}
                messageType={modalType}
            />

            <div className='row no-gutters'>
                <div className='col-md-8 image-column'>
                    <img src='img/login_background.png' />
                </div>
                <div className='col-md-4 form-container'>
                    <div className="custom-form">

                        <form onSubmit={handleSubmit(onSubmit)}>

                        <p style={{ fontWeight: 'bolder', letterSpacing: "1px", fontSize: '20px', color: "#02335B" }}>Register now! Your Next Adventure Awaits.</p>

                            <div className="form-outline mb-4">
                                <label className="input-label" htmlFor="username">Username</label>
                                <input type="text" id="registerUsername" className="form-control custom-input"
                                    {...register("username", { required: t('common:requiredField') })}
                                    aria-invalid={errors.username ? "true" : "false"}
                                    placeholder={t('common:username')}
                                />
                                <div>
                                    {errors.username && <span className="text-danger">{errors.username?.message}</span>}
                                </div>
                            </div>

                            <div className="form-outline mb-4">
                                <label className="input-label" htmlFor="email">Email address</label>
                                <input type="email" id="registerEmail" className="form-control custom-input"
                                    {...register("email", {
                                        required: t('common:requiredField'),
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: t('common:invalidEmailMessage')
                                        }
                                    })}
                                    aria-invalid={errors.email ? "true" : "false"}
                                    placeholder={t('common:emailLabel')}
                                />
                                <div>
                                    {errors.email && <span className="text-danger">{errors.email?.message}</span>}
                                </div>
                            </div>

                            <div className="form-outline mb-4">
                                <label className="input-label" htmlFor="password">Password</label>
                                <input type="password" id="registerPassword" className="form-control custom-input"
                                    {...register("password", {
                                        required: t('common:requiredField'),
                                        minLength: {
                                            value: 8,
                                            message: t('tripcreate:fieldMinSymbols8'),
                                        },
                                    })}
                                    aria-invalid={errors.password ? "true" : "false"}
                                    placeholder={t('common:password')}
                                />
                                <div>
                                    {errors.password && <span className="text-danger">{errors.password?.message}</span>}
                                </div>
                            </div>

                            <div className="pt-1 mb-4">
                                <button className="sign-button" type="submit" disabled={loading}>
                                    {loading ? <div className="loading-spinner" /> : t('common:registerLabel')}
                                </button>
                            </div>
                        </form>

                        <p onClick={() => navigate("/confirm-registration")} className="forgotten-password">{t('common:showConfirmMailScreen')}</p>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;