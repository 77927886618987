import React, { useState } from "react";
import TripsSearch from "../components/TripsSearch";
import { sendContactEmail } from "../util/http";
import '../css/Home.css';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import { useForm } from 'react-hook-form';

const Home = () => {
    const { t } = useTranslation();
    const [captcha, setCaptcha] = useState(null);
    const { register, handleSubmit, reset, formState: { errors } } = useForm();


    const handleCaptchaVerify = (response) => {
        setCaptcha(response);
    };

    const onSubmitMail = async (data) => {
        if (!captcha) {
            return;
        }

        data.captcha = captcha;

        try {
            await sendContactEmail(data);
            //handle success response with message beneath the form
        } catch (error) {
            console.error('Error sending mail:', error);
            //hnadle the error if 500 wit hmessage to the user beneath the form
        }

        reset();
        setCaptcha(null);
    };



    return (
        <div>
            <section id="search" className="search" style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/home/search_background.png)`, backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '100vh'
            }}>
                <div className="row">
                    <div className="col-md-12 d-flex flex-column bg-image d-flex justify-content-center align-items-center">
                        <div className="align-items-center">
                            <div className="container" style={{ marginTop: "60px" }}>
                                <div className="row d-flex align-items-center">
                                    <div className="col-12 col-xl-12">
                                        <p className="fs-1 text-center" style={{ fontWeight: 'bold', color: "#02335B" }}>{t('common:searchScreenTitle')}</p>
                                        <div className="d-flex justify-content-center">
                                            <p className="mb-5 text-center" style={{ fontWeight: 'bold', color: "#02335B", maxWidth: '70%' }}>{t('common:searchScreenSubtitle')}</p>
                                        </div>
                                        <TripsSearch />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="intro" className="intro">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 d-flex align-items-center">
                            <div className="text-left">
                                <p style={{ fontWeight: "bold", color: "#FD7E14" }}>Welcome to Travelwithme</p>
                                <h2 style={{ fontWeight: "bold" }}>Experience the joy of shared journeys and make every trip memorable.</h2>
                                <p style={{ marginTop: "20px" }}>The ultimate carpooling platform designed to make your intercity travel more affordable, social, and eco-friendly. Whether you're driving or looking for a ride, our platform connects you with like-minded travelers for a seamless and enjoyable journey.</p>
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div className="text-center">
                                <img src="/home/intro_background.png" alt="Image"
                                    style={{
                                        borderRadius: '30px',
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover"
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="benefits" className="benefits">
                <div className="container">
                    <h2 className="text-center" style={{ color: '#101D27', marginBottom: 40, marginTop: 50, fontWeight: "bold" }}>{t('home:weOfferLabel')}</h2>
                    <div className="row gx-4 justify-content-center mb-4">
                        <div className="col-md-4 text-center">
                            <div className="card text-center h-100 border-0" style={{ borderRadius: '30px' }}>
                                <div className="card-body" style={{ padding: '20px' }}>
                                    <div>
                                        <img src="/home/wwo_social_connections.png" alt="Social Connections" width="64" height="64" />
                                    </div>
                                    <h2 className="mt-3 mb-3" style={{ fontWeight: "bold" }}>{t('home:socialConnectionsLabel')}</h2>
                                    <p style={{ fontSize: 14 }}>{t('home:socialConnections')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="card text-center h-100 border-0" style={{ borderRadius: '30px' }}>
                                <div className="card-body" style={{ padding: '20px' }}>
                                    <div>
                                        <img src="/home/wwo_cost_efficiency.png" alt="Cost Efficiency" width="64" height="64" />
                                    </div>
                                    <h2 className="mt-3 mb-3" style={{ fontWeight: "bold" }}>{t('home:costEfficiencyLabel')}</h2>
                                    <p style={{ fontSize: 14 }}>{t('home:costEfficiency')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="card text-center h-100 border-0" style={{ borderRadius: '30px' }}>
                                <div className="card-body" style={{ padding: '20px' }}>
                                    <div>
                                        <img src="/home/wwo_security.png" alt="Security" width="64" height="64" />
                                    </div>
                                    <h2 className="mt-3 mb-3" style={{ fontWeight: "bold" }}>{t('home:securityLabel')}</h2>
                                    <p style={{ fontSize: 14 }}>{t('home:security')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="howitworks" className="howitworks" >
                <div className="container">
                    <h2 className="text-center" style={{ color: '#101D27', marginBottom: 40, marginTop: 50, fontWeight: "bold" }}>How It Works</h2>
                    <div className="row" style={{ marginTop: "50px", marginBottom: "50px" }}>
                        <div className="col-md-6 d-flex align-items-center order-2 order-md-1">
                            <div className="text-left">
                                <p style={{ fontSize: "24px", fontWeight: "bold" }}>For Drivers</p>
                                <p style={{ fontSize: "14px", marginTop: "20px" }}><span style={{ fontWeight: "bold" }}>Create a Journey:</span> Log in and list your trip details, including the destination, date, and time. Specify if your car has air conditioning, if smoking is allowed, and other preferences.</p>
                                <p style={{ fontSize: "14px", marginTop: "20px" }}><span style={{ fontWeight: "bold" }}>Receive Requests:</span> Other users can send join requests for your trip. Review their profiles and accept the requests that suit you.</p>
                                <p style={{ fontSize: "14px", marginTop: "20px" }}><span style={{ fontWeight: "bold" }}>Start Your Journey:</span> Enjoy your trip with new companions, share stories, and reduce travel costs.</p>
                            </div>
                        </div>

                        <div className="col-md-6 order-1 order-md-2">
                            <div className="text-center">
                                <img src="/home/how_it_works_drivers.png" alt="Image"
                                    className="howitworks-image"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: "50px", marginBottom: "50px" }}>
                        <div className="col-md-6">
                            <div className="text-center">
                                <img src="/home/how_it_works_passengers.png" alt="Image"
                                    className="howitworks-image"
                                />
                            </div>
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            <div className="text-left">
                                <p style={{ fontSize: "24px", fontWeight: "bold" }}>For Passengers</p>
                                <p style={{ fontSize: "14px", marginTop: "20px" }}><span style={{ fontWeight: "bold" }}>Search for Rides:</span> Log in and search for available rides to your destination on your preferred date and time.</p>
                                <p style={{ fontSize: "14px", marginTop: "20px" }}><span style={{ fontWeight: "bold" }}>Send Join Requests:</span> Apply to join trips that match your schedule and preferences. Wait for the driver to approve your request.</p>
                                <p style={{ fontSize: "14px", marginTop: "20px" }}><span style={{ fontWeight: "bold" }}>Enjoy the Ride:</span> Meet new people, share the journey, and enjoy a cost-effective travel experience.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="testimonials" className="testimonials">
                <div className="container">
                    <h2 className="text-center" style={{ color: '#101D27', marginBottom: 40, marginTop: 50, fontWeight: "bold" }}>Real Stories, Real Experiences</h2>
                    <p className="text-center" style={{ color: '#547692', marginBottom: 40, marginTop: 50, fontWeight: "bold" }}>Discover firsthand experiences from TravelWithMe users. Get inspired by their stories and adventures.</p>
                    <div className="row gx-4 justify-content-center mb-4">
                        <div className="col-md-3 text-center">
                            <div className="card text-center h-100 border-0" style={{ borderRadius: '30px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <div className="card-body" style={{ padding: '20px' }}>
                                    <p style={{ fontSize: 14 }}>"Лесно намерих пътуване по моя маршрут в удобно за мен време. Шофьорът беше точен и приятелски настроен, а колата беше чиста и удобна. Това беше не само изгодно решение, но и забавно. Определено ще използвам Travelwithme отново за бъдещите си пътувания."</p>
                                    <div>
                                        <span style={{ fontSize: '20px', color: '#159E7F' }}>★★★★★</span>
                                    </div>
                                    <div style={{ marginTop: '10px', fontWeight: 'bold' }}>
                                        Христина Д.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 text-center">
                            <div className="card text-center h-100 border-0" style={{ borderRadius: '30px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <div className="card-body" style={{ padding: '20px' }}>
                                    <p style={{ fontSize: 14 }}>"Страхотно изживяване за мен като шофьор! Приложението е много лесно за използване и бързо намерих пътници. Пътниците бяха много забавни и разговорите не спираха. Освен това разходите ми за гориво бяха значително по-малки."</p>
                                    <div>
                                        <span style={{ fontSize: '20px', color: '#159E7F' }}>★★★★★</span>
                                    </div>
                                    <div style={{ marginTop: '10px', fontWeight: 'bold' }}>
                                        Иван Д.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="mobileApp" style={{ backgroundColor: '#fff', padding: '50px 50px' }}>
                        <h2 className="text-center" style={{ color: '#ff7022', marginBottom: 40 }}>{t('home:tryOurApp')}</h2>
                        <p className="text-center" style={{ fontSize: 20 }}>{t('home:tryOurAppText1')}<br />{t('home:tryOurAppText2')}</p>
                        <div className="row justify-content-center">
                            <div className="col-md-12 mb-4 text-center">
                                <a href="#work" target="_blank"><img className="store-icon" src="/img/apple_store_download.jpg" /></a>
                            </div>
                            <div className="col-md-12 mb-4 text-center">
                                <a href="https://play.google.com/store/apps/details?id=com.travelwithmemobile" target="_blank"><img className="store-icon" src="/img/play_store_download.jpg" /></a>
                            </div>
                        </div>
                    </section> */}

            <section id="faq" className="faq" style={{ backgroundColor: "white", paddingTop: "50px", paddingBottom: "50px", margin: '0 auto' }}>
                <div className="container">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-6 mb-4">
                            <h2 style={{ fontWeight: "bold" }}>Frequently asked</h2>
                            <h2 style={{ fontWeight: "bold" }}>questions</h2>
                        </div>
                        <div className="col-md-6 mb-4">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>How do I join a ride?</Accordion.Header>
                                    <Accordion.Body>
                                        Log in, search for rides, and send a join request to the driver.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>How are safety measures implemented</Accordion.Header>
                                    <Accordion.Body>
                                        We have implemented a rating strategy for both drivers and passengers.
                                        You can also file a complaint for another user and a person can be banned from using the platform.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Can I create a ride for the same city?</Accordion.Header>
                                    <Accordion.Body>
                                        No, you cannot create a ride for the same city.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="position-relative">
                                <img src="/home/faq_join_ride.png" className="img-fluid w-100 faq-join-image" alt="Image 1" />
                                <div className="overlay-text-center">
                                    <h2 style={{ color: "white", fontWeight: "bold", marginBottom: "30px" }}>Join a Ride Today</h2>
                                    <a href="#search" className="faq-join-ride-button">Search for a ride</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mb-4">
                            <div className="position-relative">
                                <img src="/home/faq_safety_security.png" className="img-fluid w-100" alt="Image 2" />
                                <div className="overlay-text-bottom-left">
                                    <h2 style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>Safety and Security</h2>
                                    <p style={{ color: "white", fontSize: "14px" }}>Your safety is our top priority. Our platform includes a robust rating and feedback system, ensuring that both drivers and passengers can trust their travel companions.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-4">
                            <div className="position-relative">
                                <img src="/home/faq_convenience_comfort.png" className="img-fluid w-100" alt="Image 3" />
                                <div className="overlay-text-bottom-left">
                                    <h2 style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>Convenience and Comfort</h2>
                                    <p style={{ color: "white", fontSize: "14px" }}>Easily search for and join rides that match your schedule and preferences. Enjoy features like air conditioning options, smoking preferences, and more for a comfortable journey.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Contact Section */}
            <section id="contact" className="contact" style={{ backgroundColor: '#02335B' }}>
                <div className="container">
                    <div className="row" style={{ paddingTop: '50px', paddingBottom: '50px' }}>
                        <div className="col-md-6">
                            <img
                                alt=""
                                src="/img/logo_main.png"
                                width="120"
                                height="120"
                                className="align-center main-logo"
                            />
                            <img
                                alt=""
                                src="/img/text_logo.png"
                                className="align-center secondary-logo"
                            />
                            <p className="card-text" style={{ color: "white", fontSize: 18 }}>{t('common:contactUsAssistText')}</p>
                            <p className="card-text" style={{ color: "white", fontSize: 18 }}><span style={{ fontWeight: "bold" }}>@</span> assist.travelwithme@gmail.com</p>
                        </div>
                        <div className="col-md-6 mt-4">
                            <h2 style={{ color: "white" }}>REQUEST A CALL BACK</h2>
                            <form onSubmit={handleSubmit(onSubmitMail)} className="custom-form mt-5">
                                <div className="mb-3">
                                    <input type="text" placeholder="Full Name" className={`form-control placeholder-light-grey ${errors.name ? 'is-invalid' : ''}`} id="name" {...register('name', { required: true })} />
                                    {errors.name && <div className="invalid-feedback">Полето е задължително</div>}
                                </div>
                                <div className="mb-3">
                                    <input type="email" placeholder='Email' className={`form-control placeholder-light-grey ${errors.email ? 'is-invalid' : ''}`} id="email" {...register('email', { required: true, pattern: /^\S+@\S+$/i })} />
                                    {errors.email && <div className="invalid-feedback">Моля въведете правилен имейл</div>}
                                </div>
                                <div className="mb-3">
                                    <textarea placeholder='Message' className={`form-control placeholder-light-grey ${errors.message ? 'is-invalid' : ''}`} id="message" style={{ height: "150px" }} {...register('message', { required: true })} rows="4" ></textarea>
                                    {errors.message && <div className="invalid-feedback">Полето е задължително</div>}
                                </div>
                                <div className="mb-3">
                                    <ReCAPTCHA
                                        sitekey="6Lc61vEpAAAAAI_oAGA0J6lK5BkHaT-SI7sZFd27"
                                        onChange={handleCaptchaVerify}
                                        ref={(r) => (window.recaptchaVerifier = r)}
                                    />
                                    {errors.captcha && <div className="invalid-feedback">{errors.captcha.message}</div>}
                                </div>
                                <button type="submit" style={{ backgroundColor: "#20C997", borderRadius: "30px", padding: "10px 10px 10px 10px", border: "none", width: "50%" }}>Изпрати</button>
                            </form>
                        </div>
                    </div>
                    <hr style={{ borderTop: "2px solid grey" }}></hr>
                    <div className="row justify-content-between">
                        <div className="col-auto">
                            <p style={{ color: "white" }}>&copy; 2023 TravelWithMe. Всички права запазени.</p>
                        </div>
                        <div className="col-auto">
                            <Link to="/privacy-policy" style={{ color: '#fff', textDecoration: 'underline' }}>{t('common:privacyPolicyLabel')}</Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;