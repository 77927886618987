export default {
    tripCreateTitle: "Създай своето преживяване сега",
    createButtonLabel: "Създай",
    createFormLeaving: "Тръгвам от",
    createFormGoing: "Пристигам в",
    selectStartTime: 'Час на тръгване',
    selectArrivalTime: 'Час на пристигане',
    departurePoint: 'Начална точка',
    pricePerPassenger: 'Цена на пътник',
    carBrand: 'Марка кола',
    carModel: 'Модел кола',
    seatsCapacity: 'Капацитет места',
    seatsTaken: 'Заети места',
    suitcasesCapacity: 'Капацитет куфари',
    suitcasesTaken: 'Заети места за багаж',
    petsAllowed: 'Домашни любимци',
    smokingAllowed: 'Разрешено пушене',
    airConditioning: 'Климатик',
    picksFromAddress: 'Взема от адрес',
    saveConfiguration: 'Запази като лична конфигурация',
    onlyNumericValues: "Моля въведете число",
    fieldMinSymbols1: "Mин 1 символ",
    fieldMinSymbols8: "Mин 8 символа",
    fieldMinSymbols2: "Mин 2 символа",
    fieldMaxSymbols20: "Макс 20 символа",
    tripCreatedModalMessage: "Вашето пътуване беше създадено",
    tripCreateFailureModalMessage: "Възникна проблем при създаването на вашето пътуване. Моля опитайте по-късно или се свържете с нас за съдействие.",
}