import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import TripsPassengerPlanned from "./TripsPassengerPlanned";
import TripsPassengerFinished from "./TripsPassengerFinished";

const TripsPassenger = () => {
  const { t } = useTranslation();
  const [selectedComponent, setSelectedComponent] = useState("planned");

  const handleSelectComponent = (component) => {
    setSelectedComponent(component);
  };

  return (
    <div className="row justify-content-center">
      <div className="col-auto">
        <div className="d-flex">
          <button
            onClick={() => handleSelectComponent("planned")}
            className={`${selectedComponent === "planned" ? 'link-text-button-active' : 'link-text-button-inactive'}`}
          >
            {t('common:planned')}
          </button>
          <button
            onClick={() => handleSelectComponent("finished")}
            className={`link-text-button ${selectedComponent === "finished" ? 'link-text-button-active' : 'link-text-button-inactive'}`}
          >
            {t('common:finished')}
          </button>
        </div>
      </div>

      <hr/>

      <div>
        {selectedComponent === "planned" && <TripsPassengerPlanned />}
        {selectedComponent === "finished" && <TripsPassengerFinished />}
      </div>
    </div>
  );
}

export default TripsPassenger;