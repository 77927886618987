import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

const languageOptions = [
    {
        id: "en",
        flagimg: "img/flag-en.png",
    },
    {
        id: "bg",
        flagimg: "/img/flag-bg.png",
    },
];

function LanguageSelector() {
    const { i18n } = useTranslation();
    const defaultLanguage = i18n.language;
    const [selectedLanguage, setSelectedLanguage] = useState({ id: defaultLanguage });
    const [cssDisplay, setCssDisplay] = useState('none');

    useEffect(() => {
        // Initialize selected language from i18n language
        setSelectedLanguage({ id: i18n.language });
      }, [i18n.language]);

    function setLanguage(lang) {
        setSelectedLanguage({ id: lang })
        i18n.changeLanguage(lang);
        localStorage.setItem('i18nextLng', lang); // Store the selected language in localStorage
    };

    /**
     * get the language options without the currently selected one
     */
    function getAvailableLanguageOptions() {
        const filtered = languageOptions.filter(function (el) { return el.id !== selectedLanguage.id; });
        return filtered;
    }

    function getSelectedLanguageIcon() {
        let lang = languageOptions.find(o => o.id === selectedLanguage.id);
        return lang.flagimg;
    }

    const showDropdown = () => {
        if (cssDisplay === 'none') {
            setCssDisplay('block');
        } else {
            setCssDisplay('none');
        }
    };

    const selectLanguage = (lang) => {
        setLanguage(lang);
        setCssDisplay('none');
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px', marginLeft: '20px', position: 'relative' }}>
            <button style={{ ...styles.buttonNoStyle, alignSelf: 'center' }} onClick={showDropdown}>
                <img style={styles.flagIcon} src={getSelectedLanguageIcon()} alt="Selected Language" />
            </button>
            <div style={{ height: '40px', position: 'absolute', top: '100%' }}>
                <ul style={{ display: cssDisplay, listStyleType: "none", paddingInlineStart: "0px" }}>
                    {getAvailableLanguageOptions().map((lang) => (
                        <li id={lang.id} key={lang.id} disabled>
                            <img onClick={() => selectLanguage(lang.id)} style={styles.flagIcon} src={lang.flagimg} alt={lang.name} id={lang.id} />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

LanguageSelector.displayName = "/src/widgets/layout/language-selector.jsx";

export default LanguageSelector;

const styles = {
    flagIcon: {
        height: 30,
        width: 30,
        alt: "flagpic",
        borderRadius: "50%",
        borderColor: 'white',
    },
    buttonNoStyle: {
        background: "none",
        border: "none",
        padding: 0,
        cursor: "pointer",
        outline: "none",
    },
};
