import { useTranslation } from 'react-i18next';
import { useAuth } from "../providers/AuthProvider";
import UserDetails from './UserDetails';
import { useState } from 'react';
import config from '../util/config';
import '../css/TripCard.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import { useNavigate } from "react-router-dom";


const TripCard = ({ trip, index }) => {
    const { token } = useAuth();
    const { t } = useTranslation();
    const [selectedUser, setSelectedUser] = useState(null);
    const navigate = useNavigate();

    /**
     * 
     * @param  userId 
     * if user is not authenticated dont show anything because unauthenticated users 
     * should not be able to view other users
     */
    const handleUserClick = (userId) => {
        if (!token) {
            return;
        }
        setSelectedUser(userId);
    };

    const handleCloseModal = () => {
        setSelectedUser(null);
    };

    return (
        <>
            {selectedUser && <UserDetails
                show={!!selectedUser}
                onClose={handleCloseModal}
                userId={selectedUser}
            />}

            <div className="d-flex justify-content-center">
                <div className="col-12">
                    <div className="card mt-3 shadow-sm bg-body" style={{ border: '0.5px solid #ccc', borderRadius: '20px' }} key={index}>
                        <div className="card-body">

                            <div className="row">
                                <div className='col d-flex align-items-center mb-md-0'>
                                    <p style={{ fontSize: "22px", fontWeight: "bold", marginBottom: '0px', color: '#02335B' }}>{trip.departure_city}</p>
                                    <svg width="100" height="22" viewBox="0 0 100 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ margin: '0 20px' }}>
                                        <line x1="0" y1="12" x2="85" y2="12" stroke="#02335B" strokeWidth="2" />
                                        <polygon points="85,0 100,12 85,24" fill="#02335B" />
                                    </svg>
                                    <p style={{ fontSize: "22px", fontWeight: "bold", marginBottom: '0px', color: '#02335B' }}>{trip.destination_city}</p>
                                </div>
                                <div className="col align-items-center mb-3 mb-md-0">
                                    <p style={{margin: '0'}}  className='text-end'><span style={{ fontWeight: "bold", fontSize: '24px', color: '#FD7E14' }}>{trip.price_per_passenger} lv </span><span style={{ fontSize: '10px', color: '#02335B' }}>/ seat price</span></p>
                                </div>
                            </div>

                            <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />

                            <div className="row mt-4 custom-row">
                                <div className="col-md-4 custom-col">
                                    <div className="d-flex flex-column h-100">
                                        <p style={{ fontSize: '12px', color: "grey" }}>Trip Start date</p>
                                        <p style={{ fontSize: '14px', fontWeight: "bold", color: "#02335B" }}>{trip.trip_start_time}</p>
                                        <div>
                                            <p style={{ fontSize: '12px', color: 'grey' }}>{t('tripdetails:departurePoint')}: <span style={{ color: "#02335B", fontWeight: "bold" }}>{trip.departure_point}</span></p>
                                        </div>
                                        <div>
                                            <p style={{ fontSize: '12px', color: 'grey' }}>Estimated arrival: <span style={{ color: "#02335B", fontWeight: "bold" }}>{trip.estimated_arrival_time}</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 custom-col">
                                    <div className="d-flex flex-column justify-content-left">
                                        <div>
                                            <p style={{ fontSize: '12px', color: 'grey' }}>Preferences and Policies:</p>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6 d-flex align-items-center'>
                                                {trip.smoking_allowed ? (
                                                    <i className="bi bi-check-circle" style={{ color: 'green', fontSize: '20px', marginRight: '10px' }}></i>
                                                ) : (
                                                    <i className="bi bi-x-circle" style={{ color: 'red', fontSize: '20px', marginRight: '10px' }}></i>
                                                )}
                                                <p style={{ fontSize: '12px', margin: '0' }}>Smoking allowed</p>
                                            </div>
                                            <div className='col-6 d-flex align-items-center'>
                                                {trip.air_conditioning ? (
                                                    <i className="bi bi-check-circle" style={{ color: 'green', fontSize: '20px', marginRight: '10px' }}></i>
                                                ) : (
                                                    <i className="bi bi-x-circle" style={{ color: 'red', fontSize: '20px', marginRight: '10px' }}></i>
                                                )}
                                                <p style={{ fontSize: '12px', margin: '0' }}>Air conditioning</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6 d-flex align-items-center'>
                                                {trip.pets_allowed ? (
                                                    <i className="bi bi-check-circle" style={{ color: 'green', fontSize: '20px', marginRight: '10px' }}></i>
                                                ) : (
                                                    <i className="bi bi-x-circle" style={{ color: 'red', fontSize: '20px', marginRight: '10px' }}></i>
                                                )}
                                                <p style={{ fontSize: '12px', margin: '0' }}>Pets allowed</p>
                                            </div>
                                            <div className='col-6 d-flex align-items-center'>
                                                {trip.picks_from_address ? (
                                                    <i className="bi bi-check-circle" style={{ color: 'green', fontSize: '20px', marginRight: '10px' }}></i>
                                                ) : (
                                                    <i className="bi bi-x-circle" style={{ color: 'red', fontSize: '20px', marginRight: '10px' }}></i>
                                                )}
                                                <p style={{ fontSize: '12px', margin: '0' }}>Picks from address</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 text-end custom-col seats-left-responsive">
                                    <div className="d-flex flex-column justify-content-left">
                                        <div>
                                            <p style={{ fontSize: '14px', color: 'grey' }}>Seats left</p>
                                            <p style={{ fontSize: '16px', fontWeight: "bold", color: '#02335B' }}>{trip.seats_left}</p>
                                            <p style={{ fontSize: '16px', color: '#02335B' }}>{trip.seats_taken} seats booked</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />

                            <div className='row'>
                                <div id="driver-avatar" className="col-md-4">
                                    <div className="d-flex flex-row justify-content-center align-items-center">
                                        <div className="col-4 rounded-circle overflow-hidden clickable-circle"
                                            onClick={() => handleUserClick(trip.driver.id)} style={{ width: '60px', height: '60px', backgroundColor: 'lightgrey' }}>
                                            {trip.driver.driver_avatar ? (
                                                <img src={config.BACKEND_PIC_URL + '/' + trip.driver.driver_avatar} alt="Driver Avatar" className="img-fluid" />
                                            ) : (
                                                <img src="img/noavatar.png"
                                                    alt="NoAvatar" className="img-fluid" />
                                            )}
                                        </div>
                                        <div className='col-8' style={{ marginLeft: '10px' }}>
                                            <div className="row">
                                                <div className='align-items-center'>
                                                    <p style={{ color: "#02335B", fontWeight: "bold", margin: '0', fontSize: '14px'}}>{trip.driver.name}</p>
                                                </div>
                                                <div className='d-flex flex-row align-items-center'>
                                                    <i className="bi bi-star-fill" style={{ color: '#FFAF34', fontSize: '18px', marginRight: '10px' }}></i>
                                                    <p style={{ margin: '0', fontSize: '13px' }}>{trip.driver.avg_driver_rating ? trip.driver.avg_driver_rating : 0}/5</p>
                                                    <div className='col'>
                                                        <p style={{ margin: 0, marginLeft: '2px', fontSize: '13px' }}><span style={{ color: "grey" }}>({trip.driver.count_rated_by} reviews)</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="car-avatar" className="col-md-4">
                                    <div className="d-flex flex-row justify-content-center align-items-center">
                                        <div className="col-4 rounded-circle overflow-hidden" style={{ width: '60px', height: '60px' }}>
                                            {trip.driver.driver_avatar ? (
                                                <img src={config.BACKEND_PIC_URL + '/' + trip.driver.driver_avatar} alt="Driver Car" className="img-fluid" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                            ) : (
                                                <img src="img/default_car_image.png" alt="NoCar" className="img-fluid" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                            )}
                                        </div>
                                        <div className='col-8' style={{ marginLeft: '10px' }}>
                                            <div className="row">
                                                <div className='align-items-center'>
                                                    <p style={{ color: "#02335B", fontWeight: "bold", margin: '0', fontSize: '14px' }}>{trip.driver.car.brand} {trip.driver.car.model}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {token && <div id="details-button" className='col text-end order-md-last'>
                                    <button onClick={() => navigate(`/trips/${trip.id}`)} className='custom-button'>See details</button>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default TripCard;