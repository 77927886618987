import React from "react";
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    return (
        <div className="container">
            <div className="text-center">
                <p>Privacy Policy</p>
                <p>Effective Date: 18.08.2023</p>

                <p>
                    1. Introduction<br />
                    Welcome to TravelWithMe ("we," "us," or "our"). We are committed to protecting your privacy and safeguarding your personal information. This Privacy Policy outlines how we collect, use, and disclose information when you use the TravelWithMe website and services. By accessing or using our services, you agree to the terms outlined in this policy.
                </p>

                <p>
                    2. Information We Collect<br />
                    2.1 Personal Information:<br />
                    We may collect personal information from you, such as your name, email address. This information is collected when you create an account, use our services, or communicate with us.<br />
                    2.2 Ride Information:<br />
                    To facilitate carpooling, we collect information about your ride preferences, including your origin, destination, and preferred times. This information helps us match you with potential carpool partners.<br />
                    2.3 Usage Information:<br />
                    We may collect data about your interactions with our website and services, such as the pages you visit, the features you use, and your activities within the platform. This information helps us improve our services and enhance your experience.
                </p>

                <p>
                    3. How We Use Your Information<br />
                    3.1 Provide and Improve Services:<br />
                    We use your information to provide carpooling services, personalize your experience, and enhance our offerings based on user feedback.<br />
                    3.2 Communication:<br />
                    We may use your contact information to send you updates, notifications, and important announcements related to your carpooling activity.<br />
                    3.3 Matchmaking:<br />
                    We use ride information to match you with potential carpool partners, taking into account factors like location and preferences.
                </p>

                {/* ... More points ... */}

                <p>
                    8. Changes to this Privacy Policy<br />
                    We may update this Privacy Policy periodically. Any changes will be posted on our website, and the effective date will be revised accordingly.
                </p>

                <p>
                    9. Contact Us<br />
                    If you have any questions or concerns regarding this Privacy Policy, please contact us at assist.travelwithme@gmail.com.
                </p>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
