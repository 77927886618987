import StarRatings from "react-star-ratings";
import { Modal, Button } from "react-bootstrap";
import { ratePassenger, getUserDetails, rateDriver } from "../util/http";
import { useAuth } from "../providers/AuthProvider";
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import config from "../util/config";
import ModalMessage from "./ModalMessage";

const RateUser = ({ show, onClose, userId, userType }) => {
    const { t } = useTranslation();
    const { token } = useAuth();
    const [userRating, setUserRating] = useState();
    const [comment, setComment] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('success');
    const [modalMessage, setModalMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState();

    useEffect(() => {
        getUserData();
    }, []);

    async function getUserData() {
        try {
            const response = await getUserDetails(userId, token);
            setUserData(response.data);
        } catch (error) {
            handleModal('failure', error);
        }
    }

    async function rate() {
        if (userRating == null) {
            return;
        }

        let payload = {
            rating: parseInt(userRating),
            comment: comment,
        }

        if (userType == 'driver') {
            try {
                setLoading(true);
                const response = await rateDriver(userId, payload, token);
                if (response.status === 201) {
                    setModalMessage(t('profile:rateSuccessful'));
                    handleModal('success');
                }
            } catch (error) {
                console.log(error.response)
                handleModal('failure', error);
            } finally {
                setLoading(false);
            }
        } else if (userType == 'passenger') {
            try {
                setLoading(true);
                const response = await ratePassenger(userId, payload, token);
                if (response.status === 201) {
                    setModalMessage(t('profile:rateSuccessful'));
                    handleModal('success');
                }
            } catch (error) {
                handleModal('failure', error);
            } finally {
                setLoading(false);
            }
        }
    }

    function handleModal(type, error = null) {
        if (type === 'success') {
            setModalType('success');
            setShowModal(true);
        } else if (type === 'failure') {
            setModalType('failure');
            if (error.response?.status && [400, 401, 422, 409].includes(error.response.status)) {
                setModalMessage(error.response.data.error);
            } else {
                setModalMessage(t('common:generalFormSubmitServerError'));
            }
            setShowModal(true);
        }
    }

    function handleCloseModal() {
        setShowModal(false);
        onClose();
    }

    return (
        <>
            <ModalMessage
                show={showModal}
                onClose={handleCloseModal}
                message={modalMessage}
                messageType={modalType}
            />

            <Modal size="md"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered show={show} onHide={onClose} className="trip-details-modal-dialog">
                <Modal.Body>

                    <div className="mb-3">
                        <div className="text-center">
                            <div className="rounded-circle overflow-hidden mx-auto"
                                style={{ width: '60px', height: '60px', backgroundColor: 'lightgrey' }}>
                                {userData?.avatar ? (
                                    <img src={config.BACKEND_PIC_URL + '/' + userData?.avatar} alt="Driver Avatar" className="img-fluid" />
                                ) : (
                                    <img src="img/noavatar.png"
                                        alt="NoAvatar" className="img-fluid" />
                                )}
                            </div>
                            <div>
                                <p>{userData?.name}</p>
                            </div>
                        </div>
                    </div>

                    <div className="mb-3">
                        <div className="text-center">
                            <StarRatings
                                rating={userRating}
                                starRatedColor="#ff7022"
                                changeRating={setUserRating}
                                numberOfStars={5}
                                name='rating'
                                starDimension="40px"
                                starSpacing="15px"
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="rateUserTextarea" className="form-label">{t('profile:comment')}</label>
                        <textarea
                            className="form-control"
                            id="rateUserTextarea"
                            rows="4"
                            maxLength="500"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        ></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className="btn custom-btn btn-md btn-block mx-auto" onClick={onClose}>
                        {t('common:closeButtonLabel')}
                    </Button>
                    <button type="submit" onClick={rate} disabled={loading} className="btn custom-btn btn-md btn-block mx-auto">
                        {loading ? <div className="loading-spinner" /> : t('notifications:rateLabel')}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default RateUser;