import { useState, useEffect } from "react";
import { useAuth } from "../providers/AuthProvider";
import { deleteAvatar, updateAvatar } from "../util/http";
import { useTranslation } from 'react-i18next';
import config from "../util/config";
import StarRatings from "react-star-ratings";
import ModalMessage from "../components/ModalMessage";
import "bootstrap-icons/font/bootstrap-icons.css";
import '../css/Profile.css';
import ProfileSettings from "../components/Profile/ProfileSettings";

const Profile = () => {
    const { t } = useTranslation();
    const { token, getUserData, userData } = useAuth();
    const [loading, setLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState('driver');

    const [showProfileSettings, setShowProfileSettings] = useState(false);

    const driverReviews = userData?.driver.comments || [];
    const passengerReviews = userData?.passenger.comments || [];

    const reviewsToDisplay = selectedTab === 'driver' ? driverReviews : passengerReviews;

    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('success');
    const [modalMessage, setModalMessage] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Validate file extension
            const allowedExtensions = ['jpg', 'jpeg', 'png'];
            const fileExtension = file.name.split('.').pop().toLowerCase();

            if (!allowedExtensions.includes(fileExtension)) {
                setModalMessage(t('profile:invalidFileFormatMessage'));
                handleModal('success');
                return;
            }

            // Validate file size (in bytes)
            const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB
            if (file.size > maxSizeInBytes) {
                setModalMessage(t('profile:sizeExceedsLimitMessage'));
                handleModal('success');
                return;
            }

            addAvatar(file);
        }
    };

    const removeAvatar = async () => {
        try {
            const response = await deleteAvatar(token);
            if (response.status === 204) {
                setModalMessage('Avatar updated');
                handleModal('success');
            }
        } catch (err) {
            handleModal('failure', err);
        }
    }

    const addAvatar = async (file) => {
        try {
            const formData = new FormData();
            formData.append('photo', file);

            const response = await updateAvatar(formData, token);
            if (response.status === 201) {
                setModalMessage('Avatar updated');
                handleModal('success');
            }
        } catch (err) {
            console.log(err.response.data)
            handleModal('failure', err);
        }
    }


    function handleModal(type, error = null) {
        if (type === 'success') {
            setModalType('success');
            setShowModal(true);
        } else if (type === 'failure') {
            setModalType('failure');
            if (error.response?.status && [400, 401, 422, 409].includes(error.response.status)) {
                setModalMessage(error.response.data.error);
            } else {
                setModalMessage(t('common:generalFormSubmitServerError'));
            }
            setShowModal(true);
        }
    }

    function handleCloseModal() {
        setShowModal(false);
        if (modalType === 'success') {
            getUserData();
        }
    }

    return (
        <>
            <ModalMessage
                show={showModal}
                onClose={handleCloseModal}
                message={modalMessage}
                messageType={modalType}
            />

            <ProfileSettings
                show={showProfileSettings}
                handleClose={() => setShowProfileSettings(false)}
            />

            <div className="container h-100" style={{ maxWidth: "none" }}>
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-xl-10 mt-5">
                        <div className="card" style={{ borderRadius: "1rem", boxShadow: 'none' }}>
                            <div className="card-body text-center">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="position-relative">
                                            {userData?.personal_info.avatar ? <img src={config.BACKEND_PIC_URL + '/' + userData?.personal_info.avatar} alt="Avatar" className="img-fluid my-5" style={{ width: "80px" }} /> : <img src="img/noavatar.png"
                                                alt="NoAvatar" className="img-fluid my-5" style={{ width: "80px" }} />}

                                            <div className="d-flex justify-content-center mb-3">
                                                <label htmlFor="upload" style={{ marginRight: '30px' }}>

                                                    <input type="file" id="upload" style={{ display: "none" }} onChange={handleFileChange} />
                                                </label>

                                                {userData?.personal_info.avatar && <label htmlFor="delete" className="ml-3">

                                                </label>}
                                            </div>
                                        </div>
                                        <p style={{ color: '#02335B', fontSize: '20px', fontWeight: 'bold' }}>{userData?.personal_info?.name}</p>
                                        <button className="profile-settings-button" onClick={() => setShowProfileSettings(true)}>Profile Settings</button>
                                    </div>

                                    <div className="col-md-9">
                                        <div id="personal-info" className="row mt-5">
                                            <p className="section-label">Profile information</p>
                                            <hr />

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="d-flex align-items-center">
                                                        <i className="bi bi-envelope-at-fill" style={{ color: 'orange', fontSize: '24px', marginRight: '10px' }}></i>
                                                        <p className="profile-info-element-label">Email</p>
                                                        {userData?.personal_info.is_email_validated && <i className="bi bi-patch-check-fill" style={{ color: 'green', fontSize: '20px', marginLeft: '10px' }}></i>}
                                                    </div>
                                                    <p className="profile-info-element-text">{userData?.personal_info.email}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="d-flex align-items-center">
                                                        <i className="bi bi-calendar-week" style={{ color: 'green', fontSize: '24px', marginRight: '10px' }}></i>
                                                        <p className="profile-info-element-label">Member since</p>
                                                    </div>
                                                    <p className="profile-info-element-text">{userData?.personal_info.member_since}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="d-flex align-items-center">
                                                        <i className="bi bi-cash-stack" style={{ color: 'purple', fontSize: '24px', marginRight: '10px' }}></i>
                                                        <p className="profile-info-element-label">Total earned</p>
                                                    </div>
                                                    <p className="profile-info-element-text">{userData?.personal_info.total_money_earned ? userData.personal_info?.total_money_earned : 0} lv</p>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-md-4">
                                                    <div className="d-flex align-items-center">
                                                        <i className="bi bi-car-front-fill" style={{ color: 'green', fontSize: '24px', marginRight: '10px' }}></i>
                                                        <p className="profile-info-element-label">Total rides driver</p>
                                                    </div>
                                                    <p className="profile-info-element-text">{userData?.driver.trips}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="d-flex align-items-center">
                                                        <i className="bi bi-car-front-fill" style={{ color: '#68C4DA', fontSize: '24px', marginRight: '10px' }}></i>
                                                        <p className="profile-info-element-label">Total rides passenger</p>
                                                    </div>
                                                    <p className="profile-info-element-text">{userData?.passenger.trips}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="car-info" className="row mt-5">
                                            <p className="section-label">Car details</p>
                                            {userData?.car ? (
                                                <div>
                                                    {/* <img
                                                        src={userData?.car?.avatar ? `${config.BACKEND_PIC_URL}/${userData.car.avatar}` : "img/car_default_avatar.jpg"}
                                                        alt="Car Avatar"
                                                        className="car-avatar"
                                                    /> */}
                                                    <div className="d-flex">
                                                        <p className="car-info-text">{userData?.car.brand}</p>
                                                        <p className="car-info-text">{userData?.car.model}</p>
                                                        <p className="car-info-text">{userData?.car.year_made}</p>
                                                    </div>
                                                </div>

                                            ) : (
                                                <div>
                                                    <p style={{ color: '#02335B' }}>You have not set your car information yet</p>
                                                </div>
                                            )}

                                            <hr />
                                        </div>
                                        <div id="reviews" className="row mt-5">
                                            <p className="section-label">Reviews</p>
                                            <div className="reviews-button-group mt-2">
                                                <button
                                                    className={`${selectedTab === 'driver' ? 'link-text-button-active' : 'link-text-button-inactive'}`}
                                                    onClick={() => setSelectedTab('driver')}
                                                >
                                                    As Driver
                                                </button>
                                                <button
                                                    className={`${selectedTab === 'passenger' ? 'link-text-button-active' : 'link-text-button-inactive'}`}
                                                    onClick={() => setSelectedTab('passenger')}
                                                >
                                                    As Passenger
                                                </button>
                                            </div>
                                            <hr />

                                            <div className="mt-4">
                                                {reviewsToDisplay.length > 0 ? (
                                                    reviewsToDisplay.map((review, index) => (
                                                        <div key={index} className="review-item mb-4">
                                                            <p style={{ fontSize: "16px", color: "#02335B", marginBottom: '0' }}><strong>{review.sender}</strong></p>
                                                            <div className="d-flex">
                                                                <StarRatings
                                                                    starRatedColor="#FF9C41"
                                                                    rating={review.rating ? review.rating : 0}
                                                                    starDimension="15px"
                                                                    starSpacing="2px"
                                                                />
                                                                <p style={{ fontSize: '14px', marginLeft: '15px', marginBottom: '0', marginTop: '5px' }}>{review.created_at}</p>
                                                            </div>
                                                            <p style={{ fontSize: "14px", color: "#02335B", marginTop: '5px' }}>{review.comment}</p>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <p>No reviews yet</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}

export default Profile;