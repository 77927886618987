import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from "../util/config";
import axios from "axios";
import { getUserProfile } from '../util/http';

const AuthContext = createContext(null);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [userData, setUserData] = useState(null);
  
  const updateToken = (newToken) => {
    setToken(newToken);
    localStorage.setItem('token', newToken); // Store token in localStorage
    if (newToken) {
      getUserData(newToken); // Fetch user data after updating the token
    } else {
      setUserData(null); // Clear user data if the token is removed
    }
  };

  const onLogin = async (email, password) => {
    const url = config.BACKEND_API_URL + '/login';

    const response = await axios.post(url, {
      email: email,
      password: password,
    });

    const token = response.data.access_token;

    if (!token) {
      return;
    }

    updateToken(token);
    navigate('/');
  };

  const onRegister = async (name, email, password) => {
    const url = config.BACKEND_API_URL + '/register';

    const response = await axios.post(url, {
      name: name,
      email: email,
      password: password,
    });

    return response;
  };

  const onLogout = () => {
    localStorage.removeItem('token'); // Remove token from localStorage
    setToken(null);
    navigate('/login');
  };

  const getUserData = async (token) => {
    const response = await getUserProfile(token);
    setUserData(response);
  }

  useEffect(() => {
    if (token) {
      getUserData(token); // Fetch user data if token exists on initial render
    }
  }, [token]);

  const value = {
    token,
    userData,
    getUserData,
    onLogin,
    onRegister,
    onLogout,
    updateToken,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};