import { useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { verifyEmail } from '../util/http';
import { useNavigate } from "react-router-dom";
import ModalMessage from '../components/ModalMessage';


const ConfirmRegistration = () => {
    const { t } = useTranslation();
    const { register, formState: { errors }, handleSubmit, watch } = useForm();
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('success');
    const [modalMessage, setModalMessage] = useState('');
    const navigate = useNavigate();

    const onSubmit = async () => {
        try {
            setLoading(true);
            const formData = watch();
            const response = await verifyEmail({ validation_code: formData.confirmCode });
            if (response.status === 200) {
                setModalMessage(t('home:emailConfirmedMessage'));
                handleModal('success');
            }
        } catch (err) {
            handleModal('failure', err);
        } finally {
            setLoading(false)
        }
    }

    function handleModal(type, error = null) {
        if (type === 'success') {
            setModalType('success');
            setShowModal(true);
        } else if (type === 'failure') {
            setModalType('failure');
            if (error.response?.status && [400, 401, 422, 409].includes(error.response.status)) {
                setModalMessage(error.response.data.error);
            } else {
                setModalMessage(t('common:generalFormSubmitServerError'));
            }
            setShowModal(true);
        }
    }

    function handleCloseModal() {
        setShowModal(false);
        if (modalType === 'success') {
            navigate("/login");
        }
    }

    return (
        <div className='container-fluid'>
            <ModalMessage
                show={showModal}
                onClose={handleCloseModal}
                message={modalMessage}
                messageType={modalType}
            />

            <div className='row no-gutters'>
                <div className='col-md-8 image-column'>
                    <img src='img/login_background.png' />
                </div>
                <div className='col-md-4 form-container'>
                    <div className="custom-form">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <p>{t('common:codeValidationLabel')}</p>
                            <div className="form-outline mb-4">
                                <input type="confirmCode" id="confirmCode" className="form-control"
                                    {...register("confirmCode", {
                                        required: t('common:requiredField'),
                                        minLength: {
                                            value: 6,
                                            message: t('common:symbols6'),
                                        },
                                        maxLength: {
                                            value: 6,
                                            message: t('common:symbols6'),
                                        },
                                    })}
                                    aria-invalid={errors.confirmCode ? "true" : "false"}
                                    placeholder={t('common:codeLabel')}
                                />
                                <div>
                                    {errors.confirmCode && <span className="text-danger">{errors.confirmCode?.message}</span>}
                                </div>
                            </div>

                            <button className="btn custom-btn btn-lg btn-block" type="submit" disabled={loading}>
                                {loading ? <div className="loading-spinner" /> : 'Изпрати'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default ConfirmRegistration;