import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { forgottenPassword } from "../util/http";
import { useNavigate } from "react-router-dom";
import ModalMessage from "./ModalMessage";


const ForgottenPasswordModal = ({ show, onClose }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { register, formState: { errors }, handleSubmit, watch } = useForm();
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('success');
    const [modalMessage, setModalMessage] = useState('');

    const onSubmit = async () => {
        try {
            setLoading(true);
            const formData = watch();
            const response = await forgottenPassword({ email: formData.email });
            if (response.status === 200) {
                setModalMessage(t('common:passwordResetLinkSent'));
                handleModal('success');
            }
        } catch (err) {
            handleModal('failure', err);
        } finally {
            setLoading(false)
        }
    };

    function handleModal(type, error = null) {
        if (type === 'success') {
            setModalType('success');
            setShowModal(true);
        } else if (type === 'failure') {
            setModalType('failure');
            if (error.response?.status && [401, 422, 409].includes(error.response.status)) {
                setModalMessage(error.response.data.error);
            } else {
                setModalMessage(t('common:generalFormSubmitServerError'));
            }
            setShowModal(true);
        }
    }

    function handleCloseModal() {
        setShowModal(false);
        if (modalType === 'success') {
            navigate("/reset-password");
        }
        //onClose();
    }

    return (
        <>
            <ModalMessage
                show={showModal}
                onClose={handleCloseModal}
                message={modalMessage}
                messageType={modalType}
            />

            <Modal size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered show={show} onHide={onClose} className="trip-details-modal-dialog">
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        {/* <h5 className="fw-normal mb-3 pb-3" style={{letterSpacing: "1px"}}>Sign into your account</h5> */}

                        <div className="form-outline mb-4">
                            <input type="email" id="resetEmail" className="form-control"
                                {...register("email", {
                                    required: t('common:requiredField'),
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: t('common:invalidEmailMessage')
                                    }
                                })}
                                aria-invalid={errors.email ? "true" : "false"}
                                placeholder={t('common:emailLabel')}
                            />
                            <div>
                                {errors.email && <span className="text-danger">{errors.email?.message}</span>}
                            </div>
                        </div>
                        <div className='d-flex flex-row justify-content-between mt-2'>
                            <button className="btn custom-btn btn-md btn-block mx-auto" variant="secondary" type="submit" disabled={loading}>
                                {loading ? <div className="loading-spinner" /> : t('common:sendLabel')}
                            </button>
                            <button className="btn custom-btn btn-md btn-block mx-auto" variant="secondary" onClick={() => onClose()}>
                                {t('common:closeButtonLabel')}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ForgottenPasswordModal;