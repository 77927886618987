import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useAuth } from "../providers/AuthProvider";
import { sendMessage } from "../util/http";
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import LoadingOverlay from "../components/LoadingOverlay";


const ChatMessages = ({ userId, messagesLoading, roomId, messages, setMessages }) => {
    const [newMessage, setNewMessage] = useState('');
    const { t } = useTranslation();
    const { token } = useAuth();

    useEffect(() => {
        window.Pusher = Pusher
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: 'qwerty',
            cluster: 'qwerty',
            forceTLS: false,
            encrypted: false,
            disableStats: true,
            wsHost: window.location.hostname,
            wsPort: 6007,
            wssPort: 6007,
            enabledTransports: ['ws', 'wss'],
        });

        const channel = window.Echo.channel(`chat.room.${roomId}`);
        channel.listen('.message.created', (e) => {
            console.log('New message received:', e);
            setMessages((prevMessages) => [...prevMessages, e.message]);
        });

        // Clean up the subscription when the component is unmounted
        return () => {
            channel.stopListening('.message.created');
            window.Echo.leaveChannel(`chat.room.${roomId}`);
        };

    }, [roomId, setMessages]);

    const handleSendMessage = async () => {
        if (newMessage == '') {
            return;
        }

        try {
            await sendMessage({
                room_id: roomId,
                message: newMessage
            },
                token);
            setNewMessage('');
        } catch (error) {
            console.error('Error sending messages:', error);
        }
    };

    function isUserMessage(message) {
        if (message.user_id == userId) {
            return true;
        }

        return false;
    }

    return (
        <LoadingOverlay loading={messagesLoading}>
            <div className="card text-white mb-2" style={{ borderRadius: "1rem" }}>
                <div className="card-body">
                    {/* <p style={{ color: '#02335B', fontSize: '24px' }}>Sofia to Varna</p> */}
                    <hr className="hr-rooms" />
                    <div className="vh-100 d-flex flex-column">
                        <div className="overflow-auto p-3 h-75">
                            {messages.map((message, index) => (
                                <div className={`mb-3 d-flex justify-content-${isUserMessage(message) ? 'end' : 'start'}`} key={index}>
                                    <div>
                                        {!isUserMessage(message) && <p className="sender-name">{message.sender_name}</p>}
                                        <div className={`${isUserMessage(message) ? 'user-message' : 'other-message'}`}>
                                            <div style={{ padding: "4px" }}>{message.content}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>


                        <hr className="hr-rooms" />

                        {!messagesLoading && (
                            <div className="mt-3 mb-3">
                                <div className="input-group">
                                    <div className="form-outline">
                                        <input type="text" id="form1"
                                            placeholder={t('common:typeYourMessagePlaceholder')}
                                            value={newMessage}
                                            maxLength="255"
                                            onChange={(e) => setNewMessage(e.target.value)}
                                            className="form-control custom-message-input" />
                                    </div>
                                    <button type="button" onClick={handleSendMessage} className="send-message-button">
                                        <i className="bi bi-send-fill" style={{ color: 'white', fontSize: '18px', marginLeft: '10px', marginRight: '10px' }}></i>
                                        {t('common:sendLabel')}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    );
}

export default ChatMessages;