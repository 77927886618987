import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import { useTrips } from "../providers/TripsProvider";
import '../css/TripSearchFilters.css';


const TripsSearchFilters = () => {
    const { t } = useTranslation();
    const { handleSearch, filters, setFilters } = useTrips();

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'lightgrey',
            border: 'none',
            borderRadius: '10px',
            boxShadow: 'none',
            padding: '2px 8px',
            minHeight: 'auto',
            '&:hover': {
                border: 'none',
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'grey', // Adjust this based on your design
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'grey', // Adjust this based on your design
        }),
        input: (provided) => ({
            ...provided,
            color: 'grey', // Adjust this based on your design
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: 'grey', // Adjust this based on your design
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '0 0 30px 30px',
            overflow: 'hidden',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'grey' : 'lightgrey',
            color: 'grey', // Adjust this based on your design
            '&:hover': {
                backgroundColor: '#fff',
            },
        }),
    };

    const options = [
        { label: t('searchfilters:driverRating'), value: 'driverRating' },
        { label: t('searchfilters:pricePerPassenger'), value: 'pricePerPassenger' },
        { label: t('searchfilters:startTime'), value: 'startTime' },
    ];

    const handleFilter = (filterName, filterValue) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: filterValue
        }));
    };

    const applyFilters = () => {
        handleSearch();
    }

    return (
        <div className="card mt-5 shadow-sm bg-body" style={{ border: '0.5px solid #ccc', borderRadius: '20px' }}>
            <div className="card-body">
                <p style={{ fontSize: '24px', color: '#02335B' }}>Filters</p>
                <div className="form-group">
                    <div className="mb-3">
                        <Select
                            className="select-input-filters"
                            placeholder={t('searchfilters:orderBy')}
                            options={options}
                            onChange={(value) => {
                                handleFilter('orderBy', value.value);
                            }}
                            styles={customStyles}
                        />
                    </div>
                    <div className="row align-items-center">
                        <div className="form-check">
                            <label style={{ fontSize: '14px' }} className="form-check-label">
                                {t('tripcreate:petsAllowed')}
                            </label>
                            <input
                                type="checkbox"
                                name="pets_allowed"
                                className="custom-checkbox"
                                checked={filters.pets_allowed}
                                onChange={(e) => handleFilter('pets_allowed', e.target.checked)}
                            />
                        </div>
                    </div>

                    <div className='row align-items-center'>
                        <div className="form-check">
                            <label style={{ fontSize: '14px' }} className="form-check-label">
                                {t('tripcreate:airConditioning')}
                            </label>
                            <input
                                type="checkbox"
                                name="air_conditioning"
                                className="custom-checkbox"
                                checked={filters.air_conditioning}
                                onChange={(e) => handleFilter('air_conditioning', e.target.checked)}
                            />
                        </div>
                    </div>
                    <div className='row align-items-center'>
                        <div className="form-check">
                            <label style={{ fontSize: '14px' }} className="form-check-label">
                                {t('tripcreate:picksFromAddress')}
                            </label>
                            <input
                                type="checkbox"
                                name="picks_from_address"
                                className="custom-checkbox"
                                checked={filters.picks_from_address}
                                onChange={(e) => handleFilter('picks_from_address', e.target.checked)}
                            />
                        </div>
                    </div>
                    <div className='row align-items-center'>
                        <div className="form-check">
                            <label style={{ fontSize: '14px' }} className="form-check-label">
                                {t('tripcreate:smokingAllowed')}
                            </label>
                            <input
                                type="checkbox"
                                name="smoking_allowed"
                                className="custom-checkbox"
                                checked={filters.smoking_allowed}
                                onChange={(e) => handleFilter('smoking_allowed', e.target.checked)}
                            />
                        </div>
                    </div>
                </div>
                <div className="text-center mt-3">
                    <button type="submit" className="custom-button w-100" onClick={() => { applyFilters() }}>
                        Apply Filters
                    </button>
                </div>
            </div>
        </div>
    );
}

export default TripsSearchFilters;