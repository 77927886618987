import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { getCities } from '../util/cities';
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { getFormattedDate } from '../util/date';
import { storeTrip } from "../util/http";
import { useAuth } from "../providers/AuthProvider";
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import ModalMessage from "../components/ModalMessage";
import '../css/TripCreate.css';

const TripCreate = () => {
    const navigate = useNavigate();
    const { token, userData } = useAuth();
    const { t } = useTranslation();
    const [departureCity, setDepartureCity] = useState(null);
    const [destinationCity, setDestinationCity] = useState(null);
    const [loading, setLoading] = useState(false);
    const { handleSubmit, control, formState: { errors }, watch, setValue } = useForm();

    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('success');
    const [modalMessage, setModalMessage] = useState('');
    const cities = getCities(t);
    const today = new Date();

    const [inputs, setInputs] = useState({
        pets_allowed: false,
        air_conditioning: false,
        smoking_allowed: false,
        picks_from_address: false,
    });

    const onSubmit = (data) => {
        const isCityDifferenceValid = validateCityDifference(data.departure_city, data.destiantion_city);

        if (!isCityDifferenceValid) {
            return;
        }

        // If validation passes, perform desired action (e.g., handle search logic)
        if (!errors.departure_city &&
            !errors.destination_city &&
            !errors.trip_start_time &&
            !errors.departure_point &&
            !errors.price_per_passenger) {
            const parsedData = getParsedData(data);
            createTrip(parsedData);
        }
    };

    const getParsedData = (formData) => {
        return {
            trip_start_time: getFormattedDate(formData.trip_start_time),
            departure_city: formData.departure_city.value,
            destination_city: formData.destination_city.value,
            departure_point: formData.departure_point,
            seats_capacity: formData.seats_capacity,
            message_by_driver: formData.message_by_driver,
            price_per_passenger: formData.price_per_passenger,
            suitcases_capacity: formData.suitcases_capacity,
            pets_allowed: inputs.pets_allowed,
            air_conditioning: inputs.air_conditioning,
            smoking_allowed: inputs.smoking_allowed,
            picks_from_address: inputs.picks_from_address,
        }
    }

    const createTrip = async (data) => {
        try {
            setLoading(true);
            const response = await storeTrip(token, data);

            if (response.status === 201) {
                setModalMessage(t('tripcreate:tripCreatedModalMessage'));
                handleModal('success');
            }
        } catch (error) {
            handleModal('failure', error);
        } finally {
            setLoading(false);
        }
    }

    const validateCityDifference = (value, field) => {
        if (value && field === 'destination_city' && departureCity && value.value === departureCity.value) {
            return t('common:cityConflictValidationError');
        }
        if (value && field === 'departure_city' && destinationCity && value.value === destinationCity.value) {
            return t('common:cityConflictValidationError');
        }
        return true;
    };

    const customSelectStyles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black'
        }),
        singleValue: provided => ({
            ...provided,
            color: 'black'
        })
    }

    function handleModal(type, error = null) {
        if (type === 'success') {
            setModalType('success');
            setShowModal(true);
        } else if (type === 'failure') {
            setModalType('failure');
            if (error.response?.status && [400, 401, 422, 409].includes(error.response.status)) {
                setModalMessage(error.response.data.error);
            } else {
                setModalMessage(t('tripcreate:tripCreateFailureModalMessage'));
            }
            setShowModal(true);
        }
    }

    function handleCloseModal() {
        setShowModal(false);
        if (modalType === 'success') {
            navigate("/");
        }
    }

    return (
        <div>
            <ModalMessage
                show={showModal}
                onClose={handleCloseModal}
                message={modalMessage}
                messageType={modalType}
            />
            {/* If the user does not have a car hide the form and show a message that he must add a car to proceed woth trip creation */}
            {!userData?.car ? (
                <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                    <p stlye={{ color: '#02335B', fontSize: '20px' }}>In order to create a trip you need to have added a car to your profile</p>
                </div>
            ) : (<div className="container h-100" style={{ maxWidth: "none" }}>
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-xl-10 mt-5">
                        <div className="card" style={{ borderRadius: "1rem", boxShadow: 'none' }}>
                            <div className="card-body text-center">
                                <div className="row">
                                    <div className="col-md-6 mb-5">
                                        <p style={{ margin: '10px', fontSize: '30px', fontWeight: 'bold', color: '#02335B' }}>{t('tripcreate:tripCreateTitle')}</p>
                                        <p style={{ margin: '10px', fontSize: '15px', color: '#02335B' }}>Plan your next trip</p>
                                    </div>
                                    <div className="col-md-6">
                                        <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="form-outline mb-4">
                                                <p className="form-label-custom">{t('tripcreate:createFormLeaving')}</p>
                                                <Controller
                                                    name="departure_city"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <Select
                                                                styles={{
                                                                    ...customSelectStyles,
                                                                    control: (styles) => ({ ...styles, width: '100%' }),
                                                                }}
                                                                value={field.value}
                                                                onChange={(value) => {
                                                                    setDepartureCity(value);
                                                                    field.onChange(value);
                                                                }}
                                                                options={cities}
                                                                placeholder={t('common:selectPlaceholder')}
                                                            />
                                                            {errors.departure_city && <span className="text-danger">{errors.departure_city.message}</span>}
                                                        </>
                                                    )}
                                                    rules={{
                                                        required: t('common:requiredField'),
                                                        validate: value => validateCityDifference(value, 'departure_city')
                                                    }}
                                                />
                                            </div>

                                            <div className="form-outline mb-4">
                                                <p className="form-label-custom">{t('tripcreate:createFormGoing')}</p>
                                                <Controller
                                                    name="destination_city"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <Select
                                                                styles={{
                                                                    ...customSelectStyles,
                                                                    control: (styles) => ({ ...styles, width: '100%' }),
                                                                }}
                                                                value={field.value}
                                                                onChange={(value) => {
                                                                    setDestinationCity(value);
                                                                    field.onChange(value);
                                                                }}
                                                                options={cities}
                                                                placeholder={t('common:selectPlaceholder')}
                                                            />
                                                            {errors.destination_city && <span className="text-danger">{errors.destination_city.message}</span>}
                                                            {errors.cityDifference && <span className="text-danger">{errors.cityDifference.message}</span>}
                                                        </>
                                                    )}
                                                    rules={{
                                                        required: t('common:requiredField'),
                                                        validate: value => validateCityDifference(value, 'destination_city')
                                                    }}
                                                />
                                            </div>

                                            <div className="form-outline mb-4">
                                                <p className="form-label-custom">{t('tripcreate:pricePerPassenger')}</p>
                                                <Controller
                                                    name="price_per_passenger"
                                                    control={control}
                                                    defaultValue={''}
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="text"
                                                                name="price_per_passenger"
                                                                className="form-control"
                                                                {...field}
                                                            />
                                                            {errors.price_per_passenger && <span className="text-danger">{errors.price_per_passenger.message}</span>}
                                                        </>
                                                    )}
                                                    rules={{
                                                        required: t('common:requiredField'),
                                                        pattern: {
                                                            value: /^[0-9]*$/,
                                                            message: t('tripcreate:onlyNumericValues'),
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div className="form-outline mb-4">
                                                <p className="form-label-custom">{t('tripcreate:seatsCapacity')}</p>
                                                <Controller
                                                    name="seats_capacity"
                                                    control={control}
                                                    defaultValue={''}
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="text"
                                                                name="seats_capacity"
                                                                className="form-control"
                                                                {...field}
                                                            />
                                                            {errors.seats_capacity && <span className="text-danger">{errors.seats_capacity.message}</span>}
                                                        </>
                                                    )}
                                                    rules={{
                                                        required: t('common:requiredField'),
                                                        min: { value: 1, message: 'Min 1' },
                                                        max: { value: 7, message: 'Max 7' },
                                                        pattern: {
                                                            value: /^[0-9]*$/,
                                                            message: t('tripcreate:onlyNumericValues'),
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div className="form-outline mb-4">
                                                <p className="form-label-custom">{t('tripcreate:selectStartTime')}</p>
                                                <Controller
                                                    name="trip_start_time"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <DatePicker
                                                                className="form-control"
                                                                selected={field.value}
                                                                onChange={(value) => {
                                                                    field.onChange(value);
                                                                }}
                                                                showTimeSelect
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                minDate={today}
                                                            />
                                                            {errors.trip_start_time && <span className="text-danger">{errors.trip_start_time.message}</span>}
                                                        </>
                                                    )}
                                                    rules={{
                                                        required: t('common:requiredField'),
                                                    }}
                                                />
                                            </div>

                                            <div className="form-outline mb-4">
                                                <p className="form-label-custom">{t('tripcreate:departurePoint')}</p>
                                                <Controller
                                                    name="departure_point"
                                                    control={control}
                                                    defaultValue={''}
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="text"
                                                                name="departure_point"
                                                                className="form-control"
                                                                {...field}
                                                            />
                                                            {errors.departure_point && <span className="text-danger">{errors.departure_point.message}</span>}
                                                        </>
                                                    )}
                                                    rules={{
                                                        required: t('common:requiredField'),
                                                    }}
                                                />
                                            </div>
                                            <div className="form-outline mb-4">
                                                <p className="form-label-custom">{t('tripcreate:suitcasesCapacity')}</p>
                                                <Controller
                                                    name="suitcases_capacity"
                                                    control={control}
                                                    defaultValue={''}
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="text"
                                                                name="suitcases_capacity"
                                                                className="form-control"
                                                                {...field}
                                                            />
                                                            {errors.suitcases_capacity && <span className="text-danger">{errors.suitcases_capacity.message}</span>}
                                                        </>
                                                    )}
                                                    rules={{
                                                        required: t('common:requiredField'),
                                                        min: { value: 1, message: 'Min 1' },
                                                        max: { value: 7, message: 'Max 7' },
                                                        pattern: {
                                                            value: /^[0-9]*$/,
                                                            message: t('tripcreate:onlyNumericValues'),
                                                        }
                                                    }}
                                                />

                                            </div>

                                            <div className="form-outline mb-4">
                                                <p className="form-label-custom">Message By Driver</p>
                                                <Controller
                                                    name="message_by_driver"
                                                    control={control}
                                                    defaultValue={''}
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="textarea"
                                                                name="message_by_driver"
                                                                className="form-control"
                                                                {...field}
                                                            />
                                                            {errors.message_by_driver && <span className="text-danger">{errors.message_by_driver.message}</span>}
                                                        </>
                                                    )}
                                                    rules={{
                                                        maxLength: { value: 500, message: 'Max 500 symbols' },
                                                    }}
                                                />
                                            </div>

                                            <div className="checkboxes-container">
                                                <div className="custom-checkbox-container">
                                                    <label className="form-check-label custom-checkbox-label">
                                                        <input
                                                            type="checkbox"
                                                            name="pets_allowed"
                                                            className="custom-checkbox"
                                                            checked={inputs.pets_allowed}
                                                            onChange={(e) => setInputs({ ...inputs, pets_allowed: e.target.checked })}
                                                        />
                                                        {t('tripcreate:petsAllowed')}
                                                    </label>
                                                </div>

                                                <div className="custom-checkbox-container">
                                                    <label className="form-check-label">
                                                        <input
                                                            type="checkbox"
                                                            name="air_conditioning"
                                                            className="custom-checkbox"
                                                            checked={inputs.air_conditioning}
                                                            onChange={(e) => setInputs({ ...inputs, air_conditioning: e.target.checked })}
                                                        />
                                                        {t('tripcreate:airConditioning')}
                                                    </label>
                                                </div>

                                                <div className="custom-checkbox-container">
                                                    <label className="form-check-label">
                                                        <input
                                                            type="checkbox"
                                                            name="smoking_allowed"
                                                            className="custom-checkbox"
                                                            checked={inputs.smoking_allowed}
                                                            onChange={(e) => setInputs({ ...inputs, smoking_allowed: e.target.checked })}
                                                        />
                                                        {t('tripcreate:smokingAllowed')}
                                                    </label>
                                                </div>

                                                <div className="custom-checkbox-container">
                                                    <label className="form-check-label">
                                                        <input
                                                            type="checkbox"
                                                            name="picks_from_address"
                                                            className="custom-checkbox"
                                                            checked={inputs.picks_from_address}
                                                            onChange={(e) => setInputs({ ...inputs, picks_from_address: e.target.checked })}
                                                        />
                                                        {t('tripcreate:picksFromAddress')}
                                                    </label>
                                                </div>
                                            </div>
                                            <button className="btn custom-btn btn-lg px-5 mt-3" type="submit" disabled={loading}>
                                                {loading ? <div className="loading-spinner" /> : t('tripcreate:createButtonLabel')}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)}
        </div>
    );
}

export default TripCreate;