export const getCities = (t) => {
    return (
        [
            { label: t('cities:sofia'), value: 'Sofia' },
            { label: t('cities:varna'), value: 'Varna' },
            { label: t('cities:plovdiv'), value: 'Plovdiv' },
            { label: t('cities:burgas'), value: 'Burgas' },

            {label: t('cities:ruse'), value: 'Ruse'},
            {label: t('cities:staraZagora'), value: 'Stara Zagora'},
            {label: t('cities:pleven'), value: 'Pleven'},
            {label: t('cities:sliven'), value: 'Sliven'},
            {label: t('cities:dobrich'), value: 'Dobrich'},
            {label: t('cities:shumen'), value: 'Shumen'},
            {label: t('cities:haskovo'), value: 'Haskovo'},
            {label: t('cities:pazardzhik'), value: 'Pazardzhik'},
            {label: t('cities:yambol'), value: 'Yambol'},
            {label: t('cities:pernik'), value: 'Pernik'},
            {label: t('cities:blagoevgrad'), value: 'Blagoevgrad'},
            {label: t('cities:velikoTarnovo'), value: 'Veliko Tarnovo'},
            {label: t('cities:vratsa'), value: 'Vratsa'},
            {label: t('cities:kardzhali'), value: 'Kardzhali'},
            {label: t('cities:kyustendil'), value: 'Kyustendil'},
            {label: t('cities:gabrovo'), value: 'Gabrovo'},
            {label: t('cities:asenovgrad'), value: 'Asenovgrad'},
            {label: t('cities:vidin'), value: 'Vidin'},
            {label: t('cities:kazanlak'), value: 'Kazanlak'},
            {label: t('cities:montana'), value: 'Montana'},
            {label: t('cities:targovishte'), value: 'Targovishte'},
            {label: t('cities:dimitrovgrad'), value: 'Dimitrovgrad'},
            {label: t('cities:silistra'), value: 'Silistra'},
            {label: t('cities:lovech'), value: 'Lovech'},
            {label: t('cities:razgrad'), value: 'Razgrad'},
            {label: t('cities:petrich'), value: 'Petrich'},
            {label: t('cities:dupnitsa'), value: 'Dupnitsa'},
            {label: t('cities:gornaOryahovitsa'), value: 'Gorna Oryahovitsa'},
            {label: t('cities:smolyan'), value: 'Smolyan'},
            {label: t('cities:sandanski'), value: 'Sandanski'},
            {label: t('cities:samokov'), value: 'Samokov'},
            {label: t('cities:novaZagora'), value: 'Nova Zagora'},
            {label: t('cities:karlovo'), value: 'Karlovo'},
            {label: t('cities:velingrad'), value: 'Velingrad'},
            {label: t('cities:sevlievo'), value: 'Sevlievo'},
            {label: t('cities:lom'), value: 'Lom'},
            {label: t('cities:aytos'), value: 'Aytos'},
            {label: t('cities:svishtov'), value: 'Svishtov'},
            {label: t('cities:harmanli'), value: 'Harmanli'},
            {label: t('cities:troyan'), value: 'Troyan'},
            {label: t('cities:botevgrad'), value: 'Botevgrad'},
            {label: t('cities:gotseDelchev'), value: 'Gotse Delchev'},
            {label: t('cities:peshtera'), value: 'Peshtera'},
            {label: t('cities:karnobat'), value: 'Karnobat'},
            {label: t('cities:svilengrad'), value: 'Svilengrad'},
            {label: t('cities:momchilgrad'), value: 'Momchilgrad'},
            {label: t('cities:panagyurishte'), value: 'Panagyurishte'},
            {label: t('cities:berkovitsa'), value: 'Berkovitsa'},
            {label: t('cities:noviPazar'), value: 'Novi Pazar'},
            {label: t('cities:radomir'), value: 'Radomir'},
            {label: t('cities:ihtiman'), value: 'Ihtiman'},
            {label: t('cities:pomorie'), value: 'Pomorie'},
            {label: t('cities:noviIskar'), value: 'Novi Iskar'},
            {label: t('cities:nesebar'), value: 'Nesebar'},
            {label: t('cities:radnevo'), value: 'Radnevo'},
            {label: t('cities:balchik'), value: 'Balchik'},
            {label: t('cities:razlog'), value: 'Razlog'},
            {label: t('cities:byalaSlatina'), value: 'Byala Slatina'},
            {label: t('cities:kozloduy'), value: 'Kozloduy'},
            {label: t('cities:kavarna'), value: 'Kavarna'},
            {label: t('cities:stamboliyski'), value: 'Stamboliyski'},
            {label: t('cities:pavlikeni'), value: 'Pavlikeni'},
            {label: t('cities:isperih'), value: 'Isperih'},
            {label: t('cities:mezdra'), value: 'Mezdra'},
            {label: t('cities:kostinbrod'), value: 'Kostinbrod'},
            {label: t('cities:etropole'), value: 'Etropole'},
            {label: t('cities:bankya'), value: 'Bankya'},
            {label: t('cities:knezha'), value: 'Knezha'},
            {label: t('cities:elhovo'), value: 'Elhovo'},
            {label: t('cities:omurtag'), value: 'Omurtag'},
            {label: t('cities:tutrakan'), value: 'Tutrakan'},
            {label: t('cities:ardino'), value: 'Ardino'},
            {label: t('cities:lukovit'), value: 'Lukovit'},
            {label: t('cities:teteven'), value: 'Teteven'},
            {label: t('cities:kubrat'), value: 'Kubrat'},
            {label: t('cities:tryavna'), value: 'Tryavna'},
            {label: t('cities:bansko'), value: 'Bansko'},
            {label: t('cities:belene'), value: 'Belene'},
            {label: t('cities:svoge'), value: 'Svoge'},
            {label: t('cities:dryanovo'), value: 'Dryanovo'},
            {label: t('cities:simitli'), value: 'Simitli'},
            {label: t('cities:tsarevo'), value: 'Tsarevo'},
            {label: t('cities:dobrinishte'), value: 'Dobrinishte'},
            {label: t('cities:noviHan'), value: 'Novi Han'},
            {label: t('cities:apriltsi'), value: 'Apriltsi'},
            {label: t('cities:ahtopol'), value: 'Ahtopol'}
        ]
    );
}