import { deleteAccount } from "../../util/http";
import { useAuth } from "../../providers/AuthProvider";
import { useState } from "react";
import ModalMessage from "../ModalMessage";
import { useTranslation } from 'react-i18next';

const DeleteAccount = () => {
    const { t } = useTranslation();
    const { token, onLogout } = useAuth();
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('success');
    const [modalMessage, setModalMessage] = useState('');

    const handleDeleteAccount = async () => {
        setLoading(true);
        try {
            await deleteAccount(token);
            handleModal('success');
            setModalMessage('You have successfully deleted your account');
        } catch (err) {
            handleModal('failure', err);
        } finally {
            setLoading(false);
        }
    }

    function handleModal(type, error = null) {
        if (type === 'success') {
            setModalType('success');
            setShowModal(true);
        } else if (type === 'failure') {
            setModalType('failure');
            if (error.response?.status && [400, 401, 422, 409].includes(error.response.status)) {
                setModalMessage(error.response.data.error);
            } else {
                setModalMessage(t('common:generalFormSubmitServerError'));
            }
            setShowModal(true);
        }
    }

    function handleCloseModal() {
        setShowModal(false);
        if (modalType === 'success') {
            onLogout();
        }
    }

    return (
        <>
            <ModalMessage
                show={showModal}
                onClose={handleCloseModal}
                message={modalMessage}
                messageType={modalType}
            />

            <div className="col d-flex flex-column align-items-center">
                <i className="bi bi-exclamation-triangle-fill" style={{ color: 'orange', fontSize: '50px' }}></i>
                <p style={{ color: '#02335B', textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>Do you really want to delete your account?</p>
                <p style={{ color: '#02335B', textAlign: 'center', fontSize: '14px' }}>All of our data - trips, join requests, trip history, profile info will be deleted.</p>
                <button className="account-delete-button" disabled={loading} onClick={() => handleDeleteAccount()}>
                    {loading ? <div className="loading-spinner" /> : 'Yes, I want'}
                </button>
            </div>
        </>

    );
}

export default DeleteAccount;