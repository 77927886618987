import { useEffect, useRef } from 'react';
import TripCard from './TripCard';
import { useTrips } from '../providers/TripsProvider';
import '../css/TripsList.css';


const TripsList = () => {
    const { trips, getNextPage, loadingMore, loading } = useTrips();
    const loaderRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '20px',
            threshold: 0
        };
        
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                getNextPage();
            }
        }, options);

        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [loadingMore]);


    return (
        <div className='mt-5'>
            <p style={{color: "grey"}}>Showing <span style={{color: '#02335B'}}>“{trips.length} rides”</span> of this search results</p>
            {trips.map((trip, index) => (
                <TripCard trip={trip} key={index} />
            ))}
            {loading && <div className="spinner">Loading...</div>}
            <div ref={loaderRef} style={{ height: '20px' }} />
        </div>
    );
}

export default TripsList;