export default {
    sofia: 'Sofia',
    varna: 'Varna',
    plovdiv: 'Plovdiv',
    burgas: 'Burgas',
    ruse: 'Ruse',
    staraZagora: 'Stara Zagora',
    pleven: 'Pleven',
    sliven: 'Sliven',
    dobrich: 'Dobrich',
    shumen: 'Shumen',
    haskovo: 'Haskovo',
    pazardzhik: 'Pazardzhik',
    yambol: 'Yambol',
    pernik: 'Pernik',
    blagoevgrad: 'Blagoevgrad',
    velikoTarnovo: 'Veliko Tarnovo',
    vratsa: 'Vratsa',
    kardzhali: 'Kardzhali',
    kyustendil: 'Kyustendil',
    gabrovo: 'Gabrovo',
    asenovgrad: 'Asenovgrad',
    vidin: 'Vidin',
    kazanlak: 'Kazanlak',
    montana: 'Montana',
    targovishte: 'Targovishte',
    dimitrovgrad: 'Dimitrovgrad',
    silistra: 'Silistra',
    lovech: 'Lovech',
    razgrad: 'Razgrad',
    petrich: 'Petrich',
    dupnitsa: 'Dupnitsa',
    gornaOryahovitsa: 'Gorna Oryahovitsa',
    smolyan: 'Smolyan',
    sandanski: 'Sandanski',
    samokov: 'Samokov',
    novaZagora: 'Nova Zagora',
    karlovo: 'Karlovo',
    velingrad: 'Velingrad',
    sevlievo: 'Sevlievo',
    lom: 'Lom',
    aytos: 'Aytos',
    svishtov: 'Svishtov',
    harmanli: 'Harmanli',
    troyan: 'Troyan',
    botevgrad: 'Botevgrad',
    gotseDelchev: 'Gotse Delchev',
    peshtera: 'Peshtera',
    karnobat: 'Karnobat',
    svilengrad: 'Svilengrad',
    momchilgrad: 'Momchilgrad',
    panagyurishte: 'Panagyurishte',
    berkovitsa: 'Berkovitsa',
    noviPazar: 'Novi Pazar',
    radomir: 'Radomir',
    ihtiman: 'Ihtiman',
    pomorie: 'Pomorie',
    noviIskar: 'Novi Iskar',
    nesebar: 'Nesebar',
    radnevo: 'Radnevo',
    balchik: 'Balchik',
    razlog: 'Razlog',
    byalaSlatina: 'Byala Slatina',
    kozloduy: 'Kozloduy',
    kavarna: 'Kavarna',
    stamboliyski: 'Stamboliyski',
    pavlikeni: 'Pavlikeni',
    isperih: 'Isperih',
    mezdra: 'Mezdra',
    kostinbrod: 'Kostinbrod',
    etropole: 'Etropole',
    bankya: 'Bankya',
    knezha: 'Knezha',
    elhovo: 'Elhovo',
    omurtag: 'Omurtag',
    tutrakan: 'Tutrakan',
    ardino: 'Ardino',
    lukovit: 'Lukovit',
    teteven: 'Teteven',
    kubrat: 'Kubrat',
    tryavna: 'Tryavna',
    bansko: 'Bansko',
    belene: 'Belene',
    svoge: 'Svoge',
    dryanovo: 'Dryanovo',
    simitli: 'Simitli',
    tsarevo: 'Tsarevo',
    dobrinishte: 'Dobrinishte',
    noviHan: 'Novi Han',
    apriltsi: 'Apriltsi',
    ahtopol: 'Ahtopol',
}