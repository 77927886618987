import { useEffect, useState } from "react";
import { useAuth } from "../providers/AuthProvider";
import { getFinishedUserTripsAsDriver } from "../util/http";
import { useTranslation } from 'react-i18next';
import TripCardDriver from "../components/TripCardDriver";
import LoadingOverlay from "../components/LoadingOverlay";

const TripsDriverFinished = () => {
    const { t } = useTranslation();
    const { token } = useAuth();
    const [trips, setTrips] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getTripsDriver()
    }, []);

    async function getTripsDriver(page = 1) {
        setLoading(true);

        try {
            setLoading(true);
            let pageToSearch = page !== 1 ? page : 1
            const response = await getFinishedUserTripsAsDriver(token, pageToSearch);
            setLastPage(response.last_page)
            setCurrentPage(response.current_page);
            setTrips(response.data);
        } catch (error) {
            console.log('Could not fetch finished trips as driver!');
        } finally {
            setLoading(false);
        }
    }

    const showNextPage = () => {
        return currentPage !== lastPage;
    }

    const showPrevPage = () => {
        return currentPage !== 1;
    }

    const handleNextPageClick = () => {
        getTripsDriver(currentPage + 1);
    }

    const handlePrevPageClick = () => {
        getTripsDriver(currentPage - 1);
    }

    return (
        <LoadingOverlay loading={loading}>
            <div className="container mt-3">
                {trips.length > 0 ? (
                    <div>
                        <div className="d-flex justify-content-center align-items-center">
                            <p style={{ color: "grey" }}>Showing
                                <span style={{ color: '#02335B' }}> “{trips.length} rides”</span>
                            </p>
                        </div>
                        {trips.map((trip, index) => (
                            <TripCardDriver showTripCancelButton={false} trip={trip} key={index} />
                        ))}
                        <div className="pagination-container">
                            {showPrevPage() && <button type="button" disabled={loading} className="pagination-button" onClick={handlePrevPageClick}>
                                {loading ? <div className="loading-spinner" /> : t('common:prev')}
                            </button>}
                            {showNextPage() && <button type="button" disabled={loading} className="pagination-button" onClick={handleNextPageClick}>
                                {loading ? <div className="loading-spinner" /> : t('common:next')}
                            </button>}
                        </div>
                    </div>
                ) : (
                    <div className="d-flex justify-content-center pt-1 mb-4">
                        {!loading && <p>{t('common:noResultsFound')}</p>}
                    </div>
                )}
            </div>
        </LoadingOverlay>
    );
}

export default TripsDriverFinished;