export default {
    slogan: "Вашата история започва с нас.",
    subslogan: "Добре дошли в TravelWithMe, водещата платформа за споделено пътуване, свързваща пътници между градовете. Ние сме тук, за да направим революция в пътуванията на дълги разстояния, като ги направим достъпни, удобни и социално възнаграждаващи. Присъединете се към TravelWithMe и се впуснете в безпроблемно пътуване до желаната дестинация, докато се наслаждавате на компанията на спътници.",
    socialConnections: "Ние ви предлагаме всичко от което се нуждаете за вашето споделено пътуване на едно място. Вече няма нужда да се регистрирате в множество платформи - споделяйте вашите истории, запознайте се с нови хора и създавайте запомнящи се преживявания, всичко това на едно място.",
    costEfficiency: "Кажете сбогом на скъпите билети за влак или автобус. TravelWithMe ви предлага достъпна алтернатива за междуградските пътувания. Чрез споделеното пътуване вие си разделяте разходите и правите вашите пътувания по-оптимизирани финансово без да правите компромис с комфорта.",
    security: "Заложете на сигурността по време на вашето пътуване с TravelWithMe - вашият доверен партньор в споделеното пътуване. Вашата безопасност е от първостепенно значение за нас и вие можете да разчитате на системата ни за оценка и обратна връзка за пътници и шофьори, предоставяйки ви увереност, докато споделяте пътувания.",
    socialConnectionsLabel: "Социални връзки",
    costEfficiencyLabel: "Ефективност на разходите",
    securityLabel: "Сигурност",
    tryOurApp: "Изпробвайте нашето приложение",
    tryOurAppText1: "Присъединете се към TravelWithMe днес и влезте в един свят на достъпни, гъвкави и социални пътувания. Кажете сбогом на ограниченията на традиционния транспорт и се впуснете в един нов начин на пътуване между градовете, град след град.",
    tryOurAppText2: "Започнете вашето междуградско споделено пътуване с TravelWithMe сега и превърнете всяко ваше пътуване в невероятно преживяване от вашата история.",
    footerTitle: "Открийте ни",
    footerText: "Ние сме тук за вас",
    testimonialsSection: "Какво казват нашите потребители",
    weOfferLabel: 'Какво Ви Предлагаме',
    emailConfirmedMessage: "Вашият имейл беше потвърден успешно",
}