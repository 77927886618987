export default {
    markAsRead: "Mark as read",
    joinRequestApproved: 'Your trip join request has been approved by the driver. Have a wonderful trip.',
    joinRequestCancelled: 'The user has cancelled his trip with you.',
    joinRequestCreated: 'You have new trip join request.',
    joinRequestRejected: 'Your trip join request has been rejected by the driver.',
    rateTheDriver: 'Please rate the driver. This will benefit other passengers.',
    rateThePassenger: 'Please rate the passenger. This will benefit other drivers.',
    complaintLevelReached: 'You have received several complaints and will be reviewed for a ban from posting trips',
    rateUser: "Rate user",
    rateLabel: "Rate",
    joinRequestLabel: "Show request",
}