export default {
    requiredField: "Field is required",
    loginLabel: "Login",
    registerLabel: "Register",
    password: "Password",
    username: "Username",
    searchButtonLabel: "Search",
    searchFormLeaving: "Leaving from...",
    searchFormGoing: "Going to...",
    searchFormInBetween: "In between",
    selectDate: "Select date",
    searchBetweenDateValidationError: "The second date must be after the first one",
    cityConflictValidationError: "Destination city cannot be the same as the departure city",
    searchDateAfterNowValidationError: "Date should be in the future",
    searchScreenTitle: "Connect. Save. Travel Smarter",
    searchScreenSubtitle: "Discover a better way to travel between cities. Share rides, save money, and meet new people with our trusted carpooling platform.",
    closeButtonLabel: "Close",
    noResultsFound: "No results found",
    next: "Next",
    prev: "Prev",
    fieldMinSymbols8: "Min 8 characters",
    generalFormSubmitServerError: "There was a problem with your request. Plese try again or contact our support team.",
    changeLabel: "Change",
    newPassword: "New password",
    confirmNewPassword: "Confirm new password",
    passwordsDoNotMatch: "Passwords do not match",
    joinLabel: "Join",
    details: "Details",
    sendLabel: "Send",
    trip: "Trip",
    cancelLabel: "Cancel",
    status: "Status",
    passengers: "Passengers",
    deleteLabel: "Delete",
    invalidEmailMessage: "Invalid email",
    forgottenPassword: "Forgot password",
    ratingsLabel: "Ratings",
    cancel: "Cancel",
    from: "From",
    to: "to",
    typeYourMessagePlaceholder: "Type your message...",
    openChatRoom: "Join room",
    cancelJoinRequest: "Cancel request",
    passwordResetLinkSent: "We have emailed your password reset link",
    symbols6: "The field must contain 6 characters",
    passwordChangedSuccessfully: "Password changed successfully",
    emailLabel: "Email",
    codeValidationLabel: "Please enter the code which was sent to your email.",
    codeLabel: "Code",
    privacyPolicyLabel: "Privacy policy",
    contactUs: "Contact us",
    contactUsAssistText: "Our team is here to assist you with any questions or technical issues. You can contact us at any time.",
    selectPlaceholder: "Select...",
    showConfirmMailScreen: "Enter confirmation code",
    continueWithLabel: 'Continue with',
    planned: 'Planned',
    finished: 'Finished',
    reportUser: 'Report user',
}