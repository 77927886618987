export default {
    requiredField: "Полето е задължително",
    loginLabel: "Влез",
    registerLabel: "Регистрирай се",
    password: "Парола",
    username: "Име",
    searchButtonLabel: "Търси",
    searchFormLeaving: "Тръгва от...",
    searchFormGoing: "Пристига в...",
    searchFormInBetween: "В интервала между",
    selectDate: "Изберете дата",
    searchBetweenDateValidationError: "Втората дата трябва да бъде след първата",
    cityConflictValidationError: "Началната точка и крайната не може да бъдат с еднаква стойност",
    searchDateAfterNowValidationError: "Датата трябва да бъде в бъдещето",
    searchScreenTitle: "Запознай се. Спести. Пътувай Разумно",
    searchScreenSubtitle: "Открийте по-добър начин за пътуване между градовете. Споделяйте пътувания, спестявайте пари и срещайте нови хора с нашата надеждна платформа за споделено пътуване.",
    closeButtonLabel: "Затвори",
    noResultsFound: "Няма намерени резултати",
    next: "Следващ",
    prev: "Предишен",
    fieldMinSymbols8: "Mин 8 символа",
    generalFormSubmitServerError: "Имаше проблем при изпълнението на вашата заявка. Моля опитайте отново или се свържете с нашия екип за съдействие.",
    changeLabel: "Промени",
    newPassword: "Нова парола",
    confirmNewPassword: "Повтори новата парола",
    passwordsDoNotMatch: "Паролите не съвпадат",
    joinLabel: "Присъедини се",
    details: "Детайли",
    sendLabel: "Изпрати",
    trip: "Пътуване",
    cancelLabel: "Oткажи",
    status: "Статус",
    passengers: "Пътници",
    deleteLabel: "Изтрий",
    invalidEmailMessage: "Невалиден мейл",
    forgottenPassword: "Забравена парола",
    ratingsLabel: "Рейтинзи",
    cancel: "Откажи",
    from: "От",
    to: "до",
    typeYourMessagePlaceholder: "Въведете съобщение...",
    openChatRoom: "Влез в стаята",
    cancelJoinRequest: "Oткажи молбата",
    passwordResetLinkSent: "На мейла ви е изпратен линк за ресетване на паролата",
    symbols6: "Полето трябва да съдържа 6 символа",
    passwordChangedSuccessfully: "Вашата парола беше променена успешно",
    emailLabel: "Имейл",
    codeValidationLabel: "Моля въведете кода, който беше изпратен на вашият имейл",
    codeLabel: "Код",
    privacyPolicyLabel: "Политика за поверителност",
    contactUs: "Свържете се с нас",
    contactUsAssistText: "Имате въпроси или нужда от поддръжка? Нашият екип е тук, за да ви помогне. Свържете се с нас по всяко време.",
    selectPlaceholder: "Избери...",
    showConfirmMailScreen: "Въведи код за потвърждение",
    continueWithLabel: 'Продължи с',
    planned: 'Предстоящи',
    finished: 'Приключили',
    reportUser: 'Докладвай нередност',
}