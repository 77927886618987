export default {
    changePasswordLabel: "Промени парола",
    changeUsernameLabel: "Промени име",
    deleteAccountLabel: "Изтрий профила",
    driverRating: "Рейтинг като шофьор",
    passengerRating: "Рейтинг като пасажер",
    username: "Име",
    totalMoneyEarned: "Спечелени пари общо",
    userCreateFailureModalMessage: "Възникна проблем при създаването на профила. Моля опитайте по-късно или се свържете с нас за съдействие.",
    invalidFileFormatMessage: "Невалиден формат. Разрешени формати са jpg, jpeg, png",
    sizeExceedsLimitMessage: "Файлът надвишава лимита от 1МБ.",
    information: "Информация",
    comment: 'Коментар',
    rateSuccessful: 'Благодарим за вашата оценка.',
    driver: 'Шофьор',
    passenger: 'Пътник',
    comments: 'Коментари',
    successfulUserRegistration: "Успешна регистрация",
    successfulTripConfigurationDeletion: "Конфигурацията беше изтрита",
    reportUserText: "Моля опишете проблема",
    sendComplaintButtonText: "Изпрати оплакване",
    complaintPostedSuccessfullyMessage: "Вашето оплакване беше успешно изпратено",
    daysUsingTheApp: "Дни на използване на платформата",
}