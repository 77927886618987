import { useEffect, useState, useRef } from "react";
import { useAuth } from "../providers/AuthProvider";
import { getUserTripsAsPassenger } from "../util/http";
import { useTranslation } from 'react-i18next';
import TripCardPassenger from "../components/TripCardPassenger";
import LoadingOverlay from "../components/LoadingOverlay";

const TripsPassengerPlanned = () => {
    const { t } = useTranslation();
    const { token } = useAuth();
    const [trips, setTrips] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const loaderRef = useRef(null);

    const getNextPage = () => {
        if (currentPage < lastPage && !loadingMore) {
            getTripsPassenger(currentPage + 1);
        }
    };

    useEffect(() => {
        getTripsPassenger()
    }, []);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '20px',
            threshold: 0
        };

        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                getNextPage();
            }
        }, options);

        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [loadingMore]);

    async function getTripsPassenger(page = 1) {
        setLoading(true);
        setLoadingMore(true);

        try {
            let pageToSearch = page !== 1 ? page : 1
            const response = await getUserTripsAsPassenger(token, pageToSearch);
            setLastPage(response.last_page)
            setCurrentPage(response.current_page);
            setTrips(prevTrips => page === 1 ? response.data : [...prevTrips, ...response.data]);
        } catch (error) {
            console.log('Could not fetch trips as passenger!');
        } finally {
            setLoading(false);
            setLoadingMore(false);
        }
    }

    return (
        <LoadingOverlay loading={loading}>
            <div className="container">
                {trips.length > 0 ? (
                    <div>
                        {trips.map((trip, index) => (
                            <TripCardPassenger trip={trip} key={index} />
                        ))}
                        {loading && <div className="spinner">Loading...</div>}
                        <div ref={loaderRef} style={{ height: '20px' }} />
                    </div>
                ) : (
                    <div className="d-flex justify-content-center pt-1 mb-4">
                        {!loading && <p>{t('common:noResultsFound')}</p>}
                    </div>
                )}
            </div>
        </LoadingOverlay>
    );
}

export default TripsPassengerPlanned;