import { useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { resetPassword } from '../util/http';
import { useNavigate } from "react-router-dom";
import ModalMessage from '../components/ModalMessage';


const ResetPassword = () => {
    const { t } = useTranslation();
    const { register, formState: { errors }, handleSubmit, watch } = useForm();
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('success');
    const [modalMessage, setModalMessage] = useState('');
    const navigate = useNavigate();

    const onSubmit = async () => {
        try {
            setLoading(true);
            const formData = watch();
            const response = await resetPassword({
                token: formData.confirmCode,
                password: formData.password,
                password_confirmation: formData.password_confirmation
            });
            if (response.status === 200) {
                setModalMessage(t('common:passwordChangedSuccessfully'));
                handleModal('success');
            }
        } catch (err) {
            handleModal('failure', err);
        } finally {
            setLoading(false)
        }
    }


    function handleModal(type, error = null) {
        if (type === 'success') {
            setModalType('success');
            setShowModal(true);
        } else if (type === 'failure') {
            setModalType('failure');
            if (error.response?.status && [400, 401, 422, 409].includes(error.response.status)) {
                setModalMessage(error.response.data.error);
            } else {
                setModalMessage(t('common:generalFormSubmitServerError'));
            }
            setShowModal(true);
        }
    }

    function handleCloseModal() {
        setShowModal(false);
        if (modalType === 'success') {
            navigate("/login");
        }
    }

    return (
        <>
            <ModalMessage
                show={showModal}
                onClose={handleCloseModal}
                message={modalMessage}
                messageType={modalType}
            />

            <section className="vh-100" style={{ backgroundColor: "#9A616D" }}>
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col col-xl-10">
                            <div className="card" style={{ borderRadius: "1rem" }}>
                                <div className="row g-0">
                                    <div className="col-md-6 col-lg-5 d-none d-md-block">
                                        <img src="img/sign_background_3.jpg"
                                            alt="login form" className="img-fluid" style={{ borderRadius: "1rem 0 0 1rem" }} />
                                    </div>
                                    <div className="col-md-6 col-lg-7 d-flex align-items-center">
                                        <div className="card-body p-4 p-lg-5 text-black">
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <p>{t('common:codeValidationLabel')}</p>
                                                <div className="form-outline mb-4">
                                                    <label className="form-label" htmlFor="confirmCode">{t('common:codeLabel')}</label>
                                                    <input type="confirmCode" id="confirmCode" className="form-control"
                                                        {...register("confirmCode", {
                                                            required: t('common:requiredField'),
                                                            minLength: {
                                                                value: 6,
                                                                message: t('common:symbols6'),
                                                            },
                                                            maxLength: {
                                                                value: 6,
                                                                message: t('common:symbols6'),
                                                            },
                                                        })}
                                                        aria-invalid={errors.confirmCode ? "true" : "false"}
                                                    />
                                                    <div>
                                                        {errors.confirmCode && <span className="text-danger">{errors.confirmCode?.message}</span>}
                                                    </div>
                                                </div>

                                                <div className="form-outline mb-4">
                                                    <label className="form-label" htmlFor="resetPassword">{t('common:password')}</label>
                                                    <input type="password" id="resetPassword" className="form-control"
                                                        {...register("password", {
                                                            required: t('common:requiredField'),
                                                            minLength: {
                                                                value: 8,
                                                                message: t('tripcreate:fieldMinSymbols8'),
                                                            }
                                                        })}
                                                        aria-invalid={errors.password ? "true" : "false"}
                                                    />
                                                    <div>
                                                        {errors.password && <span className="text-danger">{errors.password?.message}</span>}
                                                    </div>
                                                </div>

                                                <div className="form-outline mb-4">
                                                    <label className="form-label" htmlFor="confirmResetPassword">{t('common:confirmNewPassword')}</label>
                                                    <input type="password" id="confirmResetPassword" className="form-control"
                                                        {...register("password_confirmation", {
                                                            required: t('common:requiredField'),
                                                            minLength: {
                                                                value: 8,
                                                                message: t('tripcreate:fieldMinSymbols8'),
                                                            }
                                                        })}
                                                        aria-invalid={errors.password ? "true" : "false"}
                                                    />
                                                    <div>
                                                        {errors.password && <span className="text-danger">{errors.password?.message}</span>}
                                                    </div>
                                                </div>

                                                <button className="btn custom-btn btn-lg btn-block" type="submit" disabled={loading}>
                                                    {loading ? <div className="loading-spinner" /> : 'Изпрати'}
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}


export default ResetPassword;