import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LanguageSelector from "./LanguageSelector";
import { useAuth } from "../providers/AuthProvider";

const Navigation = () => {
    const { t } = useTranslation();
    const { token, onLogout } = useAuth();
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const handleDropdownToggle = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleDropdownClose = () => {
        setDropdownOpen(false);
    };

    return (
        <Navbar bg="transparent" expand="lg" className='custom-navbar'>
            <Navbar.Brand href="/">
                <img
                    alt="logo"
                    src="/img/logo_img_text.png"
                    width="210"
                    height="30"
                    className="main-logo"
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar-nav" ><img slt="menu-icon" src="/img/menu_drawer_icon.png" width="50" height="50" border="none" /> </Navbar.Toggle>
            <Navbar.Collapse id="navbar-nav" className="justify-content-end">
                <Nav className="ml-auto">
                    {token ? (
                        <>
                            <Nav.Link href="/create-trip" style={{ marginLeft: '20px', marginRight: '20px' }}>{t('menu:profileTripCreate')}</Nav.Link>
                            <Nav.Link href="/chats" style={{ marginLeft: '20px', marginRight: '20px' }}>{t('menu:chats')}</Nav.Link>
                            <NavDropdown
                                title={t('menu:profile')}
                                id="profile-dropdown"
                                show={isDropdownOpen}
                                onToggle={handleDropdownToggle}
                                onMouseEnter={handleDropdownToggle}
                                onMouseLeave={handleDropdownClose}
                                style={{ marginLeft: '20px', marginRight: '40px' }}
                            >
                                <NavDropdown.Item href="/profile-info">{t('menu:profileInfo')}</NavDropdown.Item>
                                <NavDropdown.Item href="/user-trips-driver">{t('menu:profileTripsDriver')}</NavDropdown.Item>
                                <NavDropdown.Item href="/user-trips-passenger">{t('menu:profileTripsPassenger')}</NavDropdown.Item>
                                <NavDropdown.Item href="/sent-join-requests">{t('menu:profileSentJoinRequests')}</NavDropdown.Item>
                                <NavDropdown.Item href="/received-join-requests">{t('menu:profileReceivedJoinRequests')}</NavDropdown.Item>
                                <NavDropdown.Item href="/notifications">{t('menu:profileNotifications')}</NavDropdown.Item>
                                <NavDropdown.Item onClick={onLogout}>{t('menu:logout')}</NavDropdown.Item>
                            </NavDropdown>
                        </>
                    ) : (
                        <>
                            <Nav.Link href="/login" style={{ marginLeft: '20px', marginRight: '20px' }}>{t('menu:login')}</Nav.Link>
                            <Nav.Link href="/register" style={{ marginLeft: '20px', marginRight: '20px' }}>{t('menu:register')}</Nav.Link>
                        </>
                    )}
                    <LanguageSelector />
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Navigation;
