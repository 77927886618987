import React from 'react';
import '../css/LoadingOverlay.css'


const LoadingOverlay = ({ loading, children }) => {
    return (
        <div>
          {loading && (
            <div className="loading-overlay">
              <div className="loading-big-spinner" />
            </div>
          )}
          {children}
        </div>
      );

}

export default LoadingOverlay;
