import TripsSearch from "../components/TripsSearch";
import TripsList from "../components/TripsList";
import TripsSearchFilters from "../components/TripsSearchFilters";
import '../css/Trips.css';
import { useTrips } from "../providers/TripsProvider";

const Trips = () => {
    const { trips } = useTrips();

    return (
        <div>
            <div className="row">
                <div className="col-md-12 d-flex flex-column bg-image d-flex justify-content-center align-items-center">
                    <div className="align-items-center">
                        <div style={{ marginTop: "60px" }}>
                            <p className="text-center" style={{fontSize: '24px', color: '#02335B'}}>Search Result</p>
                            <TripsSearch />
                        </div>
                    </div>
                </div>
            </div>
            {trips.length === 0 ? (
                <div className="mt-5" style={{ textAlign: 'center' }}>
                    <img src="img/no_trips_found.png" alt="No trips available" style={{ width: '10%', maxWidth: '60%', height: 'auto' }} />
                    <p className="mt-5">No trips have been found for your route and date</p>
                </div>
            ) : (
                <div id="trips-and-filters" className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <TripsSearchFilters />
                        </div>
                        <div className="col-md-9">
                            <TripsList />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Trips;