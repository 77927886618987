import { useForm } from "react-hook-form";
import { createCar, deleteCar } from "../../util/http";
import { useAuth } from "../../providers/AuthProvider";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import ModalMessage from "../ModalMessage";
import config from "../../util/config";

const CarSettings = () => {
    const { t } = useTranslation();
    const { token, userData, getUserData } = useAuth();
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('success');
    const [modalMessage, setModalMessage] = useState('');

    const {
        register: register,
        handleSubmit: handle,
        formState: { errors },
    } = useForm();

    const currentYear = new Date().getFullYear();

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            await createCar(data, token);
            handleModal('success');
            setModalMessage('You have successfully added your car');
        } catch (err) {
            handleModal('failure', err);
        } finally {
            setLoading(false);
        }
    };

    const deleteUserCar = async () => {
        setLoading(true);
        try {
            await deleteCar(userData.car.id, token);
            handleModal('success');
            setModalMessage('You have successfully deleted your car');
        } catch (err) {
            handleModal('failure', err);
        } finally {
            setLoading(false);
        }
    }

    function handleModal(type, error = null) {
        if (type === 'success') {
            setModalType('success');
            setShowModal(true);
        } else if (type === 'failure') {
            setModalType('failure');
            if (error.response?.status && [400, 401, 422, 409].includes(error.response.status)) {
                setModalMessage(error.response.data.error);
            } else {
                setModalMessage(t('common:generalFormSubmitServerError'));
            }
            setShowModal(true);
        }
    }

    function handleCloseModal() {
        setShowModal(false);
        if (modalType === 'success') {
            getUserData();
            window.location.reload();
        }
    }

    return (
        <>
            <ModalMessage
                show={showModal}
                onClose={handleCloseModal}
                message={modalMessage}
                messageType={modalType}
            />
            {/* if the user has a car show the car detail with button delete, else show the car create form */}
            {userData?.car ? (
                <div className='col d-flex flex-column justify-content-center' style={{ height: '30vh' }}>
                    {/* <img
                        src={userData?.car?.avatar ? `${config.BACKEND_PIC_URL}/${userData.car.avatar}` : "img/car_default_avatar.jpg"}
                        alt="Car Avatar"
                        //className="car-avatar"
                    /> */}
                    <div className="row w-100">
                        <div className='col-md-4'>
                            <p className="car-settings-info-label">Brand</p>
                            <p className="car-settings-info-text">{userData?.car?.brand}</p>
                        </div>
                        <div className='col-md-4'>
                            <p className="car-settings-info-label">Model</p>
                            <p className="car-settings-info-text">{userData?.car?.model}</p>
                        </div>
                        <div className='col-md-4'>
                            <p className="car-settings-info-label">Year made</p>
                            <p className="car-settings-info-text">{userData?.car?.year_made}</p>
                        </div>
                    </div>
                    <button className="custom-button mt-5" onClick={() => deleteUserCar()}>Delete car</button>
                </div>
            ) : (<form onSubmit={handle(onSubmit)}>
                <div className="form-group mb-3">
                    <p style={{ color: '#02335B', fontSize: '18px', fontWeight: 'bold', marginBottom: '20px' }}>Car settings</p >
                    <p className="custom-input-label">Write car brand</p>
                    <input id="brand" className="form-control custom-profile-settings-input" type="text"
                        {...register("brand", {
                            required: t('common:requiredField'),
                            maxLength: {
                                value: 20,
                                message: t('tripcreate:fieldMaxSymbols20'),
                            }
                        })}
                        aria-invalid={errors.brand ? "true" : "false"}
                    />
                    {errors.brand && <p className="text-danger">{errors.brand?.message}</p>}
                </div >
                <div className="form-group mb-3">
                    <p className="custom-input-label">Write car model</p>
                    <input id="model" className="form-control custom-profile-settings-input" type="text"
                        {...register("model", {
                            required: t('common:requiredField'),
                            maxLength: {
                                value: 20,
                                message: t('tripcreate:fieldMaxSymbols20'),
                            }
                        })}
                        aria-invalid={errors.model ? "true" : "false"}
                    />
                    {errors.model && <p className="text-danger">{errors.model?.message}</p>}
                </div>
                <div className="form-group mb-3">
                    <p className="custom-input-label">Write year made</p>
                    <input id="year_made" className="form-control custom-profile-settings-input" type="text"
                        {...register("year_made", {
                            required: t('common:requiredField'),
                            maxLength: {
                                value: 4,
                                message: t('tripcreate:fieldMaxSymbols4'),
                            },
                            pattern: {
                                value: /^\d{4}$/,
                                message: t('tripcreate:invalidYearFormat'),
                            },
                            validate: {
                                isValidYear: value => value >= 1960 && value <= currentYear || t('tripcreate:yearRange1940ToCurrent')
                            }
                        })}
                        aria-invalid={errors.year_made ? "true" : "false"}
                    />
                    {errors.year_made && <p className="text-danger">{errors.year_made?.message}</p>}
                </div>
                <div className="text-center mt-3">
                    <button type="submit" className="custom-profile-settings-submit-button" disabled={loading}>
                        {loading ? <div className="loading-spinner" /> : 'Create car'}
                    </button>
                </div>
            </form >)}
        </>
    );
}

export default CarSettings;