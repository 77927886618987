import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from "@fortawesome/free-solid-svg-icons";
import UserDetails from "./UserDetails";
import { useState } from "react";

const ReceivedJoinReqCard = ({ loading, showTrip, approveRequest, rejectRequest, join, index }) => {
    const { t } = useTranslation();
    const [selectedUser, setSelectedUser] = useState(null);

    function showRejectApproveButton(status) {
        if (status == 'requested') {
            return true;
        }

        return false;
    }

    const handleUserClick = (userId) => {
        setSelectedUser(userId);
    };

    const handleCloseModal = () => {
        setSelectedUser(null);
    };

    return (
        <>
            {selectedUser && <UserDetails
                show={!!selectedUser}
                onClose={handleCloseModal}
                userId={selectedUser}
            />}

            <div className="d-flex justify-content-center">
                <div className="col-12">
                    <div className="card mt-3 shadow-sm bg-body" style={{ border: '0.5px solid #ccc', borderRadius: '20px' }} key={index}>
                        <div className="card-body">
                            <div className="row">
                                <div className='col d-flex align-items-center mb-md-0'>
                                    <p style={{ fontSize: "22px", fontWeight: "bold", marginBottom: '0px', color: '#02335B' }}>{join.trip.departure_city}</p>
                                    <svg width="100" height="22" viewBox="0 0 100 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ margin: '0 20px' }}>
                                        <line x1="0" y1="12" x2="85" y2="12" stroke="#02335B" strokeWidth="2" />
                                        <polygon points="85,0 100,12 85,24" fill="#02335B" />
                                    </svg>
                                    <p style={{ fontSize: "22px", fontWeight: "bold", marginBottom: '0px', color: '#02335B' }}>{join.trip.destination_city}</p>
                                </div>
                                <div className="col align-items-center mb-md-0">
                                    <p style={{ margin: '0' }} className='text-end'><span style={{ fontWeight: "bold", fontSize: '16px', color: '#FD7E14' }}>{join.status}</span></p>
                                </div>
                            </div>

                            <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />

                            <div className="row mt-4 d-flex justify-content-center">
                            <div className="col custom-col">
                                    <div className="d-flex flex-column h-100">
                                        <p style={{ fontSize: '12px', color: "grey", margin: '0' }}>Trip Start date</p>
                                        <p style={{ fontSize: '14px', fontWeight: "bold", color: "#02335B", margin: '0' }}>{join.trip.trip_start_time}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="d-flex flex-row justify-content-center align-items-center">
                                        <div className="col-4 rounded-circle overflow-hidden clickable-circle"
                                            onClick={() => handleUserClick(join.user_id)} style={{ width: '60px', height: '60px', backgroundColor: 'lightgrey' }}>

                                            <div className="d-flex justify-content-center align-items-center" style={{ width: '100%', height: '100%' }}>
                                                <FontAwesomeIcon icon={faUser} size="lg" />
                                            </div>
                                        </div>
                                        <div className='col-8' style={{ marginLeft: '10px' }}>
                                            <div className="row">
                                                <div className='align-items-center'>
                                                    {/* <p>{join.user_id}</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <button className='custom-button' onClick={() => showTrip(join.trip.id)}>{t('common:trip')}</button>
                                    {showRejectApproveButton(join.status) && <button className='custom-button' disabled={loading} onClick={() => approveRequest(join.id)}>
                                        {loading ? <div className="loading-spinner" /> : t('tripdetails:approveLabel')}
                                    </button>}
                                    {showRejectApproveButton(join.status) && <button className='custom-button' disabled={loading} onClick={() => rejectRequest(join.id)}>
                                        {loading ? <div className="loading-spinner" /> : t('tripdetails:rejectLabel')}
                                    </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReceivedJoinReqCard;