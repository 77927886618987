import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '../translations/en';
import bg from '../translations/bg';

const LANGUAGES = {
  en,
  bg
};

const LANGUAGE_KEY = 'i18nextLng';

const savedLanguage = localStorage.getItem(LANGUAGE_KEY) || 'en';

  i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // set options
  .init({
    compatibilityJSON: 'v3',
    fallbackLng: 'en',
    lng: savedLanguage,
    resources: LANGUAGES,
    react: {
      useSuspense: false
    },
    interpolation: {
      escapeValue: false
    }
  });