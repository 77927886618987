import React, { createContext, useState, useContext } from 'react';
import { fetchTrips } from "../util/http";

const TripsContext = createContext(null);

export const useTrips = () => {
    return useContext(TripsContext);
};

export const TripsProvider = ({ children }) => {
    const [trips, setTrips] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [lastPage, setLastPage] = useState();
    const [loadingMore, setLoadingMore] = useState(false);
    const [date, setDate] = useState();
    const [filters, setFilters] = useState({
        departure_city: '',
        destination_city: '',
        between_from_time: '',
        between_to_time: '',
        orderBy: '',
        pets_allowed: '',
        air_conditioning: '',
        smoking_allowed: '',
        picks_from_address: ''
    });

    const getNextPage = () => {
        if (currentPage < lastPage && !loadingMore) {
            handleSearch(currentPage + 1);
        }
    };

    const handleSearch = async (page = 1) => {
        setLoading(true);
        setLoadingMore(true);
        const filtersForSearch = {
            ...filters,
            departure_city: filters.departure_city.value,
            destination_city: filters.destination_city.value
        };

        let pageToSearch = page !== 1 ? page : 1
        try {
            const response = await fetchTrips(filtersForSearch, pageToSearch);
            setLastPage(response.meta.last_page)
            setCurrentPage(response.meta.current_page);
            setTrips(prevTrips => page === 1 ? response.data : [...prevTrips, ...response.data]);
        } catch (error) {
            console.log('Error fetching trips:', error);
        } finally {
            setLoading(false);
            setLoadingMore(false);
        }
    };

    const value = {
        trips,
        setTrips,
        currentPage,
        setCurrentPage,
        lastPage,
        setLastPage,
        filters,
        setFilters,
        date,
        setDate,
        handleSearch,
        getNextPage,
        loading,
        loadingMore,
        setLoadingMore
    };

    return (
        <TripsContext.Provider value={value}>
            {children}
        </TripsContext.Provider>
    );
};