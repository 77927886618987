export default {
    tripCreateTitle: "Create your adventure now",
    createButtonLabel: "Create",
    createFormLeaving: "Leaving from",
    createFormGoing: "Going to",
    selectStartTime: 'Departure time',
    selectArrivalTime: 'Arrival time',
    departurePoint: 'Departure point',
    pricePerPassenger: 'Price per passenger',
    carBrand: 'Car brand',
    carModel: 'Car model',
    seatsCapacity: 'Seats capacity',
    seatsTaken: 'Seats taken',
    suitcasesCapacity: 'Suitcases capacity',
    suitcasesTaken: 'Suitcases taken',
    petsAllowed: 'Pets allowed',
    smokingAllowed: 'Smoking allowed',
    airConditioning: 'Air conditioning',
    picksFromAddress: 'Picks from address',
    saveConfiguration: 'Save configuration',
    onlyNumericValues: "Only numeric values are allowed",
    fieldMinSymbols1: "Min 1 character",
    fieldMinSymbols8: "Min 8 characters",
    fieldMinSymbols2: "Min 2 characters",
    fieldMaxSymbols20: "Max 20 characters",
    tripCreatedModalMessage: "Trip created successfully",
    tripCreateFailureModalMessage: "There was a problem creating the trip. Please try again later or contact our support team.",
}