import { useEffect } from 'react';
import { socialAuthCallback } from '../util/http';
import { useAuth } from '../providers/AuthProvider';
import { useNavigate } from "react-router-dom";
import {useLocation} from "react-router-dom";

const GoogleAuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { updateToken } = useAuth();

  const goToHome = () => {
    navigate('/');
  };
  
  async function handleGoogleCallback() {
    try {
      const response = await socialAuthCallback(location);
      if (response.data.access_token) {
        updateToken(response.data.access_token);
        navigate('/');
      }
    } catch (error) {
      console.log('Cannot sign in with Google');
      goToHome();
    }
}

  useEffect(() => {
    handleGoogleCallback();
  }, []);

};

export default GoogleAuthCallback;
