import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { showTrip } from "../util/http";
import { useAuth } from "../providers/AuthProvider";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import '../css/TripDetails.css';
import ModalMessage from "../components/ModalMessage";
import Select from 'react-select';
import { createJoinRequest } from "../util/http";
import config from '../util/config';

const TripDetails = () => {
    const { id } = useParams();
    const { token } = useAuth();
    const [trip, setTrip] = useState({});
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState({
        pet: false,
        additional_passenger: false,
        additional_passenger_category: null,
        suitcases: 0,
    });
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('success');
    const [modalMessage, setModalMessage] = useState('');

    const passengerCategories = [
        { label: t('tripdetails:baby'), value: 'baby' },
        { label: t('tripdetails:child'), value: 'child' },
        { label: t('tripdetails:adult'), value: 'adult' },
    ];

    async function getTripData() {
        try {
            const trip = await showTrip(token, id);
            setTrip(trip);
        } catch (error) {
            console.log('Could not fetch trip data !');
        }
    }

    useEffect(() => {
        getTripData();
    }, []);

    const onSubmit = async (data) => {
        inputs.suitcases = handleSuitcases(data);
        try {
            setLoading(true);
            const response = await createJoinRequest(id, inputs, token);

            if (response.status === 201) {
                setModalMessage(t('tripdetails:joinSuccessful'));
                handleModal('success');
            }
        } catch (error) {
            handleModal('failure', error);
        } finally {
            setLoading(false);
        }
    }

    const handleInput = (input, value) => {
        setInputs(prevInputs => ({
            ...prevInputs,
            [input]: value
        }));
    };

    function handleSuitcases(data) {
        if (data.suitcases && data.suitcases > 0) {
            inputs.suitcases = data.suitcases;
            return data.suitcases;
        }

        inputs.suitcases = 0;
        return inputs.suitcases;
    }

    function handleModal(type, error = null) {
        if (type === 'success') {
            setModalType('success');
            setShowModal(true);
        } else if (type === 'failure') {
            setModalType('failure');
            if (error.response?.status && [400, 401, 422, 409].includes(error.response.status)) {
                setModalMessage(error.response.data.error);
            } else {
                setModalMessage(t('common:generalFormSubmitServerError'));
            }
            setShowModal(true);
        }
    }

    function handleCloseModal() {
        setShowModal(false);
        getTripData();
    }

    const customSelectStyles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black'
        }),
        singleValue: provided => ({
            ...provided,
            color: 'black'
        })
    }

    return (
        <div className="container-fluid custom-container mt-5">
            <ModalMessage
                show={showModal}
                onClose={handleCloseModal}
                message={modalMessage}
                messageType={modalType}
            />
            <div className="row">
                <div className="col-md-8">
                    <div>
                        <p>
                            <span style={{ color: '#02335B', fontWeight: 'bolder', fontSize: '18px' }}>{trip.departure_city}</span>
                            <span style={{ color: '#a8a6a6', marginLeft: '10px', marginRight: '10px' }}>to</span>
                            <span style={{ color: '#02335B', fontWeight: 'bolder', fontSize: '18px' }}>{trip.destination_city}</span>
                        </p>
                    </div>

                    <hr className="custom-hr" />

                    <div className="row">
                        <div className="col">
                            <p style={{ color: '#a8a6a6', fontSize: '12px' }}>Trip Start date</p>
                            <p style={{ color: '#02335B', fontWeight: 'bolder', fontSize: '16px' }}>{trip.trip_start_time}</p>
                        </div>
                        <div className="col">
                            <p style={{ color: '#a8a6a6', fontSize: '12px' }}>Estimated arrival</p>
                            <p style={{ color: '#02335B', fontWeight: 'bolder', fontSize: '14px' }}>{trip.estimated_arrival_time}</p>
                        </div>
                    </div>

                    <hr className="custom-hr" />

                    <div id='driver-info' className='row'>
                        <div id="driver-avatar" className="col-md-6">
                            <div className="d-flex flex-row justify-content-center align-items-center">
                                <div className="col-4 rounded-circle overflow-hidden"
                                    style={{ width: '60px', height: '60px', backgroundColor: 'lightgrey' }}>
                                    {trip.driver?.avatar ? (
                                        <img src={config.BACKEND_PIC_URL + '/' + trip.driver?.avatar} alt="Driver Avatar" className="img-fluid" />
                                    ) : (
                                        <img src="img/noavatar.png"
                                            alt="NoAvatar" className="img-fluid" />
                                    )}
                                </div>
                                <div className='col-8' style={{ marginLeft: '10px' }}>
                                    <div className="row">
                                        <div className='align-items-center'>
                                            <p style={{ color: "#02335B", fontWeight: "bold", margin: '0', fontSize: '14px' }}>{trip.driver?.name}</p>
                                        </div>
                                        <div className='d-flex flex-row align-items-center'>
                                            <i className="bi bi-star-fill" style={{ color: '#FFAF34', fontSize: '18px', marginRight: '10px' }}></i>
                                            <p style={{ margin: '0', fontSize: '13px' }}>{trip.driver?.avg_driver_rating ? trip.driver?.avg_driver_rating : 0}/5</p>
                                            <div className='col'>
                                                <p style={{ margin: 0, marginLeft: '2px', fontSize: '13px' }}><span style={{ color: "grey" }}>({trip.driver?.count_rated_by} reviews)</span></p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex flex-row align-items-center">
                                                <i className="bi bi-car-front" style={{ color: 'black', fontSize: '15px', marginRight: '5px' }}></i>
                                                <p style={{ margin: 0, fontSize: '10px' }}>Total rides: {trip.driver?.total_trips_as_driver}</p>
                                            </div>

                                            <div className="d-flex flex-row align-items-center">
                                                <i className="bi bi-calendar3" style={{ color: 'black', fontSize: '15px', marginRight: '5px' }}></i>
                                                <p style={{ margin: 0, fontSize: '10px' }}>Member since: {trip.driver?.member_since} </p>
                                            </div>

                                            <div className="d-flex flex-row align-items-center">
                                                <i className="bi bi-check-circle" style={{ color: 'green', fontSize: '15px', marginRight: '5px' }}></i>
                                                <p style={{ margin: 0, fontSize: '10px' }}>Email address verified</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="car-avatar" className="col-md-6">
                            <div className="d-flex flex-row justify-content-center align-items-center">
                                <div className="col-4 rounded-circle overflow-hidden" style={{ width: '60px', height: '60px' }}>
                                    {/* {trip.driver?.avatar ? (
                                        <img src={config.BACKEND_PIC_URL + '/' + trip.driver.avatar} alt="Driver Car" className="img-fluid" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                    ) : (
                                        <img src="img/default_car_image.png" alt="NoCar" className="img-fluid" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                    )} */}
                                    <img src="img/default_car_image.png" alt="NoCar" className="img-fluid" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                </div>
                                <div className='col-8' style={{ marginLeft: '10px' }}>
                                    <div className="row">
                                        <div className='align-items-center'>
                                            <p style={{ color: "#02335B", fontWeight: "bold", margin: '0', fontSize: '14px' }}>{trip.driver?.car.brand} {trip.driver?.car.model}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className="custom-hr" />

                    <div>
                        <p style={{ color: '#02335B', fontWeight: 'bolder', fontSize: '18px' }}>Pickup and destination location</p>
                        <p style={{ color: '#a8a6a6', fontSize: '12px' }}>Pickup point</p>
                        <p style={{ color: '#02335B', fontWeight: 'bolder', fontSize: '14px' }}>{trip.departure_point}</p>
                    </div>

                    <hr className="custom-hr" />

                    <div>
                        <p style={{ color: '#02335B', fontWeight: 'bolder', fontSize: '18px' }}>Preferences and policies</p>
                        <div className='row'>
                            <div className='col-6 d-flex align-items-center'>
                                {trip.smoking_allowed ? (
                                    <i className="bi bi-check-circle" style={{ color: 'green', fontSize: '20px', marginRight: '10px' }}></i>
                                ) : (
                                    <i className="bi bi-x-circle" style={{ color: 'red', fontSize: '20px', marginRight: '10px' }}></i>
                                )}
                                <p style={{ fontSize: '12px', margin: '0' }}>Smoking allowed</p>
                            </div>
                            <div className='col-6 d-flex align-items-center'>
                                {trip.air_conditioning ? (
                                    <i className="bi bi-check-circle" style={{ color: 'green', fontSize: '20px', marginRight: '10px' }}></i>
                                ) : (
                                    <i className="bi bi-x-circle" style={{ color: 'red', fontSize: '20px', marginRight: '10px' }}></i>
                                )}
                                <p style={{ fontSize: '12px', margin: '0' }}>Air conditioning</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6 d-flex align-items-center'>
                                {trip.pets_allowed ? (
                                    <i className="bi bi-check-circle" style={{ color: 'green', fontSize: '20px', marginRight: '10px' }}></i>
                                ) : (
                                    <i className="bi bi-x-circle" style={{ color: 'red', fontSize: '20px', marginRight: '10px' }}></i>
                                )}
                                <p style={{ fontSize: '12px', margin: '0' }}>Pets allowed</p>
                            </div>
                            <div className='col-6 d-flex align-items-center'>
                                {trip.picks_from_address ? (
                                    <i className="bi bi-check-circle" style={{ color: 'green', fontSize: '20px', marginRight: '10px' }}></i>
                                ) : (
                                    <i className="bi bi-x-circle" style={{ color: 'red', fontSize: '20px', marginRight: '10px' }}></i>
                                )}
                                <p style={{ fontSize: '12px', margin: '0' }}>Picks from address</p>
                            </div>
                        </div>

                        <p style={{ color: '#02335B', fontWeight: 'bolder', fontSize: '14px', marginTop: '20px' }}>Message from Driver</p>
                        <p style={{ color: '#02335B', fontSize: '14px', marginTop: '20px' }}>{trip.message_by_driver}</p>
                    </div>

                    <hr className="custom-hr" />

                    <div>
                        <p style={{ color: '#02335B', fontWeight: 'bolder', fontSize: '18px' }}>Reviews</p>
                        {trip.driver?.reviews_as_driver.length > 0 ? (
                            trip.driver.reviews_as_driver.map((review, index) => (
                                <div key={index} className="card" style={{ borderRadius: '10px', marginBottom: '10px' }}>
                                    <div className="card-body">
                                        <div className="col" style={{ textAlign: 'left' }}>
                                            <p style={{ color: '#02335B', fontWeight: 'bold', fontSize: '18px' }}>{review.sender}</p>
                                            <p style={{ margin: 0, color: '#02335B', fontSize: '10px' }}>{review.created_at}</p>
                                            <p style={{ color: '#02335B', fontSize: '14px' }}>{review.comment}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p style={{ color: '#02335B', fontSize: '14px' }}>No reviews for this user</p>
                        )}
                    </div>
                </div>

                <div id='join-card' className="col-md-4 mb-5 order-first order-md-2">
                    <div className="card text-center" style={{ border: 'none', borderRadius: '30px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <div className="card-body">
                            <div className="align-items-center mt-2 mb-3">
                                <p style={{ margin: '0' }}>
                                    <span style={{ fontWeight: "bold", fontSize: '24px', color: '#FD7E14' }}>{trip.price_per_passenger}</span>
                                    <span style={{ fontSize: '10px', color: '#02335B' }}>/seat price</span>
                                </p>
                            </div>
                            <p style={{ fontSize: '14px', color: '#02335B', fontWeight: 'bold' }}>Seats left: {trip.seats_left}</p>
                            <p style={{ fontSize: '12px', color: '#02335B' }}>{trip.seats_taken} seats booked</p>
                            {trip.can_join_trip && <form className="profile-modal-form" onSubmit={handleSubmit(onSubmit)}>
                                <div className='col'>
                                    <div className='custom-form-check mt-3 mb-3'>
                                        <input
                                            type="checkbox"
                                            name="pet"
                                            className="custom-checkbox"
                                            checked={inputs.pet}
                                            onChange={(e) => handleInput('pet', e.target.checked)}
                                        />
                                        <label style={{ fontSize: '14px' }} className="checkbox-label-filters">
                                            {t('tripdetails:pet')}
                                        </label>
                                    </div>
                                    <div className='custom-form-check mt-3 mb-3'>
                                        <input
                                            type="checkbox"
                                            name="additional_passenger"
                                            className="custom-checkbox"
                                            checked={inputs.additional_passenger}
                                            onChange={(e) => handleInput('additional_passenger', e.target.checked)}
                                        />
                                        <label style={{ fontSize: '14px' }} className="checkbox-label-filters">{t('tripdetails:additionalPassenger')}</label>
                                    </div>
                                    {inputs.additional_passenger &&
                                        <div className="form-outline mb-4">
                                            <Select
                                                styles={{
                                                    ...customSelectStyles,
                                                    control: (styles) => ({ ...styles, width: '100%', background: "#f0f0f0", border: 'none' }),
                                                }}
                                                options={passengerCategories}
                                                onChange={(value) => {
                                                    handleInput('additional_passenger_category', value.value);
                                                }}
                                                placeholder={t('tripdetails:additionalPassengerCategory')}
                                            />
                                        </div>
                                    }
                                    {inputs.additional_passenger &&
                                        <div className="form-outline mb-4">
                                            <input
                                                type="text"
                                                name="additional-passenger-name"
                                                className="form-control custom-input"
                                                {...register('suitcases', {
                                                    maxLength: {
                                                        value: 50,
                                                        message: 'Maximum length is 50 characters',
                                                    },
                                                    pattern: {
                                                        value: /^[A-Za-z\s]*$/,
                                                        message: 'Only letters are allowed',
                                                    },
                                                })}
                                                aria-invalid={errors['additional-passenger-name'] ? 'true' : 'false'}
                                                placeholder="Passenger Full Name"
                                            />
                                            {errors['additional-passenger-name'] && (
                                                <span className="text-danger">
                                                    {errors['additional-passenger-name'].message}
                                                </span>
                                            )}
                                        </div>
                                    }
                                    <div className="form-outline mb-4">
                                        <input
                                            type="text"
                                            name="suitcases"
                                            className="form-control custom-input"
                                            {...register('suitcases', {
                                                max: { value: 7, message: t('tripdetails:maxValue7') },
                                                pattern: {
                                                    value: /^[0-9]*$/,
                                                    message: t('tripcreate:onlyNumericValues'),
                                                }
                                            })}
                                            aria-invalid={errors.suitcases ? "true" : "false"}
                                            placeholder="Number of suitcases"
                                        />
                                        {errors.suitcases && <p className="text-danger">{errors.suitcases?.message}</p>}
                                    </div>

                                    <div className="text-center mt-3">
                                        {(token && trip.can_join_trip) && <button type="submit" disabled={loading} className="join-button">
                                            {loading ? <div className="loading-spinner" /> : 'Join'}
                                        </button>}
                                    </div>
                                </div>
                            </form>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TripDetails;