import { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useAuth } from "../providers/AuthProvider";
import { getUnreadNotifications, markNotificationsAsRead } from "../util/http";
import LoadingOverlay from "../components/LoadingOverlay";
import RateUser from "../components/RateUser";
import JoinRequestModal from "../components/JoinRequestModal";

const Notifications = () => {
    const { t } = useTranslation();
    const [notifications, setNotifications] = useState([]);
    const { token } = useAuth();
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState();
    const [selectedIds, setSelectedIds] = useState([]);

    const [showRateModal, setShowRateModal] = useState(false);
    const [rateUserId, setRateUserId] = useState(null);
    const [rateUserType, setRateUserType] = useState(null);

    const [showJoinRequestModal, setShowJoinRequestModal] = useState(false);
    const [joinId, setJoinId] = useState(null);
    const [joinType, setJoinType] = useState(null);
    const [joinNotificationUserId, setJoinNotificationUserId] = useState(null);

    const [loadingMore, setLoadingMore] = useState(false);
    const loaderRef = useRef(null);

    const getNextPage = () => {
        if (currentPage < lastPage && !loadingMore) {
            getNotifications(currentPage + 1);
        }
    };

    useEffect(() => {
        getNotifications();
    }, []);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '20px',
            threshold: 0
        };

        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                getNextPage();
            }
        }, options);

        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [loadingMore]);

    async function getNotifications(page = 1) {
        setLoading(true);
        setLoadingMore(true);

        try {
            let pageToSearch = page !== 1 ? page : 1
            const response = await getUnreadNotifications(token, pageToSearch);
            setLastPage(response.meta.last_page)
            setCurrentPage(response.meta.current_page);
            setNotifications(response.data);
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
            setLoadingMore(false);
        }
    }

    function selectId(id) {
        setSelectedIds((prevIds) => {
            if (prevIds.includes(id)) {
                return prevIds.filter((itemId) => itemId !== id);
            } else {
                return [...prevIds, id];
            }
        });
    }

    async function markAsReadHandler() {
        setLoading(true);
        try {
            const payload = getParsedPayload();
            await markNotificationsAsRead(payload, token);
            getNotifications();
        } catch (error) {
            console.log('Could not fetch user notifications !');
        } finally {
            setLoading(false);
        }
    }

    function getParsedPayload() {
        const parsedIds = selectedIds.map((itemId) => itemId);
        return { ids: parsedIds };
    }

    const handleRateUser = (notification) => {
        if (notification.data.driver_id) {
            setRateUserId(notification.data.driver_id);
            setRateUserType('driver');
        } else {
            setRateUserId(notification.data.passenger_id);
            setRateUserType('passenger');
        }
    }

    const handleCloseRateModal = () => {
        setRateUserId(null);
    }

    const handleJoinRequest = (notification) => {
        setJoinId(notification.data.trip_join_request_id);
        setJoinType(notification.data.notification_type);
        if (notification.data.requester_id) {
            setJoinNotificationUserId(notification.data.requester_id);
        } else {
            setJoinNotificationUserId(notification.data.driver_id);
        }

    }

    const handleCloseJoinRequestModal = () => {
        setJoinId(null);
    }

    const showJoinRequestButton = (notification) => {
        if (notification.data.notification_type == 'joinRequestApproved' ||
            notification.data.notification_type == 'joinRequestCancelled' ||
            notification.data.notification_type == 'joinRequestCreated' ||
            notification.data.notification_type == 'joinRequestRejected') {
            return true;
        }

        return false;
    }

    return (
        <>
            {rateUserId &&
                <RateUser
                    show={!!rateUserId}
                    onClose={handleCloseRateModal}
                    userId={rateUserId}
                    userType={rateUserType}
                />}
            {joinId &&
                <JoinRequestModal
                    show={!!joinId}
                    onClose={handleCloseJoinRequestModal}
                    joinId={joinId}
                    joinType={joinType}
                    joinNotificationUserId={joinNotificationUserId}
                />}
            <LoadingOverlay loading={loading}>
                <div className='container'>
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-xl-10 mt-5">
                            <div className="card text-white mb-2" style={{ borderRadius: "1rem" }}>
                                <div className="card-body">
                                    <p style={{ color: "#02335B", fontWeight: 'bold', fontSize: '24px', marginBottom: '20px', textAlign: 'center' }}>Notifications
                                        <span className="notifications-count-badge">{notifications.length}</span>
                                    </p>
                                    {selectedIds.length > 0 &&
                                        <div className="d-flex justify-content-center pt-1 mb-4">
                                            <button className='mark-as-read-button' onClick={markAsReadHandler}>
                                                {t('notifications:markAsRead')}
                                            </button>
                                        </div>
                                    }
                                    <hr className="hr-rooms" />
                                    {notifications.length > 0 ? (
                                        <div className="d-flex justify-content-center">
                                            {notifications.map((notification, index) => (
                                                <div className="d-flex justify-content-center align-items-center mt-3 mb-3" key={index}>
                                                    <div className='col-md-8 custom-form-check'>
                                                        <input
                                                            type="checkbox"
                                                            name="pet"
                                                            className="custom-checkbox"
                                                            checked={selectedIds.includes(notification.id)}
                                                            onChange={() => { selectId(notification.id) }}
                                                        />
                                                        <div style={{ marginLeft: '10px' }}>
                                                            <p style={{ color: 'black', fontSize: '16px' }} className="checkbox-label-filters">
                                                                {t(`notifications:${notification.data.message}`)}
                                                            </p>
                                                            <p style={{ textAlign: 'left', color: "#9d9c9c", fontSize: '12px' }}>{notification.created_at}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 d-flex justify-content-center alignt-items-center" style={{ marginLeft: '20px' }}>
                                                        {(notification.data.notification_type == 'rateTheDriver' || notification.data.notification_type == 'rateThePassenger') &&
                                                            <button className="custom-button" onClick={() => handleRateUser(notification)}>
                                                                {t('notifications:rateUser')}
                                                            </button>
                                                        }
                                                        {showJoinRequestButton(notification) &&
                                                            <button className="custom-button" onClick={() => handleJoinRequest(notification)}>
                                                                {t('notifications:joinRequestLabel')}
                                                            </button>
                                                        }
                                                    </div>

                                                    <hr />
                                                </div>
                                            ))}
                                            {loading && <div className="spinner">Loading...</div>}
                                            <div ref={loaderRef} style={{ height: '20px' }} />
                                        </div>
                                    ) : (
                                        <div className="d-flex justify-content-center pt-1 mb-4">
                                            {!loading && <p>{t('common:noResultsFound')}</p>}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay >
        </>
    );
}

export default Notifications;