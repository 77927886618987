import home from './home';
import menu from './menu';
import common from './common';
import tripcreate from './tripcreate';
import tripcard from './tripcard';
import searchfilters from './searchfilters';
import profile from './profile';
import tripdetails from './tripdetails';
import notifications from './notifications';
import cities from './cities';
import joinstatuses from './joinstatuses';

export default {
    home,
    menu,
    common,
    tripcreate,
    tripcard,
    searchfilters,
    profile,
    tripdetails,
    notifications,
    cities,
    joinstatuses,
}