export default {
    departurePoint: 'Начална точка',
    pricePerPassenger: 'Цена на пътник',
    carBrand: 'Марка кола',
    carModel: 'Модел кола',
    seatsCapacity: 'Капацитет места',
    seatsTaken: 'Заети места',
    suitcasesCapacity: 'Капацитет куфари',
    suitcasesTaken: 'Заети места за багаж',
    petsAllowed: 'Домашни любимци',
    smokingAllowed: 'Разрешено пушене',
    airConditioning: 'Климатик',
    picksFromAddress: 'Взема от адрес',
    departureCity: "Тръгва от",
    destinationCity: "Пристига в",
    tripStartTime: "Час на тръгване",
    estimatedArrivalTime: "Приблизителен час на пристигане",
    seatsLeft: "Оставащи места",
    driverName: "Име на шофьора",
    avgDriverRating: "Рейтинг на шофьора",
    yes: "Да",
    no: "Не",
    baby: "Бебе",
    child: "Дете",
    adult: "Възрастен",
    pet: "Домашен любимец",
    additionalPassenger: "Допълнителен пътник",
    additionalPassengerCategory: "Категория допълнителен пътник",
    suitcases: "Брой куфари",
    maxValue7: "Макс 7",
    cancelJoinRequestConfirmation: "Сигурни ли сте, че искате да се откажете от това пътуване?",
    approveLabel: "Одобри",
    rejectLabel: "Откажи",
    generalConfirmationMessage: "Mоля потвърдете вашия избор",
    joinSuccessful: "Успешно изпращане",
}

    