export default {
    login: "Влез",
    register: "Регистрация",
    logout: "Излез",
    profile: "Профил",
    chats: "Чатове",
    trips: "Пътувания",
    profileTripsDriver: "Пътувания като шофьор",
    profileTripsPassenger: "Пътувания като пътник",
    profileNotifications: "Известия",
    profileTripCreate: "Създай пътуване",
    profileInfo: "Информация за профила",
    profileSentJoinRequests: "Изпратени молби",
    profileReceivedJoinRequests: "Получени молби",
}