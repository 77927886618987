import { useTranslation } from 'react-i18next';
import StarRatings from 'react-star-ratings';
import UserDetails from './UserDetails';
import { useState, useEffect } from 'react';
import config from '../util/config';
import { Modal, Button } from "react-bootstrap";
import '../css/TripCard.css';


const TripCardDriver = ({ showTripCancelButton, trip, index, cancelTrip }) => {
    const [showCancelModal, setShowCancelModal] = useState(false);
    const { t } = useTranslation();
    const [selectedUser, setSelectedUser] = useState(null);
    const [reason, setReason] = useState('');
    const [errors, setErrors] = useState(null);

    const handleUserClick = (userId) => {
        setSelectedUser(userId);
    };

    const handleCloseModal = () => {
        setSelectedUser(null);
    };

    const handleCancelTrip = () => {
        if (!reason) {
            setErrors({ reason: t('common:requiredField') });
            return;
        }

        setShowCancelModal(false);
        cancelTrip(trip.id, reason);
    }

    useEffect(() => {
        if (!showCancelModal) {
            setReason('');
            setErrors(null);
        }
    }, [showCancelModal]);


    return (
        <>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                show={showCancelModal} onHide={() => { setShowCancelModal(false) }}>
                <div className="text-center">
                    <p className="modal-p">{t('tripcard:cancelTripConfirmation')}</p>
                    <p htmlFor="reasonTextarea" className="form-label">{t('tripcard:tripCancellationReason')}</p>
                    <textarea
                        className="form-control"
                        id="reasonTextarea"
                        rows="4"
                        maxLength="500"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        required
                    ></textarea>
                    {errors && <span className="text-danger">{errors.reason}</span>}
                </div>
                <Modal.Footer>
                    <Button className="btn custom-btn btn-md btn-block mx-auto" variant="secondary" onClick={() => handleCancelTrip()}>
                        {t('tripdetails:yes')}
                    </Button>
                    <Button className="btn custom-btn btn-md btn-block mx-auto" variant="secondary" onClick={() => setShowCancelModal(false)}>
                        {t('tripdetails:no')}
                    </Button>
                </Modal.Footer>
            </Modal>

            {selectedUser && <UserDetails
                show={!!selectedUser}
                onClose={handleCloseModal}
                userId={selectedUser}
            />}

            <div className="d-flex justify-content-center">
                <div className="col-12">
                    <div className="card mt-3 shadow-sm bg-body" style={{ border: '0.5px solid #ccc', borderRadius: '20px' }} key={index}>
                        <div className="card-body">

                            <div className="row">
                                <div className='col d-flex align-items-center mb-md-0'>
                                    <p style={{ fontSize: "22px", fontWeight: "bold", marginBottom: '0px', color: '#02335B' }}>{trip.departure_city}</p>
                                    <svg width="100" height="22" viewBox="0 0 100 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ margin: '0 20px' }}>
                                        <line x1="0" y1="12" x2="85" y2="12" stroke="#02335B" strokeWidth="2" />
                                        <polygon points="85,0 100,12 85,24" fill="#02335B" />
                                    </svg>
                                    <p style={{ fontSize: "22px", fontWeight: "bold", marginBottom: '0px', color: '#02335B' }}>{trip.destination_city}</p>
                                </div>
                                <div className="col align-items-center mb-3 mb-md-0">
                                    <p style={{ margin: '0' }} className='text-end'><span style={{ fontWeight: "bold", fontSize: '24px', color: '#FD7E14' }}>{trip.price_per_passenger} lv </span><span style={{ fontSize: '10px', color: '#02335B' }}>/ seat price</span></p>
                                </div>
                            </div>

                            <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />

                            <div className="row mt-4">
                                <div className="col-md-4 mb-3">
                                    <div className="d-flex flex-column h-100">
                                        <p style={{ fontSize: '12px', color: "grey" }}>Trip Start date</p>
                                        <p style={{ fontSize: '14px', fontWeight: "bold", color: "#02335B" }}>{trip.trip_start_time}</p>
                                        <div>
                                            <p style={{ fontSize: '12px', color: 'grey' }}>{t('tripdetails:departurePoint')}: <span style={{ color: "#02335B", fontWeight: "bold" }}>{trip.departure_point}</span></p>
                                        </div>
                                        <div>
                                            <p style={{ fontSize: '12px', color: 'grey' }}>Estimated arrival: <span style={{ color: "#02335B", fontWeight: "bold" }}>{trip.estimated_arrival_time}</span></p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-4 mb-3 d-flex justify-content-center align-items-center'>
                                    {showTripCancelButton &&
                                        <button
                                            onClick={() => setShowCancelModal(true)}
                                            className='custom-button'
                                        >
                                            Откажи пътуването
                                        </button>}
                                </div>

                                <div className='col-md-4 mb-3'>
                                    {trip.passengers.length > 0 &&
                                        <div>
                                            <p style={{ color: '#02335B' }}>{t('common:passengers')}</p>
                                            {trip.passengers.map((passenger, index) => (
                                                <div className="d-flex flex-row justify-content-center align-items-center" key={index}>
                                                    <div className="rounded-circle overflow-hidden clickable-circle"
                                                        onClick={() => handleUserClick(passenger.id)} style={{ width: '60px', height: '60px', backgroundColor: 'lightgrey' }}>
                                                        {passenger.avatar ? (
                                                            <img src={config.BACKEND_PIC_URL + '/' + passenger.avatar} alt="Passenger Avatar" className="img-fluid" />
                                                        ) : (
                                                            <img src="img/noavatar.png"
                                                                alt="NoAvatar" className="img-fluid" />
                                                        )}
                                                    </div>
                                                    <div className='col-8' style={{ marginLeft: '10px' }}>
                                                        <p style={{ color: "#02335B", fontWeight: "bold", margin: '0', fontSize: '14px'}}>{passenger.name}</p>
                                                    </div>
                                                </div>))}
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TripCardDriver;