export default {
    departurePoint: 'Departure point',
    pricePerPassenger: 'Price per passenger',
    carBrand: 'Car brand',
    carModel: 'Car model',
    seatsCapacity: 'Seats capacity',
    seatsTaken: 'Seats taken',
    suitcasesCapacity: 'Suitcases capacity',
    suitcasesTaken: 'Suitcases taken',
    petsAllowed: 'Pets allowed',
    smokingAllowed: 'Smoking allowed',
    airConditioning: 'Air conditioning',
    picksFromAddress: 'Picks from address',
    departureCity: "Leaving from",
    destinationCity: "Going to",
    tripStartTime: "Trip start time",
    estiamtedArrivalTime: "Estimated arrival time",
    seatsLeft: "Seats left",
    driverName: "Driver's name",
    avgDriverRating: "Driver's rating",
    yes: "Yes",
    no: "No",
    baby: "Baby",
    child: "Child",
    adult: "Adult",
    pet: "Pet",
    additionalPassenger: "Additional passenger",
    additionalPassengerCategory: "Additional passenger category",
    suitcases: "Num suitcases",
    maxValue7: "Max 7",
    cancelJoinRequestConfirmation: "Are you sure you want to cancel this request?",
    approveLabel: "Approve",
    rejectLabel: "Reject",
    generalConfirmationMessage: "Please confirm your action",
    joinSuccessful: "Your request was sent.",
}