import './App.css';
import Navigation from './components/Navigation';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './providers/AuthProvider';
import './util/IMLocalize';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Home from './pages/Home';
import ProtectedRoute from './routes/ProtectedRoute';
import Login from './pages/Login';
import Register from './pages/Register';
import Profile from './pages/Profile';
import Chats from './pages/Chats';
import TripCreate from './pages/TripCreate';
import Notifications from './pages/Notifications';
import SentJoinRequests from './pages/SentJoinRequests';
import ReceivedJoinRequests from './pages/ReceivedJoinRequests';
import TripsPassenger from './pages/TripsPassenger';
import TripsDriver from './pages/TripsDriver';
import PageNotFound from './pages/PageNotFound';
import ResetPassword from './pages/ResetPassword';
import ConfirmRegistration from './pages/ConfirmRegistration';
import GoogleAuthCallback from './components/GoogleAuthCallback';
import Trips from './pages/Trips';
import { TripsProvider } from './providers/TripsProvider';
import TripDetails from './pages/TripDetails';


function App() {
  return (
    <Router>
      <AuthProvider>
        <TripsProvider >
          <div>
            <Navigation />
            <Routes>
              <Route index element={<Home />} />
              <Route path="google-auth/callback" element={<GoogleAuthCallback />} />

              <Route path="/" element={<Home />} />
              <Route
                path="trips"
                element={
                  <Trips />
                }
              />
              <Route
                path="trips/:id"
                element={
                  <ProtectedRoute>
                    <TripDetails />
                  </ProtectedRoute>
                }
              />
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route path="confirm-registration" element={<ConfirmRegistration />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="profile-info"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="notifications"
                element={
                  <ProtectedRoute>
                    <Notifications />
                  </ProtectedRoute>
                }
              />
              <Route
                path="create-trip"
                element={
                  <ProtectedRoute>
                    <TripCreate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="sent-join-requests"
                element={
                  <ProtectedRoute>
                    <SentJoinRequests />
                  </ProtectedRoute>
                }
              />
              <Route
                path="received-join-requests"
                element={
                  <ProtectedRoute>
                    <ReceivedJoinRequests />
                  </ProtectedRoute>
                }
              />
              <Route
                path="user-trips-driver"
                element={
                  <ProtectedRoute>
                    <TripsDriver />
                  </ProtectedRoute>
                }
              />
              <Route
                path="user-trips-passenger"
                element={
                  <ProtectedRoute>
                    <TripsPassenger />
                  </ProtectedRoute>
                }
              />
              <Route
                path="chats"
                element={
                  <ProtectedRoute>
                    <Chats />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
        </TripsProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
