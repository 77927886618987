import StarRatings from "react-star-ratings";
import { Modal, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useAuth } from "../providers/AuthProvider";
import { getUserDetails, reportUser } from "../util/http";
import config from "../util/config";
import ModalMessage from "./ModalMessage";


const UserDetails = ({ show, onClose, userId }) => {
    const { t } = useTranslation();
    const { token } = useAuth();
    const [user, setUserData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [complaint, setComplaint] = useState('');
    const [showComlaint, setShowComplaint] = useState(false);
    const [errors, setErrors] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('success');
    const [modalMessage, setModalMessage] = useState('');

    useEffect(() => {
        getUserData();

        if (!showComlaint) {
            setComplaint('');
            setErrors(null);
        }
    }, [showComlaint]);

    async function getUserData() {
        setLoading(true);
        try {
            const response = await getUserDetails(userId, token);
            setUserData(response.data);
        } catch (error) {
            console.log('Could not fetch user data !');
        } finally {
            setLoading(false);
        }
    }

    const handleCloseComplaintResponse = () => {
        setShowComplaint(false);
        setComplaint('');
        setErrors(null);
    }

    async function handleReportUser() {
        if (!complaint) {
            setErrors({ reason: t('common:requiredField') });
            return;
        }

        setLoading(true);
        try {
            const response = await reportUser(userId, { message: complaint }, token);
            if (response.status === 201) {
                setModalMessage(t('profile:complaintPostedSuccessfullyMessage'));
                handleModal('success');
            }
        } catch (error) {
            handleModal('failure', error);
        } finally {
            setLoading(false);
        }
    }

    function handleModal(type, error = null) {
        if (type === 'success') {
            setModalType('success');
            setShowModal(true);
        } else if (type === 'failure') {
            setModalType('failure');
            if (error.response?.status && [400, 401, 422, 409].includes(error.response.status)) {
                setModalMessage(error.response.data.error);
            } else {
                setModalMessage(t('common:generalFormSubmitServerError'));
            }
            setShowModal(true);
        }
    }

    function handleCloseModal() {
        setShowModal(false);
        handleCloseComplaintResponse()
        onClose();
    }

    return (
        <>
            <ModalMessage
                show={showModal}
                onClose={handleCloseModal}
                message={modalMessage}
                messageType={modalType}
            />

            <Modal size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered show={show} onHide={onClose} className="trip-details-modal-dialog">
                <Modal.Body>
                    <div className="row g-0">
                        <div className="col-md-3 text-center text-white"
                            style={{ borderRadius: "1rem", backgroundColor: "#ff7022" }}>
                            {user.avatar ? <img src={config.BACKEND_PIC_URL + '/' + user.avatar} alt="Avatar" className="img-fluid my-5" style={{ width: "80px" }} /> : <img src="img/noavatar.png"
                                alt="NoAvatar" className="img-fluid my-5" style={{ width: "80px" }} />}
                            <h5>{user.name}</h5>
                        </div>
                        <div className="col-md-9">
                            <div className="card-body p-4">
                                <h6>{t('profile:driver')}</h6>
                                <hr className="mt-0 mb-4" />
                                <div className="row pt-1">
                                    <div className="col mb-3">
                                        <h6>{t('menu:profileTripsDriver')}</h6>
                                        <p className="text-muted">{user.driver?.trips}</p>
                                    </div>
                                    <div className="col mb-3">
                                        <h6>{t('profile:driverRating')}</h6>
                                        <div className='d-flex flex-row'>
                                            <StarRatings
                                                starRatedColor="#ff7022"
                                                rating={user.driver?.rating ? user.driver.rating : 0}
                                                starDimension="20px"
                                                starSpacing="2px"
                                            />
                                            <div className='col mt-1'>
                                                <p>({user.driver?.count_rated_by})</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col mb-3" style={{ height: '200px' }}>
                                        <h6>{t('profile:comments')}</h6>
                                        <div className="scrollable-comments">
                                            {user.driver?.comments.map((comment, index) => (
                                                <div key={index}>
                                                    <p className="sender-text">{comment.sender}</p>
                                                    <div className="comment-card">
                                                        <p>{comment.comment}</p>
                                                    </div>
                                                    <hr className="mt-0 mb-4" />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <h6>{t('profile:passenger')}</h6>
                                <hr className="mt-0 mb-4" />
                                <div className="row pt-1">
                                    <div className="col mb-3">
                                        <h6>{t('menu:profileTripsPassenger')}</h6>
                                        <p className="text-muted">{user.passenger?.trips}</p>
                                    </div>
                                    <div className="col mb-3">
                                        <h6>{t('profile:passengerRating')}</h6>
                                        <div className='d-flex flex-row'>
                                            <StarRatings
                                                starRatedColor="#ff7022"
                                                rating={user.passenger?.rating ? user.passenger.rating : 0}
                                                starDimension="20px"
                                                starSpacing="2px"
                                            />
                                            <div className='col mt-1'>
                                                <p>({user.passenger?.count_rated_by})</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col mb-3">
                                        <h6>{t('profile:comments')}</h6>
                                        <div className="scrollable-comments">
                                            {user.passenger?.comments.map((comment, index) => (
                                                <div key={index}>
                                                    <p className="sender-text">{comment.sender}</p>
                                                    <div className="comment-card">
                                                        <p>{comment.comment}</p>
                                                    </div>
                                                    <hr className="mt-0 mb-4" />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn custom-btn btn-md btn-block mx-auto" variant="secondary" onClick={() => onClose()}>
                        {t('common:closeButtonLabel')}
                    </Button>
                    <Button className="btn btn-dark btn-md btn-block mx-auto" variant="secondary" onClick={() => setShowComplaint(true)}>
                        {t('common:reportUser')}
                    </Button>
                </Modal.Footer>
                {showComlaint && <div className="d-flex flex-column align-items-center">
                    <label htmlFor="complaintTextarea" className="form-label">{t('profile:reportUserText')}</label>
                    <textarea
                        className="form-control"
                        id="complaintTextarea"
                        rows="4"
                        maxLength="500"
                        value={complaint}
                        onChange={(e) => setComplaint(e.target.value)}
                        required
                    ></textarea>
                    {errors && <span className="text-danger">{errors.reason}</span>}
                    <Button className="btn btn-dark btn-md btn-block mx-auto mt-2" variant="secondary" onClick={() => handleReportUser()}>
                        {t('profile:sendComplaintButtonText')}
                    </Button>
                </div>}
            </Modal>
        </>
    );
}

export default UserDetails;