export default {
    login: "Login",
    register: "Register",
    logout: "Logout",
    profile: "Profile",
    chats: "Chats",
    trips: "Trips",
    profileTripsDriver: "User Trips (Driver)",
    profileTripsPassenger: "User Trips (Passenger)",
    profileNotifications: "Notifications",
    profileTripCreate: "Create Trip",
    profileInfo: "Profile Info",
    profileSentJoinRequests: "Sent Join Requests",
    profileReceivedJoinRequests: "Received Join Requests",
}