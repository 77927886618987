import { Modal, Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import '../css/ModalMessage.css';

const ModalMessage = ({ show, onClose, message, messageType, onCloseFunction }) => {
    const { t } = useTranslation();

    const handleClose = () => {
        if (onCloseFunction && typeof onCloseFunction === 'function') {
            onCloseFunction();
        }
        onClose();
    }

    return (
        <Modal size="m"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            show={show}
            onHide={onClose}>
            <Modal.Body>
                <div className='text-center'>
                    {messageType === 'success' &&
                        <>
                            <i className="bi bi-clipboard2-check" style={{ color: 'green', fontSize: '50px' }}></i>
                            <p style={{ fontSize: '18px', color: '#02335B', fontWeight: 'bold' }}>Congratulations</p>
                        </>
                    }
                    {messageType === 'failure' &&
                        <>
                            <i className="bi bi-clipboard2-x" style={{ color: '#FF3D22', fontSize: '50px' }}></i>
                            <p style={{ fontSize: '18px', color: '#02335B', fontWeight: 'bold' }}>Oh! There was a problem</p>
                        </>
                    }
                    <p className="modal-p message-text">{message}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="col text-center">
                    <button className={`btn ${messageType === 'success' ? 'button-success' : 'button-failure'}`}
                        onClick={handleClose}>
                        {t('common:closeButtonLabel')}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalMessage;