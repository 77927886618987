import React, { useState, useEffect } from "react";
import { useTrips } from "../providers/TripsProvider";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { getFormattedDate } from '../util/date';
import { getCities } from "../util/cities";
import { bg, enGB } from 'date-fns/locale';
import { useNavigate } from "react-router-dom";
registerLocale('bg', bg);
registerLocale('enGB', enGB);

const TripsSearch = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { handleSubmit, control, setValue, formState: { errors } } = useForm();
    const cities = getCities(t);
    const today = new Date();
    const {
        handleSearch,
        date,
        setDate,
        filters,
        setFilters,
    } = useTrips();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Initialize the form with values from filters
        if (filters.departure_city) {
            setValue('departure_city', filters.departure_city);
        }
        if (filters.destination_city) {
            setValue('destination_city', filters.destination_city);
        }
        if (date) {
            setValue('searchDate', date)
        }
    }, [filters, setValue]);

    const handleFilterChange = (filterName, filterValue) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: filterValue
        }));
    };

    const onSubmit = () => {
        // If validation passes, perform desired action (e.g., handle search logic)
        if (!errors.departure_city && !errors.destination_city && !errors.searchDate) {
            try {
                setLoading(true);
                handleSearch();
                navigate("/trips");
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    };

    const validateCityDifference = (value, field) => {
        if (value && field === 'destination_city' && value.value === filters.departure_city.value) {
            return t('common:cityConflictValidationError');
        }
        if (value && field === 'departure_city' && value.value === filters.destination_city.value) {
            console.log(2);
            return t('common:cityConflictValidationError');
        }
        return true;
    };

    const handleDate = (selectedDate) => {
        setDate(selectedDate);

        let betweenFromDate = new Date(selectedDate);
        let betweenToDate = new Date(selectedDate);

        // Check if the selected date is not today
        if (selectedDate.toDateString() !== today.toDateString()) {
            // Set between_from_date to the start of the selected day (00:00:00)
            betweenFromDate.setHours(0, 0, 0, 0);

            // Set between_to_date to the end of the selected day (23:59:59)
            betweenToDate.setHours(23, 59, 59, 999);
        } else {
            // If today is selected, set between_from_date to the current time
            // and add 15 minutes, and set between_to_date to the end of the day (23:59:59)
            betweenFromDate = new Date(today);
            betweenFromDate.setMinutes(betweenFromDate.getMinutes() + 15);
            betweenToDate.setHours(23, 59, 59, 999);
        }

        let formattedFromDate = getFormattedDate(betweenFromDate);
        let formattedToDate = getFormattedDate(betweenToDate);

        handleFilterChange('between_from_time', formattedFromDate);
        handleFilterChange('between_to_time', formattedToDate);
    }

    const customSelectStyles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black'
        }),
        singleValue: provided => ({
            ...provided,
            color: 'black'
        }),
        input: (styles) => ({
            ...styles,
            padding: 0,
            margin: 0
        }),
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="p-3" style={{ backgroundColor: '#fff', border: '1px solid #ccc', borderRadius: '10px' }}>
                <div className="row">
                    <div className="col-md-3">
                        <Controller
                            name="departure_city"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <label style={{ fontSize: "12px", marginBottom: '4px', fontWeight: 'bold' }}>
                                        Departure
                                    </label>
                                    <Select
                                        styles={{
                                            ...customSelectStyles,
                                            control: (styles) => ({ ...styles, width: '100%', background: "#fff", border: 'none' }),
                                            menu: (styles) => ({ ...styles, width: '100%' }),
                                        }}
                                        value={field.value}
                                        onChange={(value) => {
                                            handleFilterChange('departure_city', value);
                                            field.onChange(value);
                                        }}
                                        options={cities}
                                        placeholder="Select Location"
                                    />
                                </>
                            )}
                            rules={{
                                required: t('common:requiredField'),
                                validate: value => validateCityDifference(value, 'departure_city')
                            }}
                        />
                        {errors.departure_city && <span className="text-danger">{errors.departure_city.message}</span>}
                    </div>

                    <div className="col-md-3">
                        <Controller
                            name="destination_city"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <label style={{ fontSize: "12px", marginBottom: '4px', fontWeight: 'bold' }}>
                                        Destination
                                    </label>
                                    <Select
                                        styles={{
                                            ...customSelectStyles,
                                            control: (styles) => ({ ...styles, width: '100%', background: "#fff", border: 'none' }),
                                            menu: (styles) => ({ ...styles, width: '100%' }),
                                        }}
                                        value={field.value}
                                        onChange={(value) => {
                                            handleFilterChange('destination_city', value);
                                            field.onChange(value);
                                        }}
                                        options={cities}
                                        placeholder="Select Location"
                                    />
                                </>
                            )}
                            rules={{
                                required: t('common:requiredField'),
                                validate: value => validateCityDifference(value, 'destination_city')
                            }}
                        />
                        {errors.destination_city && <span className="text-danger">{errors.destination_city.message}</span>}
                    </div>

                    <div className="col-md-3">
                        <Controller
                            name="searchDate"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <label style={{ fontSize: "12px", marginBottom: '4px', fontWeight: 'bold' }}>
                                        Date
                                    </label>
                                    <DatePicker
                                        placeholderText={t('common:selectDate')}
                                        className="date-control form-control picker"
                                        selected={date}
                                        onChange={(date) => {
                                            handleDate(date);
                                            field.onChange(date);
                                        }}
                                        dateFormat="MMMM d, yyyy"
                                        locale="enGB"
                                        minDate={today}
                                    />
                                    {errors.searchDate && <span className="text-danger">{errors.searchDate.message}</span>}
                                </>
                            )}
                            rules={{
                                required: t('common:requiredField'),
                            }}
                        />
                    </div>

                    <div className="col-md-3 d-grid gap-2 mx-auto">
                        <button className="btn custom-btn btn-lg px-5 ml-3 btn-block" type="submit" disabled={loading}>
                            {loading ? <div className="loading-spinner" /> : t('common:searchButtonLabel')}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default TripsSearch;