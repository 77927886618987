import { useEffect, useState } from "react";
import { getChatRooms, getRoomMessages, getUserProfileId } from "../util/http";
import { useAuth } from "../providers/AuthProvider";
import ChatMessages from "../components/ChatMessages";
import { useTranslation } from 'react-i18next';
import '../css/Chats.css';

const Chats = () => {
    const [loading, setLoading] = useState(false);
    const [messagesLoading, setMessagesLoading] = useState(false);
    const [rooms, setRooms] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [messages, setMessages] = useState([]);
    const [userId, setUserId] = useState('');
    const { token } = useAuth();
    const { t } = useTranslation();
    const [isColumnVisible, setIsColumnVisible] = useState(true);

    const toggleColumn = () => {
        setIsColumnVisible(!isColumnVisible);
    };

    useEffect(() => {
        async function fetchRooms() {
            setLoading(true);
            try {
                const response = await getChatRooms(token);
                setRooms(Object.values(response));
            } catch (error) {
                console.error('Error fetching rooms:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchRooms();
    }, []);

    function showEnterRoomButton(room) {
        if (room.id !== selectedRoom) {
            return true;
        }

        return false;
    }

    const handleRoomClick = async (roomId) => {
        try {
            setMessagesLoading(true);
            const response = await getRoomMessages(roomId, token);
            const userProfileId = await getUserProfileId(token);
            setMessages(response.messages);
            setSelectedRoom(roomId);
            setUserId(userProfileId);
        } catch (error) {
            //here put error modal
            console.error('Error fetching messages:', error);
        } finally {
            setMessagesLoading(false);
        }
    }

    return (
        <div className="container-md mt-5">

            <div className="row">
                <div className="col-md-3" id="chatRooms">
                    <div className="card text-white mb-2" style={{ borderRadius: "1rem" }}>
                        <div className="card-body">
                            <p style={{ color: "#02335B", fontWeight: 'bold', fontSize: '24px', marginBottom: '20px' }}>Messages</p>
                            <hr className="hr-rooms" />
                            {rooms.map((room, index) => (
                                <div key={index}>
                                    <p style={{ fontSize: '20px', color: '#02335B', marginBottom: '5px' }}>{room.trip.departure_city} {t('common:to')} {room.trip.destination_city}</p>
                                    <p style={{ color: '#B4B4B4', fontSize: '16px' }}>{room.trip.trip_start_time}</p>
                                    <div className="d-flex justify-content-end">
                                        {showEnterRoomButton(room) ? (
                                            <button className="join-room-button" onClick={() => handleRoomClick(room.id)}>
                                                {t('common:openChatRoom')}
                                            </button>
                                        ) : (
                                            <p className="joined-label">Joined</p>
                                        )}
                                    </div>
                                    <hr className="hr-rooms" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="col-md-9">
                    {selectedRoom ? (
                        <ChatMessages userId={userId} messagesLoading={messagesLoading} roomId={selectedRoom} messages={messages} setMessages={setMessages} />
                    ) : (
                        <div className="d-flex justify-content-center" >
                            {rooms.length > 0 ? (
                                <p className="chat-rooms-availability-message">Select a chat room</p>
                            ) : (
                                <p className="chat-rooms-availability-message">There are no chat rooms available</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div >
    );
}

export default Chats;