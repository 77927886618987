import { changePassword } from "../../util/http";
import { useForm } from "react-hook-form";
import { useAuth } from "../../providers/AuthProvider";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import ModalMessage from "../ModalMessage";

const ChangePassword = () => {
    const { t } = useTranslation();
    const { token, onLogout } = useAuth();
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('success');
    const [modalMessage, setModalMessage] = useState('');

    const {
        register: register,
        handleSubmit: handleSubmit,
        formState: { errors: errorsPassword },
        watch
    } = useForm();

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            await changePassword(data, token);
            handleModal('success');
            setModalMessage('You have successfully changed your password');
        } catch (err) {
            handleModal('failure', err);
        } finally {
            setLoading(false);
        }
    };

    const validatePasswordMatch = (newPassword, confirmNewPassword) => {
        return newPassword === confirmNewPassword || t('common:passwordsDoNotMatch');
    };

    function handleModal(type, error = null) {
        if (type === 'success') {
            setModalType('success');
            setShowModal(true);
        } else if (type === 'failure') {
            setModalType('failure');
            if (error.response?.status && [400, 401, 422, 409].includes(error.response.status)) {
                setModalMessage(error.response.data.error);
            } else {
                setModalMessage(t('common:generalFormSubmitServerError'));
            }
            setShowModal(true);
        }
    }

    function handleCloseModal() {
        setShowModal(false);
        if (modalType === 'success') {
            onLogout();
        }
    }

    return (
        <>
            <ModalMessage
                show={showModal}
                onClose={handleCloseModal}
                message={modalMessage}
                messageType={modalType}
            />

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group mb-3">
                    <p style={{ color: '#02335B', fontSize: '18px', fontWeight: 'bold', marginBottom: '20px' }}>Change password</p>
                    <p className="custom-input-label">Current password</p>
                    <input id="current_password" className="form-control custom-profile-settings-input" type="password"
                        {...register("current_password", {
                            required: t('common:requiredField'),
                            minLength: {
                                value: 8,
                                message: t('tripcreate:fieldMinSymbols8'),
                            },
                            maxLength: {
                                value: 20,
                                message: t('tripcreate:fieldMaxSymbols20'),
                            }
                        })}
                        aria-invalid={errorsPassword.current_password ? "true" : "false"}
                    />
                    {errorsPassword.current_password && <p className="text-danger">{errorsPassword.current_password?.message}</p>}
                </div>
                <div className="form-group mb-3">
                    <p className="custom-input-label">New password</p>
                    <input id="new_password" className="form-control custom-profile-settings-input" type="password"
                        {...register("new_password", {
                            required: t('common:requiredField'),
                            minLength: {
                                value: 8,
                                message: t('tripcreate:fieldMinSymbols8'),
                            },
                            maxLength: {
                                value: 20,
                                message: t('tripcreate:fieldMaxSymbols20'),
                            },

                        })}
                        aria-invalid={errorsPassword.new_password ? "true" : "false"}
                    />
                    {errorsPassword.new_password && <p className="text-danger">{errorsPassword.new_password?.message}</p>}
                </div>
                <div className="form-group mb-3">
                    <p className="custom-input-label">Re-type new password</p>
                    <input id="new_password_confirmation" className="form-control custom-profile-settings-input" type="password"
                        {...register("new_password_confirmation", {
                            required: t('common:requiredField'),
                            minLength: {
                                value: 8,
                                message: t('tripcreate:fieldMinSymbols8'),
                            },
                            maxLength: {
                                value: 20,
                                message: t('tripcreate:fieldMaxSymbols20'),
                            },
                            validate: {
                                passwordMatch: value => validatePasswordMatch(value, watch('new_password'))
                            }
                        })}
                        aria-invalid={errorsPassword.confirm_new_password ? "true" : "false"}
                    />
                    {errorsPassword.new_password_confirmation && <p className="text-danger">{errorsPassword.new_password_confirmation?.message}</p>}
                    {errorsPassword.passwordMatch && <p className="text-danger">{errorsPassword.passwordMatch.message}</p>}
                </div>
                <div className="text-center mt-3">
                    <button className="custom-profile-settings-submit-button" type="submit" disabled={loading}>
                        {loading ? <div className="loading-spinner" /> : t('common:changeLabel')}
                    </button>
                </div>
            </form>
        </>
    );
}

export default ChangePassword;